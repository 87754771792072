import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import LiveRedux from '../redux/live/LiveRedux'

const Hook = () => {

    const dispatch = useDispatch()
    const started = useSelector(LiveRedux.selectors.started)
    const others = useSelector(LiveRedux.selectors.others)
    const layout = useSelector(LiveRedux.selectors.layout)
    const allowed = useSelector(LiveRedux.selectors.allowed)
    const mic = useSelector(LiveRedux.selectors.mic)
    const cam = useSelector(LiveRedux.selectors.cam)
    const videoDeviceId = useSelector(LiveRedux.selectors.videoDeviceId)
    const audioDeviceId = useSelector(LiveRedux.selectors.audioDeviceId)

    const initialize = useCallback(() => {
        if (!started){
            dispatch(LiveRedux.actions.liveStart())
        }
    }, [dispatch])

    const liveAllow  = () => {
        dispatch(LiveRedux.actions.liveAllow())
    }

    const setCam = (value) => {
        dispatch(LiveRedux.actions.liveSetCam(value))
    }

    const setMic = (value) => {
        dispatch(LiveRedux.actions.liveSetMic(value))
    }

    return {
        initialize,
        others,
        started,
        layout,
        allowed,
        cam,
        mic,
        setCam,
        setMic,
        liveAllow,
        videoDeviceId,
        audioDeviceId
    }

}

export default Hook