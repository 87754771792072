import { useRef, useEffect, useState } from 'react'
import styles from './MobileLive.module.css'
import config from '../../../config/config'
import useSubscribe from '../../../hooks/useSubscriber'
import pivot from './pivot@3x.png'
import downImage from './down@2x.png'
import { motion } from 'framer-motion'

const variants = {
    hide: {
        opacity: 0,
        transition:{ ease: "easeIn", duration: 0.5 }
    },
    show : {
        opacity: 1,
        transition:{ ease: "easeOut", duration: 0.5 }
    }
}

const MobileLive = ({ room, iceServers }) => {

    const refVideo = useRef(null)

    const [down, setDown] = useState(false)

    const { subscribe, stream, setStream, active } = useSubscribe(true)

    useEffect(() => {
        subscribe(config.room.platform().stream, iceServers, "live-preview", room)
    }, [])

    useEffect(() => {
        const scrollListener = (e) => {
            setDown(e.currentTarget.pageYOffset > 20)
        }

        window.addEventListener('scroll', scrollListener)

        return () => {
            window.removeEventListener('scroll', scrollListener)
        }

    }, [setDown])


    return (
        <div className={styles.container}>
            <video className={styles.video} autoPlay ref={refVideo} id="live-preview" playsInline />
            <motion.div className={styles.instructions} animate={{opacity: 0, transition:{delay: 10}}}>
                <img src={pivot} className={styles.pivotIcon} alt='pivot'/>
                <p>Veuillez tourner votre smartphone pour profiter du live en plein écran.</p>
            </motion.div>
            <motion.div className={styles.scrollDown} variants={variants} initial='show' animate={down ? 'hide' : 'show'}>
                Scroll down
                <img src={downImage} className={styles.downIcon}/>
            </motion.div>
        </div>
    )
}

export default MobileLive