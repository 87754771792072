import { useState } from 'react'
import styles from './SlidePannel.module.css'
import SlideImage from '../SlideImage/SlideImage'
import config from '../../../config/config'
import useZoom from '../../../hooks/useZoom'

const SlidePannel = ({ title, from, length }) => {

    const { setZoom } = useZoom()

    const onClickImage = (index) => {
        setZoom(true, from, length, index)
    }


    return (
        <div className={styles.container}>
            <div className={styles.inner}>
                <div className={styles.slide}>
                    <div className={styles.title}><span className={styles.trait}/>{title}<span className={styles.trait}/></div>
                    <div className={styles.photos}>
                        <div className={styles.photoLeft}><SlideImage width={39} height={56} index={from} onClick={onClickImage}/></div>
                        <div className={styles.photosRight}>
                            <div className={styles.photoMini}><SlideImage width={19.4} height={27.5} index={from + 1} onClick={onClickImage}/></div>
                            <div className={styles.photoMini}><SlideImage width={19.4} height={27.5} index={from + 2} onClick={onClickImage}/></div>
                            <div className={styles.photoMini}><SlideImage width={19.4} height={27.5} index={from + 3} onClick={onClickImage}/></div>
                            <div className={styles.photoMini}><SlideImage width={19.4} height={27.5} index={from + 4} onClick={onClickImage}/></div>
                            <div className={styles.count}>{`+${length-5}`}</div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default SlidePannel