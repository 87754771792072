import { takeLatest, call, select, put, take } from 'redux-saga/effects'
import SessionRedux from './SessionRedux'
import HomeRedux from '../home/HomeRedux'
import CameraRedux from '../camera/CameraRedux'
import existRoom from '../../graphql/queries/room/existRoom'
import authenticateRoom from '../../graphql/queries/room/authenticateRoom'
import rand from 'random-key'
import RoomRedux from '../room/RoomRedux'

function* initialize({ slug }) {
    let liveKey = yield select(SessionRedux.selectors.liveKey)
    let authenticated = yield select(SessionRedux.selectors.authenticated)

    if (!authenticated || liveKey === null) {
        yield call(checkRoom, slug)
    } else {
        yield put(HomeRedux.actions.homeSetStep(HomeRedux.steps.authenticated))
    }
}

function* checkRoom(slug) {
    yield put(HomeRedux.actions.homeSetStep(HomeRedux.steps.checkRoom))

    let result = yield call(existRoom, slug)

    if (result) {
        yield put(SessionRedux.actions.sessionSetRoomKey(slug))
        yield put(HomeRedux.actions.homeSetStep(HomeRedux.steps.requestCode))
    } else {
        yield put(SessionRedux.actions.sessionSetRoomKey(null))
        yield put(HomeRedux.actions.homeSetError('invalid_room'))
        yield put(HomeRedux.actions.homeSetStep(HomeRedux.steps.error))
    }
}

function* codeRequest({ code }) {
    let key = yield select(SessionRedux.selectors.roomKey)
    let { success, liveKey } = yield call(authenticateRoom, key, code)
    if (success) {
        let identifier = yield select(SessionRedux.selectors.identifier)
        if (identifier === null) {
            yield put(SessionRedux.actions.sessionSetIdentifier(rand.generate(8)))
        }
        yield put(SessionRedux.actions.sessionSetLiveKey(liveKey))
        yield put(SessionRedux.actions.sessionSetAuthenticated(true))
        yield put(HomeRedux.actions.homeSetStep(HomeRedux.steps.authenticated))
    } else {
        yield put(HomeRedux.actions.homeSetError('invalid_code'))
        yield put(HomeRedux.actions.homeSetStep(HomeRedux.steps.error))
    }
}

function* logout() {
    /*yield put(CameraRedux.actions.cameraStopPublishingRequest())
    yield take(['CAMERA_STOP_PUBLISHING_SUCCESS'])
    yield put(CameraRedux.actions.cameraStopSubscribingAllRequest())
    yield take['CAMERA_STOP_SUBSCRIBING_ALL_SUCCESS']*/
    yield put(HomeRedux.actions.homeReset())
    yield put(SessionRedux.actions.sessionLogoutSuccess())
    yield put(SessionRedux.actions.sessionSetWelcomed(false))
    yield put(RoomRedux.actions.roomReset())
}

let combine = [
    takeLatest('SESSION_INITIALIZE', initialize),
    takeLatest('SESSION_CODE_REQUEST', codeRequest),
    takeLatest('SESSION_LOGOUT_REQUEST', logout)
]

export default combine