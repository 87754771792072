import styles from './SessionUser.module.css'
import { useEffect, useReducer, useState } from 'react'
import useSubscriber from '../../hooks/useSubscriber'
import useRoom from '../../hooks/useRoom'
import cx from 'classnames'
import camoff from './camoff@2x.png'
import micoff from './micoff@2x.png'

const SessionUser = ({ uuid, room, iceServers, hidden, index }) => {

    const { subscribe, audio, video } = useSubscriber(false, "twilio")
    const { names } = useRoom()

    useEffect(() => {
        subscribe(uuid, iceServers, uuid, room)
    }, [])

    return (
        <div className={styles.content}>
            <span id={uuid}/>
            <div id={`container-${uuid}`} style={{transitionDelay:'0s, ' + (index * 0.15 ) + 's'}} className={cx(styles.container, styles.video, hidden && styles.hidden)}>
            </div>
            {!hidden && <span className={styles.name}>{names[uuid] ? names[uuid] : ''}</span>}
            {!video && <img src={camoff} className={styles.camoff}/>}
            {video && !audio && <img src={micoff} className={styles.micoff}/>}
        </div>
    )
}

export default SessionUser