import { useState, useEffect } from 'react' 
import styles from './SlideShow.module.css'
import cx from 'classnames'
import { motion } from 'framer-motion'

const variants = {
    on:{
        opacity: 1,
        transition: {
            duration: 1
        }
    },
    off: {
        opacity: 0,
        transition: {
            duration: 1
        }
    }
}

const SlideShow = ({ images, duration }) => {

    useEffect(() => {
        let itv = setInterval(() => {
            onToggle()
        }, duration)

        return () => {
            clearInterval(itv)
        }
    }, [ duration ])

    const [index, setIndex] = useState(0)
    const [animate, setAnimate] = useState(false)

    const currentImage = () => {
        return images[index % images.length]
    }

    const nextImage = () => {
        return images[(index + 1) % images.length]
    }

    const onToggle = () => {
        setAnimate(true)
    }

    const onAnimationComplete = () => {
        console.log("ANIMATION COMPLETE ", animate)

        if (animate === true){
            setIndex(index + 1)
            setAnimate(false)
        }
    }

    return (
        <div className={styles.container}>
            <motion.div onAnimationComplete={onAnimationComplete} initial={'off'} className={cx(styles.image, styles.front)} animate={animate ? 'off' : 'on'} variants={variants}>
                <img src={currentImage()}/>
            </motion.div>
        </div>
    )
}

export default SlideShow