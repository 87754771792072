import styles from './StartPopin.module.css'
import { useEffect } from 'react'
import vendome from './vendome@3x.jpg'
import LabelButton from '../LabelButton/LabelButton'
import useRoom from '../../hooks/useRoom'
import { useHistory } from 'react-router-dom'

const StartPopin = () => {

    const { identifier } = useRoom()
    const history = useHistory()

    useEffect(() => {
        document.querySelector('body').style.overflow = "hidden"
        return () => {
            document.querySelector('body').style.removeProperty("overflow")
        }
    }, [])

    const onBegin = () => {
        let url = `/mobile/${identifier}?platform=twilio&livered=true`
        history.push(url)
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <img src={vendome} alt='vendome' className={styles.thumbnail}/>
                <p className={styles.placeTitle}>Showroom vendôme</p>
                <p className={styles.instructions}>Votre experte chanel est prête et vous attend pour le live.</p>
                <div className={styles.actions}>
                    <LabelButton label="Commencer" onClick={onBegin}/>
                </div>
            </div>
        </div>
    )
}

export default StartPopin