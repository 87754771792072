import { useState, useEffect } from 'react'
import styles from './BlurWindow.module.css'
import { motion, useAnimation } from "framer-motion"

const BlurWindow = ({ step, children }) => {

    const [localStep, setLocaleStep] = useState(step) 
    const content = useAnimation()

    useEffect(() => {
        async function transition(){
            await content.start({ opacity: 0 })
            setLocaleStep(step)
            await content.start({ opacity: 1 })
        }
        if (localStep !== step)transition()
    }, [step, content, localStep])
    
    const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    }

    return (
        <motion.div variants={variants} animate='visible' className={styles.container}>
            <motion.div animate={content}>
                {children.filter(c => c.key === localStep)[0]}
            </motion.div>
        </motion.div>
    )
}

export default BlurWindow