import styles from './Live.module.css'
import { useEffect, useState } from 'react'
import useLive from '../../hooks/useLive'
import Throbber from '../../components/Throbber/Throbber'
import { motion } from 'framer-motion'
import LogoChanel from '../../components/LogoChanel/LogoChanel'
import ArrowButton from '../../components/ArrowButton/ArrowButton'
import useSession from '../../hooks/useSession'
import useCamera from '../../hooks/useCamera'
import VideoPreview from '../../components/VideoPreview/VideoPreview'
import ToolBar from '../../components/ToolBar/ToolBar'
import config from '../../config/config'

const transition = {
    duration: 0.6,
    ease: 'easeInOut'
}

const variants = {
    sleep: {
        bottom: '-8rem',
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    },
    active: {
        bottom: '2.5rem',
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    }
}


const Live = () => {

    const { requestPublish, muteCam, unMuteCam, muteMic, unMuteMic } = useCamera()
    const { initialize, others, layout, liveAllow, cam, mic, setCam, setMic } = useLive()
    const { displayName, identifier } = useSession()
    const [thumbs, setThumbs] = useState(true)
    const [sleep, setSleep] = useState(false)

    useEffect(() => {
        let itv = setTimeout(() => {
            setSleep(true)       
        }, config.live.moveTimeOut)
        const onMove = () => {
            if (sleep){
                setSleep(false)
            }
            clearInterval(itv)
            itv = setTimeout(() => {
                setSleep(true) 
            }, config.live.moveTimeOut)
        }

        document.addEventListener("mousemove", onMove)

        return () => {document.removeEventListener("mousemove", onMove)}
    }, [setSleep, sleep])    

    const onToggleCam = () => {
        if (cam){
            muteCam(identifier)
            setCam(false)
        } else {
            unMuteCam(identifier)
            setCam(true)
        }
    }

    const onToggleMic = () => {
        if (mic){
            muteMic(identifier)
            setMic(false)
        } else {
            unMuteMic(identifier)
            setMic(true)
        }
    }

    useEffect(() => {
        initialize()
        requestPublish(identifier)
        liveAllow()
    }, [identifier])

    const onToggleThumbs = () => {
        setThumbs(!thumbs)
    }

    const previewList = () => {
        let result = []
        others.map(o => {
            if (o.publishing){
                result.push({
                    streamIdentifier: o.identifier,
                    publisher: false,
                    name: o.name,
                    cam: o.cam,
                    mic: o.mic
                })
            }
        })
        result.push({
            streamIdentifier: identifier,
            publisher: true,
            name : displayName
        })
        return result
    }

    return (
        <div className={styles.container} id="live-container">
            <LogoChanel />
            <motion.div className={styles.cache} animate={{ opacity: 0, transitionEnd:{display:"none"} ,transition: { delay: 6, duration: 1 } }}>
                <Throbber />
            </motion.div>
            <VideoPreview streamIdentifier='chanel' publisher={false} name='' className={styles.live} hidden={false} cam={true} mic={true}/>
            <div className={styles.thumbnails}>
                {!sleep && previewList().length > 1 && <ArrowButton direction={thumbs ? 'down' : 'up'} className={styles.arrowButton} onClick={onToggleThumbs}/>}
                { previewList().map( (o, index) => {
                    return (
                        <VideoPreview 
                        key={o.streamIdentifier} 
                        streamIdentifier={o.streamIdentifier} 
                        publisher={o.publisher}
                        name={o.name}
                        hidden={index === previewList().length -1 ? false : !thumbs}
                        cam={index === previewList().length -1 ? cam : o.cam}
                        mic={index === previewList().length -1 ? mic : o.mic}
                        />
                    )
                }) }
            </div>
            <div className={styles.debug}>
                V0.0.2-alpha         
            </div>
            <motion.div className={styles.toolContainer} variants={variants} animate={sleep ? 'sleep' : 'active'}>
                <ToolBar onToggleCam={onToggleCam} cam={cam} onToggleMic={onToggleMic} mic={mic}/>
            </motion.div>

        </div>
    )
}

export default Live