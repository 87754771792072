import { dissoc } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import config from '../config/config'
import AppRedux from '../redux/app/AppRedux'

const Hook = () => {
    
    const dispatch = useDispatch()
    const zoom = useSelector(AppRedux.selectors.zoom)
    const index = useSelector(AppRedux.selectors.zoomIndex)
    const from = useSelector(AppRedux.selectors.zoomFrom)
    const length = useSelector(AppRedux.selectors.zoomLength)

    const setZoom = (value, from, length, index) => {
        dispatch(AppRedux.actions.appSetZoom(value, from, length, index))
    }

    const urlHd = () => {
        const url = config.waiting.gouachesHdUrl
        return url.split('%name%').join(config.waiting.gouaches[index])
    }

    const pageName = () => {
        let name = (index + 1).toString()
        while(name.length < 4)name = "0" + name
        return `#${name}`
    }

    const onPrevious  = () => {
        let nIndex = index - 1
        if (nIndex < from)nIndex = from + length - 1
        dispatch(AppRedux.actions.appSetIndex(nIndex))
    }

    const onNext = () => {
        let nIndex = index + 1
        if (nIndex >= from + length)nIndex = from
        dispatch(AppRedux.actions.appSetIndex(nIndex))
    }

    const onClose = () => {
        dispatch(AppRedux.actions.appSetZoom(false, 0, 0, 0))
    }

    const pageIndex = () => {
        return index - from
    }


    return {
        setZoom,
        zoom,
        urlHd,
        pageName,
        length,
        onNext, 
        onPrevious,
        pageIndex,
        onClose
    }
}

export default Hook