import styles from './StickChrono.module.css'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import CountDown from '../CountDown/CountDown'

const variants = {
    hide: {
        y: -60,
        transition:{ ease: "easeIn", duration: 0.5 }
    },
    show : {
        y: 0,
        transition:{ ease: "easeOut", duration: 0.5 }
    }
}

const StickChrono = () => {

    const [down, setDown] = useState(false)

    useEffect(() => {
        const scrollListener = (e) => {
            setDown(e.currentTarget.pageYOffset > 450)
        }

        window.addEventListener('scroll', scrollListener)

        return () => {
            window.removeEventListener('scroll', scrollListener)
        }

    }, [setDown])

    return (
        <div className={styles.container}>
            <motion.div className={styles.content} variants={variants} initial='hide' animate={down ? 'show' : 'hide'}>
                <CountDown dark/>
            </motion.div>
        </div>
    )
}

export default StickChrono