import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import RoomRedux from '../redux/room/RoomRedux'

const Hook = () => {

    const dispatch = useDispatch()
    const occupants = useSelector(RoomRedux.selectors.occupants)
    const step = useSelector(RoomRedux.selectors.step)
    const layout = useSelector(RoomRedux.selectors.layout)
    const videoId = useSelector(RoomRedux.selectors.videoId)
    const audioId = useSelector(RoomRedux.selectors.audioId)
    const iceServers = useSelector(RoomRedux.selectors.iceServers)
    const names = useSelector(RoomRedux.selectors.names)
    const identifier = useSelector(RoomRedux.selectors.identifier)
  
    const initialize = useCallback((identifier) => {
        dispatch(RoomRedux.actions.roomInitialize(identifier))
    }, [dispatch])

    const toggleLayout = () => {
        dispatch(RoomRedux.actions.roomToggleLayout())
    }

    const inviteUser = (uuid) => {
        dispatch(RoomRedux.actions.roomInviteUser(uuid))
    }

    const allowUser = (uuid) => {
        dispatch(RoomRedux.actions.roomAllowUser(uuid))
    }

    const cameraError = () => {
        dispatch(RoomRedux.actions.roomCameraError())
    }

    const cameraGranted = () => {
        dispatch(RoomRedux.actions.roomCameraGranted())
    }

    const setVideoId = (id) => {
        dispatch(RoomRedux.actions.roomSetVideoId(id))
    }

    const setAudioId = (id) => {
        dispatch(RoomRedux.actions.roomSetAudioId(id))
    }

    const confirmSettings = () => {
        dispatch(RoomRedux.actions.roomConfirmSettings())
    }

    const joinLive = () => {
        dispatch(RoomRedux.actions.roomJoinLive())
    }

    const renameUser = (uuid, name) => {
        dispatch(RoomRedux.actions.roomRenameUser(uuid, name))
    }

    const kickUser = (uuid) => {
        dispatch(RoomRedux.actions.roomKickUser(uuid))
    }

    const endUser = (uuid) => {
        dispatch(RoomRedux.actions.roomEndUser(uuid))
    }

    return {
        initialize,
        occupants,
        step,
        layout,
        inviteUser,
        toggleLayout,
        cameraError,
        cameraGranted,
        setVideoId,
        setAudioId,
        videoId,
        audioId,
        confirmSettings,
        allowUser,
        joinLive,
        iceServers,
        renameUser,
        names,
        kickUser,
        endUser,
        identifier
    }

}

export default Hook