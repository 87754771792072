import React, { useState, useEffect, useRef } from 'react'
import styles from './AdminUser.module.css'
import cx from 'classnames'
import useRoom from '../../../../hooks/useRoom'
import AdminButton from '../AdminButton/AdminButton'
import AdminVideo from '../AdminVideo/AdminVideo'
import RoomRedux from '../../../../redux/room/RoomRedux'
import AdminInput from '../AdminInput/AdminInput'

const AdminUser = ({ uuid, step, loading, config }) => {

    const { inviteUser, allowUser, renameUser, kickUser, endUser } = useRoom()
    const [ allowed, setAllowed ] = useState(false)

    useEffect(() => {
        if (step === RoomRedux.steps.initial){
            setAllowed(false)
        }
    }, [step])

    const onInvite = () => {
        inviteUser(uuid)
    }

    const onAllow = () => {
        allowUser(uuid)
        setAllowed(true)
    }

    const onChangeName = (name) => {
        renameUser(uuid, name)
    }

    const onKick = () => {
        kickUser(uuid)
    }
    

    const onEnd = () => {
        endUser(uuid)
    }

    return (
        <li className={styles.container}>
            {uuid} [ {step} ]
            <div className={styles.line}>
                <AdminButton className={styles.stepDown} label="invite" onClick={onInvite} disabled={step !== RoomRedux.steps.initial}/>
                <AdminButton className={styles.stepDown} label="allow" onClick={onAllow} disabled={step !== RoomRedux.steps.confirmed || allowed}/> 
                <div className={styles.stepDown}>
                    <AdminInput onValidate={onChangeName} value={''}/>
                </div>
                <AdminButton label="kick" onClick={onKick} disabled={false}/>
                <AdminButton label="End" onClick={onEnd} disabled={false}/>
            </div>
        </li>
    )
}

export default AdminUser