import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'

/* ------------- Initial state ------------- */

const initialState = Immutable({
  counter: 0,
  zoom: false,
  zoomFrom: 0,
  zoomLength: 0,
  zoomIndex: 0,
  thumbs: false,
  transitionEnd: "off"
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
  increment: null,
  appSetZoom: ['value', 'from', 'length', 'index'],
  appSetIndex: ['index'],
  appHideThumbs: ['value'],
  appSetTransitionEnd: ['value']
})
const actions = Creators

/* ------------- Reducers ------------- */

const increment = (state) => state.merge({counter: state.counter + 1})
const setZoom = (state, {value, from, length, index}) => state.merge({zoom: value, zoomFrom:from, zoomLength: length, zoomIndex: index})
const setIndex = (state, {index}) => state.merge({zoomIndex: index})
const hideThumbs = (state, {value}) => state.merge({thumbs: value})
const setTransitionEnd = (state, {value}) => state.merge({transitionEnd: value})

/* ------------- Selectors ------------- */

const counter = (state) => state.app.counter
const zoom = (state) => state.app.zoom
const zoomFrom = (state) => state.app.zoomFrom
const zoomLength = (state) => state.app.zoomLength
const zoomIndex = (state) => state.app.zoomIndex
const thumbs = (state) => state.app.thumbs
const transitionEnd = (state) => state.app.transitionEnd
 

const selectors = {
  counter: createSelector([counter], (_)=>_),
  zoom: createSelector([zoom], (_)=>_),
  zoomFrom: createSelector([zoomFrom], (_)=>_),
  zoomLength: createSelector([zoomLength], (_)=>_),
  zoomIndex: createSelector([zoomIndex], (_)=>_),
  thumbs: createSelector([thumbs], (_)=>_),
  transitionEnd: createSelector([transitionEnd], (_)=>_)
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
  [Types.INCREMENT]: increment,
  [Types.APP_SET_ZOOM]: setZoom,
  [Types.APP_SET_INDEX]: setIndex,
  [Types.APP_HIDE_THUMBS]: hideThumbs,
  [Types.APP_SET_TRANSITION_END]: setTransitionEnd
})

const defaultExport = {
  actions, 
  selectors,
  reducer,
  types: Types
}

export default defaultExport