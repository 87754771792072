import left1 from './icons/left.png'
import left2 from './icons/left@2x.png'
import right1 from './icons/right.png'
import right2 from './icons/right@2x.png'
import up1 from './icons/up.png'
import up2 from './icons/up@2x.png'
import down1 from './icons/down.png'
import down2 from './icons/down@2x.png'
import styles from './ArrowButton.module.css'
import cx from 'classnames'

const icons = {
    left:[left1, left2],
    right:[right1, right2],
    up:[up1, up2],
    down:[down1, down2]
}

const ArrowIcon = ({ direction, className, onClick }) => {

    const ico = icons[direction]

    return (
        <div className={cx(styles.container, className)} onClick={onClick}>
            <img src={ico[0]}   className={styles[`image${direction}`]} 
                                srcSet={ico[0] + ' 1x,' + ico[1] + ' 2x'} 
                                alt={`Arrow ${direction}`} 
                                />
        </div>
    )
}

export default ArrowIcon