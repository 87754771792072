import styles from './MobileHeader.module.css'
import logo from './logo@3x.png'

const MobileHeader = () => {
    return (
        <div className={styles.container}>
            <img className={styles.logo} src={logo} alt='Chanel'/>
        </div>
    )
}

export default MobileHeader