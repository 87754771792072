import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './fonts/ABChanelCorpo-Regular.ttf'
import './fonts/ABChanelCorpo-Regular.woff'
import './fonts/ABChanelCorpo-Regular.woff2'
import './fonts/ABChanelCorpo-SemiBold.ttf'
import './fonts/ABChanelCorpo-SemiBold.woff'
import './fonts/ABChanelCorpo-SemiBold.woff2'
import './fonts/ABChanelCorpo-Light.ttf'
import './fonts/ABChanelCorpo-Light.woff'
import './fonts/ABChanelCorpo-Light.woff2'
import './fonts/ABChanelCorpo-ExtraLight.ttf'
import './fonts/ABChanelCorpo-ExtraLight.woff'
import './fonts/ABChanelCorpo-ExtraLight.woff2'

ReactDOM.render(
  <App />,
  document.getElementById('root')
);


reportWebVitals();
