import { useEffect, useState, useRef } from 'react'
import styles from './RoomLive.module.css'
import RoomButton from '../RoomButton/RoomButton'
import useSubscribe from '../../../hooks/useSubscriber'
import useRoom from '../../../hooks/useRoom'
import { motion } from 'framer-motion'
import RoomRedux from '../../../redux/room/RoomRedux'
import config from '../../../config/config'
import cx from 'classnames'
import redLogo from '../RoomPlatform/red5.png'
import closeIcon from './close.png'
import ToolBar from '../../../components/ToolBar/ToolBar'
import useToggleMedia from '../../../hooks/useToggleMedia'
import RoomLogos from '../RoomLogos/RoomLogos'
import { useIdleTimer } from 'react-idle-timer'

const variants = {
    show: {
        opacity: 1
    },
    hide: {
        opacity: 0
    }
}

const variantsTool = {
    sleep: {
        bottom: '-8rem',
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    },
    active: {
        bottom: '2.5rem',
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    }
}

const googleServers = [{urls: 'stun:stun2.l.google.com:19302'}]

const RoomLive = ({ iceServers, liveCount, setLiveCount, room, peer }) => {

    const { subscribe, stream, setStream, active } = useSubscribe(true)
    const { layout, toggleLayout } = useRoom()
    const { audio, video, onToggleAudio, onToggleVideo } = useToggleMedia(room, peer)
    const refVideo = useRef(null)
    const [ tool, setTool ] = useState(false)
    
    const handleOnIdle = event => {
        console.log('user is idle', event)
        console.log('last active', getLastActiveTime())
        setTool(false)
    }

    const handleOnActive = event => {
        console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
        setTool(true)
    }

    const handleOnAction = (e) => {
        setTool(true)
        console.log('user did something', e)
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })



    useEffect(() => {
        subscribe(config.room.platform().stream, iceServers, "live-preview", room)
    }, [liveCount])

    useEffect(() => {
        if (stream !== undefined) {
            refVideo.current.srcObject = stream
        }
    }, [stream])

    const onRetry = () => {
        setLiveCount(liveCount + 1)
    }

    const title = () => {
        if (config.room.platform().livered) return `Room Live RED5 [ Forced ]`
        return `Room Live ${config.room.platform().label}`
    }

    const renderHeader = () => {
        return (
            <div className={styles.header}>
                {config.room.platform().livered && <img src={redLogo} className={styles.red5logo} />}
                <span className={cx(styles.dot, active && styles.dotActive)} />{title()} {active ? '[ LIVE ]' : '[ OFF ]'}
                <RoomButton className={styles.retry} label="Retry" enabled={!active} onClick={onRetry} />
            </div>
        )
    }

    const renderClose = () => {
        return (
            <div className={styles.closeContainer} onClick={() => {
                toggleLayout()
                setTool(false)
            }}>
                <img className={styles.closeIcon} src={closeIcon} alt='close' />
            </div>
        )
    }

    const renderToolBar = () => {
        return (
            <motion.div className={styles.toolbarContainer} variants={variantsTool} animate={tool ? 'active' : 'sleep'} initial={"sleep"}>
                <ToolBar cam={video} mic={audio} onToggleCam={onToggleVideo} onToggleMic={onToggleAudio} elementId={'room'} />
            </motion.div>
        )
    }

    return (
        <div className={cx(styles.container, layout === RoomRedux.layouts.live && styles.containerLive)}>
            {layout === RoomRedux.layouts.debug && renderHeader()}
            <div className={styles.topContainer}>
                <motion.div id={"container-live-preview"} className={styles.videoContainer} variants={variants} animate={active ? 'show' : 'hide'} transition={{ duration: 1, delay: 2, ease: 'easeOut' }}>
                    <video width={720} autoPlay ref={refVideo} id="live-preview" />

                </motion.div>
                <p className={styles.videoLabel}>{config.room.liveLabel}</p>
            </div>
            {layout === RoomRedux.layouts.live && renderClose()}
            {layout === RoomRedux.layouts.live && renderToolBar()}
            {layout === RoomRedux.layouts.live && <motion.div variants={variants} animate={tool ? 'show' : 'hide'}><RoomLogos/></motion.div>}
        </div>
    )
}

export default RoomLive