import firebase from 'firebase/app'

const firebaseConfig = {
    apiKey: "AIzaSyA6iZd54Yz8pW9NBJvNufQmGf4VgRdJRJo",
    authDomain: "remote-sales-5391b.firebaseapp.com",
    projectId: "remote-sales-5391b",
    storageBucket: "remote-sales-5391b.appspot.com",
    messagingSenderId: "536878295413",
    appId: "1:536878295413:web:31c63dccf8b201d61c8b3c",
    measurementId: "G-3XMSRJRFHH"
}

firebase.initializeApp(firebaseConfig)
let db = firebase.firestore();

export default db