import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'


/* ------------- Initial state ------------- */

const initialState = Immutable({
  liveKey: null,
  roomKey: null,
  authenticated: false,
  identifier: null,
  welcomed: false
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
    sessionInitialize: ['slug'],
    sessionSetLiveKey: ['key'],
    sessionSetRoomKey: ['key'],
    sessionCodeRequest: ['code'],
    sessionSetAuthenticated: ['value'],
    sessionLogoutRequest: null,
    sessionSetIdentifier: ['value'],
    sessionLogoutSuccess: null,
    sessionSetWelcomed: ['value']
})
const actions = Creators

/* ------------- Reducers ------------- */

const sessionSetLiveKey = (state, { key }) => state.merge({liveKey:key})
const sessionSetRoomKey = (state, { key }) => state.merge({roomKey:key})
const sessionSetAuthenticated = (state, {value}) => state.merge({authenticated:value})
const sessionLogout = (state) => state.merge({ liveKey:null, authenticated:false, identifier:null, ping:false })
const sessionSetIdentifier = (state, {value}) => state.merge({ identifier: value})
const sessionSetWelcomed = (state, {value}) => state.merge({ welcomed: value})

/* ------------- Selectors ------------- */

const liveKey = (state) => state.session.liveKey
const roomKey = (state) => state.session.roomKey
const identifier = (state) => state.session.identifier
const authenticated = (state) => state.session.authenticated
const frame = (state) => state.live.frame
const welcomed = (state) => state.session.welcomed

const selectors = {
  liveKey: createSelector([liveKey], (_)=>_),
  welcomed: createSelector([welcomed], (_)=>_),
  roomKey: createSelector([roomKey], (_)=>_),
  identifier: createSelector([identifier], (identifier) => {
    let params = new URLSearchParams(window.location.search)
    let user = params.get('user')
    return identifier === null ? null : user ? user : identifier
  }),
  authenticated: createSelector([authenticated], (_)=>_),
  displayName: createSelector([frame, identifier], (frame, identifier)=> {
    if (frame && frame[`name_${identifier}`])return frame[`name_${identifier}`]
    return ''
  })
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
  [Types.SESSION_SET_LIVE_KEY]: sessionSetLiveKey,
  [Types.SESSION_SET_ROOM_KEY]: sessionSetRoomKey,
  [Types.SESSION_SET_AUTHENTICATED]: sessionSetAuthenticated,
  [Types.SESSION_LOGOUT_SUCCESS]: sessionLogout,
  [Types.SESSION_SET_IDENTIFIER]: sessionSetIdentifier,
  [Types.SESSION_SET_WELCOMED]: sessionSetWelcomed
})

const defaultExport = {
  actions, 
  selectors,
  reducer,
  types: Types
}

export default defaultExport