import React from 'react'
import styles from './App.module.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import { PersistGate } from 'redux-persist/integration/react'
import Home from './containers/Home/Home'
import Admin from './containers/Admin/Admin'
import Waiting from './containers/Waiting/Waiting'
import Live from './containers/Live/Live'
import Session from './containers/Session/Session'
import Demo from './containers/Demo/Demo'
import Room from './containers/Room/Room'
import Mobile from './containers/Mobile/Mobile'
import ProtectedRoute from './containers/Protected/ProtectedRoute'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import RoomRedux from './redux/room/RoomRedux'
import cx from 'classnames'


function App() {
  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <div className={styles.container}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/room/:identifier" component={Room} />
              <Route exact path="/mobile/:identifier" component={Mobile} />
              <Route exact path="/demo/:mode/:identifier" component={Demo} />
              <ProtectedRoute exact path="/session" component={Session} requiredStep={`${RoomRedux.steps.allowed},${RoomRedux.steps.live},${RoomRedux.steps.broadcast}`}/>
              <ProtectedRoute exact path='/waiting' component={Waiting} />
              <ProtectedRoute exact path='/live' component={Live} />
              <Route exact path='/:slug' component={Home} />
              <Route exact path='/admin/:slug' component={Admin} />
              <Redirect from='*' to='/_' />
            </Switch>
          </BrowserRouter>
        </div>
      </PersistGate>
    </Provider>
  );
}


export default App;
