import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'
import config from '../../config/config'


/* ------------- Initial state ------------- */

const initialState = Immutable({
    event: {
        time: 0,
        type: 'none'
    },
    frame: {
        forceLoad: '_'
    },
    started: false,
    allowed: false,
    resolution: config.live.defaultResolution,
    cam: true,
    mic: true,
    videoDeviceId: null,
    audioDeviceId: null
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
    liveStart: null,
    liveSetFrame: ['frame'],
    liveSetEvent: ['event'],
    livePing: null,
    liveStarted: null,
    liveAllow: null,
    liveSetResolution: ['resolution'],
    liveSetCam: ['value'],
    liveSetMic: ['value'],
    liveSetVideoDevice: ['id'],
    liveSetAudioDevice: ['id'],
    liveEventInviteUser: ['identifier']
})
const actions = Creators

/* ------------- Reducers ------------- */

const setFrame = (state, {frame}) => state.merge({frame})
const setEvent = (state, {event}) => state.merge({event})
const liveStarted = (state) => state.merge({started: true})
const liveAllow = (state) => state.merge({allowed: true})
const setResolution = (state, {resolution}) => state.merge({resolution})
const setCam = (state, {value}) => state.merge({cam: value})
const setMic = (state, {value}) => state.merge({mic: value})
const setVideoDevice = (state, {id}) => state.merge({videoDeviceId: id})
const setAudioDevice = (state, {id}) => state.merge({audioDeviceId: id})


/* ------------- Selectors ------------- */

const frame = (state) => state.live.frame
const event = (state) => state.live.event
const started = (state) => state.live.started
const identifier = (state) => state.session.identifier
const allowed = (state) => state.live.allowed
const resolution = (state) => state.live.resolution
const cam = (state) => state.live.cam
const mic = (state) => state.live.mic
const videoDeviceId = (state) => state.live.videoDeviceId
const audioDeviceId = (state) => state.live.audioDeviceId

const selectors = {
    videoDeviceId: createSelector([videoDeviceId], (_)=>_),
    audioDeviceId: createSelector([audioDeviceId], (_)=>_),
    cam: createSelector([cam], (_)=>_),
    mic: createSelector([mic], (_)=>_),
    allowed: createSelector([allowed], (_)=>_),
    resolution: createSelector([resolution], (_)=>_),
    layout: createSelector([frame], (frame)=>frame.layout),
    started: createSelector([started], (_)=>_),
    frame: createSelector([frame], (_)=>_),
    event: createSelector([event], (_)=>_),
    forceLoad: createSelector([frame],(frame)=>frame.forceLoad),
    others: createSelector([frame, identifier], (frame, identifier) => {
        let result = []
        let keys = Object.keys(frame)
        keys.map(k => {
            if (k.indexOf('user_') === 0 && frame[k].publishing === true && frame[k].allowed && k.split('_')[1] !== identifier){
                result.push({
                    identifier: k.split('_')[1],
                    publishing:  frame[k].publishing,
                    cam: frame[k].cam,
                    mic: frame[k].mic,
                    name: frame[`name_${k.split('_')[1]}`] === undefined ? '' : `${frame[`name_${k.split('_')[1]}`]}`,
                })
            }
           
        })
        let sortedByIdentifier = result.sort((a, b) => a.identifier.localeCompare(b.identifier))
        return sortedByIdentifier
    })
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
    [Types.LIVE_SET_FRAME]: setFrame,
    [Types.LIVE_SET_EVENT]: setEvent,
    [Types.LIVE_STARTED]: liveStarted,
    [Types.LIVE_ALLOW]: liveAllow,
    [Types.LIVE_SET_RESOLUTION]: setResolution,
    [Types.LIVE_SET_CAM]: setCam,
    [Types.LIVE_SET_MIC]: setMic,
    [Types.LIVE_SET_VIDEO_DEVICE]: setVideoDevice,
    [Types.LIVE_SET_AUDIO_DEVICE]: setAudioDevice
})

const defaultExport = {
  actions, 
  selectors,
  reducer,
  types: Types
}

export default defaultExport