const texts = {
    enter_code: "Entrez svp le code à 4 chiffres qui vous a été communiqué par votre conseiller chanel.",
    invalid_room: "Ce lien de connexion est expiré, veuillez contacter votre conseiller Chanel.",
    invalid_code: "Le code d’identification est incorrect, veuillez réessayer s’il-vous-plaît.",
    try_new_code: "Essayer un nouveau code",
    lost_password: "Code oublié ?",
    lost_password_error: "Veuillez contacter votre conseiller Chanel pour obtenir un nouveau code.",
    title: "Escale à venise",
    presentation_starting: "Votre présentation commence dans",
    gouaches_section_title: "Découvrir les gouachés de la sélection",
    disclaimer: "Afin de permettre à CHANEL d’organiser une expérience vidéo personnalisée, nous aurons besoin de traiter des informations sur les flux d’images. Ces informations ne seront pas enregistrées. Vous pouvez obtenir des informations générales sur la collecte et le traitement de vos données à caractère personnel par CHANEL, en particulier concernant vos droits sur vos données, via notre Politique de confidentialité.",
    ask_permission: "Demander l'autorisation",
    no_camera: "La caméra et le micro ne sonts pas actifs",
    camera_settings_instructions: "Afin que les autres puissent vous voir et vous entendre, votre navigateur va demander l’accès à votre caméra et votre micro. Vous pourrez les désactiver à tout moment.",
    need_help: "Besoin d'aide ?",
    join_live: "Rejoindre le live",
    welcome: [  "Chère Madame, Monsieur" ,
                "Nous sommes heureux de vous présenter notre nouvelle collection de Haute Joaillerie, ESCALE à VENISE.",
                "Composée de pièces exceptionnelles, ces parures de Haute Joaillerie offrent une texture contemporaine de la découverte de la Cité des Doges par Gabrielle Chanel. Hommage à la ville favorite de Mademoiselle, cette Escale à Venise passe les icônes de CHANEL au prisme de la Sérénissime d’aujourd’hui.",
                "Nous sommes impatients de partager cette expérience numérique unique avec vous.",
                "Très sincèrement,"],
    welcome_author: "Frédéric Grangié",
    continue: "Continer",
    please_wait: "Merci de patienter",
    presentation_started: `Votre présentation en ligne est sur le point de commencer`,
    video_settings: "Réglages vidéos",
    camera_error: "Impossible d'actvier votre camera. Veuillez vérifier les réglages de votre navigateur",
    comfirm_settings: "Confirmer ces réglages",
    step_label: (cnt, tot) => `étape ${cnt}/${tot}`,
    you_are_ready: 'Vous êtes prêt',
    mobile_no_camera: "La caméra et le micro ne sont pas actifs"
}

const locale = (path) => {
    return texts[path]
}


export default locale
