import { useSelector, useDispatch } from 'react-redux'
import CameraRedux from '../redux/camera/CameraRedux'

const Hook = () => {

    const dispatch = useDispatch()

    const popin = useSelector(CameraRedux.selectors.popin)
    const publishing = useSelector(CameraRedux.selectors.publishing)
    const bitrates = useSelector(CameraRedux.selectors.bitrates)

    const requestPublish = (identifier) => {
        dispatch(CameraRedux.actions.cameraRequestPublish(identifier))
    }

    const subscribeLive = (channel, elementId) => {
        dispatch(CameraRedux.actions.cameraSubscribeLiveRequest(channel, elementId))
    }

    const requestSubscribe = (elementId, identifier) => {
        dispatch(CameraRedux.actions.cameraRequestSubscribe(elementId, identifier))
    }

    const stopSubscribe = (identifier) => {
        dispatch(CameraRedux.actions.cameraStopSubscribingRequest(identifier))
    }

    const checkBitrate = (identifier) => {
        dispatch(CameraRedux.actions.cameraCheckBitrate(identifier))
    }

    const mute = (identifier) => {
        dispatch(CameraRedux.actions.cameraMute(identifier))
    }

    const unMute = (identifier) => {
        dispatch(CameraRedux.actions.cameraUnMute(identifier))
    }

    const muteMic = (identifier) => {
        dispatch(CameraRedux.actions.cameraMuteMic(identifier))
    }

    const unMuteMic = (identifier) => {
        dispatch(CameraRedux.actions.cameraUnMuteMic(identifier))
    }

    const muteCam = (identifier) => {
        dispatch(CameraRedux.actions.cameraMuteCam(identifier))
    }

    const unMuteCam = (identifier) => {
        dispatch(CameraRedux.actions.cameraUnMuteCam(identifier))
    }

    const changeVideoDevice = (videoDeviceId) => {
        dispatch(CameraRedux.actions.cameraChangeVideoDevice(videoDeviceId))
    }

    const changeAudioDevice = (audioDeviceId) => {
        dispatch(CameraRedux.actions.cameraChangeAudioDevice(audioDeviceId))
    }

    return {
        popin,
        bitrates,
        requestPublish,
        publishing,
        subscribeLive,
        requestSubscribe,
        stopSubscribe,
        mute,
        unMute,
        muteMic,
        unMuteMic,
        muteCam,
        unMuteCam,
        checkBitrate,
        changeVideoDevice,
        changeAudioDevice
    }    
}

export default Hook