import Reactotron from 'reactotron-react-js'
import { reactotronRedux } from 'reactotron-redux'
import sagaPlugin from 'reactotron-redux-saga'
import apisaucePlugin from 'reactotron-apisauce'

Reactotron.configure()
          .use(reactotronRedux())
          .use(sagaPlugin())
          .use(apisaucePlugin({}))

if (process.env.NODE_ENV === 'development'){
    Reactotron.connect()
}


export default Reactotron