import { gql } from '@apollo/client'
import client from '../../index'

const query = (identity, room, expiration) => {
    return new Promise((resolve) => {
        client.query({
            query: gql`
                query twilioToken($identity:String!, $room:String!, $expiration:Int!){
                    twilioToken(identity:$identity, room:$room, expiration:$expiration)
                }
            `,
            variables:{
                identity,
                room,
                expiration
            }
        }).then((res) => {
            resolve(res.data.twilioToken)     
        })
    }) 
}

export default query