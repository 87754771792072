import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import config from '../../config/config'
import ky from 'ky'

const Hook = () => {

    const dispatch = useDispatch()

    const [stream, setStream] = useState(null)
    const [active, setActive] = useState(false)
    
    const subscribe = useCallback(async (identifier, iceServers, elementId = null) => {
        //
        // Load URLS & JWT
        //
        let url, jwt, result;
        try {
            result = await ky.post(config.room.subscribeUrl, {
                json:{
                    streamAccountId: config.room.accountId,
                    streamName: identifier,
                    unauthorizedSubscribe: true
                }
            }).json()
        } catch(err) {
            setStream(null)
            return
        }

        if (result.status === 'success'){
            url = result.data.urls[0]
            jwt = result.data.jwt
        }

        //
        // Create the peer
        //
        let peer = new RTCPeerConnection({
            iceServers: iceServers,
            rtcpMuxPolicy: "require",
            bundlePolicy: "max-bundle"
        })

        peer.ontrack = function ({ streams }) {
            setStream(streams[0])
        }


        //
        // Create the web socket
        //
        let ws = new WebSocket(url + "?token=" + jwt);
       
        ws.onmessage = async function (evt){
            let data = JSON.parse(evt.data)
            if (data.type === "response"){
                let session = new RTCSessionDescription(config.room.publisherResponse(data.data.sdp))
                await peer.setRemoteDescription(session)
            }
            if (data.type === "event"){
                switch (data.name){
                    case "active":
                        setActive(true)
                        break;
                    case "inactive":
                    case "stopped":
                        setActive(false)
                        break;
                }   
            }
        }

        await new Promise((resolve) => {
            ws.onopen = async () => {
                resolve()
            }
        })

        //
        // Create the offer
        //
        let offer = await peer.createOffer({
            offerToReceiveAudio: true,
            offerToReceiveVideo: true
        })
        await peer.setLocalDescription(offer)
        ws.send(JSON.stringify(config.room.subscribePayload(identifier, offer.sdp)))


    })


    return {
        subscribe,
        stream,
        setStream,
        active
    }
}

export default Hook