import { takeLatest, call, fork, select, take, put } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import LiveRedux from '../live/LiveRedux'
import SessionRedux from '../session/SessionRedux'
import CameraRedux from '../camera/CameraRedux'
import admin from '../admin/AdminFactory'
import config from '../../config/config'
import Live from '../../containers/Live/Live'

function* start() {
    let started = yield select(LiveRedux.selectors.started)
    if (!started){
        yield fork(initAdmin)
        yield put (CameraRedux.actions.cameraInitialize())
        yield put (LiveRedux.actions.liveStarted())
    }
}

function* initAdmin(){
    let liveKey = yield select(SessionRedux.selectors.liveKey)
    admin.startListen(liveKey, 'LIVE')
    const channel = yield call(adminChannel)
    try {
        while (true){
            const event = yield take(channel)
            yield put(event)
        }
    } finally {
    }
}

function adminChannel() {
    return eventChannel((emitter) => {
        const eventHandler = (e) => {
           emitter(e)
        }
        admin.onEvent = eventHandler
        return () => admin.onEvent = null
    })
}

function* eventFrame({type, open, event, ...frame}){
    let forceLoad = yield select(LiveRedux.selectors.forceLoad)
    let identifier = yield select(SessionRedux.selectors.identifier)
    let publishing = yield select(CameraRedux.selectors.publishing)
    if (forceLoad !== frame.forceLoad)admin.pingFromUser(identifier, publishing)
    yield put(LiveRedux.actions.liveSetFrame(frame))
    yield call(handleEvent, event)
}

function* livePing(){
    let identifier = yield select(SessionRedux.selectors.identifier)
    let publishing = yield select(CameraRedux.selectors.publishing)
    let bitrates = yield select(CameraRedux.selectors.bitrates)
    let allowed = yield select(LiveRedux.selectors.allowed)
    let resolution = yield select(LiveRedux.selectors.resolution)
    let cam = yield select(LiveRedux.selectors.cam)
    let mic = yield select(LiveRedux.selectors.mic)

    admin.pingFromUser(identifier, publishing, bitrates, allowed, resolution, cam, mic)
}

function* handleEvent(event){
    let localeEvent = yield select(LiveRedux.selectors.event)
    if (localeEvent.time !== event.time){
        let elapsed = new Date().getTime() - event.time
        if (elapsed < config.live.eventTimeOut){
            yield put(event)
        }
        yield put(LiveRedux.actions.liveSetEvent(event))
    }
}

function *debugMessage({ identifier, message }){
    let myIdentifier = yield select(SessionRedux.selectors.identifier)
    if (myIdentifier === identifier){
        window.alert(message)
    }
}

function *forceLogout({ identifier }){
    let myIdentifier = yield select(SessionRedux.selectors.identifier)
    if (myIdentifier === identifier){
        yield put(SessionRedux.actions.sessionLogoutRequest())
    }
}

function *allowUser({ identifier }) {
    let myIdentifier = yield select(SessionRedux.selectors.identifier)
    if (myIdentifier === identifier){
        yield put(LiveRedux.actions.liveAllow())
    }
}

function *inviteUser({ identifier }){
    let myIdentifier = yield select(SessionRedux.selectors.identifier)
    if (myIdentifier === identifier){
        yield put(CameraRedux.actions.cameraShowPopin())
    }
} 

function *changeResolution({ identifier }){
    let myIdentifier = yield select(SessionRedux.selectors.identifier)
    if (myIdentifier === identifier){
        let resolution = yield select(LiveRedux.selectors.resolution)
        if (resolution === "high"){
            resolution = "medium"
        } else if (resolution === 'medium') {
            resolution = "low"
        } else {
            resolution = "high"
        }

        yield put(LiveRedux.actions.liveSetResolution(resolution))
        yield put(CameraRedux.actions.cameraUpdateResolution(identifier))
        yield put(LiveRedux.actions.livePing())
    }
}

function *changeVideoSuccess(){
    yield put(LiveRedux.actions.livePing())
}


let combine = [
    takeLatest('LIVE_START', start),
    takeLatest('LIVE_EVENT_FRAME', eventFrame),
    takeLatest('LIVE_EVENT_DEBUG_MESSAGE', debugMessage),
    takeLatest('LIVE_EVENT_FORCE_LOGOUT', forceLogout),
    takeLatest('LIVE_PING', livePing),
    takeLatest('LIVE_EVENT_INVITE_USER', inviteUser),
    takeLatest('LIVE_EVENT_ALLOW_USER', allowUser),
    takeLatest('LIVE_EVENT_CHANGE_RESOLUTION', changeResolution),
    takeLatest('FACTORY_CHANGE_VIDEO_DEVICE_SUCCESS', changeVideoSuccess)
]
  
export default combine