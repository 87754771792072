import styles from './RowGrid.module.css'
import config from '../../config/config'
import RowThumb from '../RowThumb/RowThumb'
import ZoomGrid from '../ZoomGrid/ZoomGrid'
import { useState } from 'react'

const RowGrid = ({ from, length, title }) => {

    const [zoom, setZoom] = useState(false)
    const [name, setName] = useState(null)
    const [index, setIndex] = useState(0)

    const elements = () => {
        return config.waiting.gouaches.slice(from, from + length)
    }

    const onClick = (name, index) => {
        setZoom(true)
        setName(name)
        setIndex(index)
    }

    const onClose = () => {
        setZoom(false)
        setName(null)
    }

    const onPrevious = () => {
        let idx = index - 1
        if (idx <= 0){
            idx += elements.length
        }
        setIndex(idx)
        setName(elements()[idx])
    }

    const onNext = () => {
        let idx = index + 1
        if (idx >= elements.length){
            idx -= elements.length
        }
        setIndex(idx)
        setName(elements()[idx])
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <span className={styles.tiret}/>{title}<span className={styles.tiret}/>
            </div>
            <div className={styles.thumbs}>
                {
                    elements().map((name, idx) => {
                        return (
                            <RowThumb key={name} name={name} index={(idx + from + 1)} onClick={() => {
                                onClick(name, idx)
                            }}
                            />
                        )
                    })
                }
                <div className={styles.separatorForced}></div>
            </div>
            {zoom && <ZoomGrid onClose={onClose} name={name} onNext={onNext} onPrevious={onPrevious}/>}
        </div>
    )
}

export default RowGrid