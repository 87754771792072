import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import styles from './UnderLink.module.css'
import cx from 'classnames'

const UnderLink = ({ label, to, action, className }) => {

    const dispatch = useDispatch()

    const onClick = () => {
        if (action){
            dispatch(action)
        }
    }

    return (
        <Link to={to} className={cx(styles.link, className)} onClick={onClick}>{label}</Link>
    )
}

export default UnderLink