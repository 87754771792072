import styles from './SessionLiveVideo.module.css'
import { useEffect } from 'react'
import useSubscriber from '../../hooks/useSubscriber'

const SessionLiveVideo = ({ iceServers, streamId }) => {
    
    const { subscribe } = useSubscriber(true, "red5")

    const googleServers = [{urls: 'stun:stun2.l.google.com:19302'}]

    useEffect(() => {
        subscribe(streamId, googleServers, "live-preview")
    }, [streamId])

    return (
        <div className={styles.container}>
            <video id="live-preview" width="1920" height="1080" className={styles.video} autoPlay/>
        </div>
    )
}

export default SessionLiveVideo
