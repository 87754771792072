import { takeLatest, call, put, select } from 'redux-saga/effects'
import RoomRedux from '../room/RoomRedux'

function *appRehydrated() {
  yield null;
}

let combine = [
  takeLatest('persist/REHYDRATE', appRehydrated)
]

export default combine