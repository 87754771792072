import styles from './SlideImage.module.css'
import config from '../../../config/config'
import expand from './expand.png'

const SlideImage = ({ width, height, index, onClick }) => {
    return (
        <div className={styles.container} style={{
            width: `${width}rem`,
            height: `${height}rem`
        }} onClick={() => onClick(index)} >
            <img className={styles.imgPhoto} src={`https://remote-sales.imgix.net/chanel/${config.waiting.gouaches[index]}?fm=jpg&w=${width * 20}&h=${height * 20}&fit=crop`}
                style={{
                    width: `${width}rem`,
                    height: `${height}rem`
                }}
            />
            <div className={styles.overlay}>
                <img src={expand} className={styles.expandIcon}/>
                <p>Cliquer pour agrandir</p>
            </div>
        </div>
    )
}

export default SlideImage