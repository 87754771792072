import { gql } from '@apollo/client'
import client from '../../index'

const query = (key, code) => {
    return new Promise((resolve) => {
        client.query({
            query: gql`
                query authenticateRoom($key:String!, $code:String!){
                    authenticateRoom(key:$key, code:$code){
                        success
                        liveKey
                    }
                }
            `,
            variables:{
                key,
                code
            }
        }).then((res) => {
            resolve(res.data.authenticateRoom)     
        })
    }) 
}

export default query