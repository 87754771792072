import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'

const HomeStep = {
    loading: "loading",
    checkRoom: "checkRoom",
    requestCode: "requestCode",
    error: "error",
    authenticated: "authenticated"
}


/* ------------- Initial state ------------- */

const initialState = Immutable({
    step: HomeStep.loading,
    error: null
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
    homeSetStep: ['step'],
    homeSetError: ['error'],
    homeLostPassword: null,
    homeReset: null
})
const actions = Creators

/* ------------- Reducers ------------- */

const homeSetStep = (state, { step }) => state.merge({ step })
const homeSetError = (state, { error }) => state.merge({ error })
const lostPassword = (state) => state.merge({step: HomeStep.error, error: 'lost_password_error'})
const reset = (state) => state.merge({step:HomeStep.loading, error:null})

/* ------------- Selectors ------------- */

const step = (state) => state.home.step
const error = (state) => state.home.error

const selectors = {
    step: createSelector([step],(_)=>_),
    error: createSelector([error],(_)=>_)
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
    [Types.HOME_SET_STEP]: homeSetStep,
    [Types.HOME_SET_ERROR]: homeSetError,
    [Types.HOME_LOST_PASSWORD]: lostPassword,
    [Types.HOME_RESET]: reset
})

const defaultExport = {
  actions, 
  selectors,
  reducer,
  types: Types,
  steps: HomeStep
}

export default defaultExport