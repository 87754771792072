import styles from './HeaderPhoto.module.css'
import LogoChanel from '../LogoChanel/LogoChanel'
import CountDown from '../CountDown/CountDown'
import ArrowButton from '../ArrowButton/ArrowButton'
import locale from '../../config/locale'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { motion } from 'framer-motion'

const HeaderPhoto = () => {
    return (
        <div className={styles.container}>
            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 1, duration: 0.75}}>
                <LogoChanel/>
            </motion.div>
            <div className={styles.title}>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 1.5, duration: 0.75}}>
                    <h1 className={styles.titleText}>{locale('title')}</h1>
                </motion.div>
                <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 2, duration: 0.75}}>
                    <CountDown/>
                </motion.div>
            </div>
    
            <motion.div className={styles.bottom} initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 4, duration: 0.75}}>
                <p>SCROLL DOWN</p>
                <motion.div className={styles.arrowDown} animate={{y: [0, 9, 0]}} transition={{ repeat: Infinity, ease: ['easeIn', 'easeOut'], duration: 1.0 }}>
                    <ArrowButton direction="down"/>
                </motion.div>
            </motion.div>

            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 1, duration: 0.75}}>
                <LanguageSelector/>
            </motion.div>
        </div>
    )
}

export default HeaderPhoto