import { useState, useEffect, useRef } from 'react'
import config from '../../../config/config'
import RoomCombo from './RoomCombo'
import styles from './RoomCameraChoose.module.css'
import RoomRedux from '../../../redux/room/RoomRedux'
import useRoom from '../../../hooks/useRoom'
import cx from 'classnames'
import { useParams } from 'react-router-dom'

const RoomCameraChoose = ({ stream, setStream, micro, camera, setMicro, setCamera, step }) => {

    const [status, setStatus] = useState('Requesting camera...')
    const [cameras, setCameras] = useState([])
    const [micros, setMicros] = useState([])
    const [deviceLoaded, setDeviceLoaded] = useState(false)
    const refVideo = useRef(null)
    const { layout } = useRoom()
    const { identifier } = useParams()

    useEffect(() => {
        navigator.mediaDevices.getUserMedia(config.room.mediaConstraints(camera, micro, config.room.platform().tstream)).then((result) => {
            setStatus('Camera is granted')
            setStream(result)
            loadDevices()
        }).catch((e) => {
            setStatus('Error : unable to grant camera')
        })
    }, [micro, camera])

    useEffect(() => {
        loadDevices()
    }, [])

    useEffect(() => {
        refVideo.current.srcObject = stream
    }, [stream])

    const loadDevices = () => {
        if (!deviceLoaded){
            navigator.mediaDevices.enumerateDevices().then((result) => {
                let camList = result.filter(c => c.kind === "videoinput")
                let micList = result.filter(c => c.kind === "audioinput")
                setCameras(camList)
                setMicros(micList)
                if (camera === undefined)setCamera(camList[0].deviceId)
                if (micro === undefined)setMicro(micList[0].deviceId)
                
            })
            setDeviceLoaded(true)
        }
    }

    const onChangeCamera = (e) => {
        setCamera(e.target.value)
    }

    const onChangeMicro = (e) => {
        setMicro(e.target.value)
    }

    return (
        <div className={styles.container}>
            <p>Choose your camera settings : {status}</p>
            <div className={cx(styles.videoContainer, layout === RoomRedux.layouts.live && styles.videoContainerLive)}>
                <video ref={refVideo} className={cx(styles.video, layout === RoomRedux.layouts.live && styles.videoLive)} id="publisher-preview" width="240" height="135" muted autoPlay/>
                <p className={styles.label}>{identifier}</p>
            </div>
            <div className={cx(step !== RoomRedux.steps.initial && styles.disabled)}> 
                <p>Camera : <RoomCombo items={cameras} onChange={onChangeCamera}/></p>
                <p>Microphone : <RoomCombo items={micros} onChange={onChangeMicro}/></p>
            </div>
        </div>
    )

}

export default RoomCameraChoose