import React, { useEffect } from 'react'
import useAdmin from './useAdmin'
import { useParams } from "react-router-dom"
import useRoom from '../../hooks/useRoom'
import usePublisherConfig from '../../hooks/usePublisherConfig'
import styles from './Admin.module.css'
import AdminRedux from '../../redux/admin/AdminRedux'
import AdminUser from './components/AdminUser/AdminUser'
import Immutable from 'seamless-immutable'

const Admin = () => {
    

    const { initialize, step, error, liveKey } = useAdmin()
    const { initialize: initializeRoom, occupants } = useRoom()
    const { slug } = useParams()

    useEffect(() => {
        initialize(slug)
        initializeRoom("admin")
    }, [slug, initialize])

    return (
        <div className={styles.container}>
            <section>Admin pannel</section>
            <section>Step : {step}</section>
            {
                step === AdminRedux.steps.error && (
                    <>
                        <section className={styles.error}>{error}</section>
                    </>
                )
            }
            {
                step === AdminRedux.steps.authenticated && (
                    <>
                        <section>Live key : {liveKey}</section>
                        <section>
                            Users :
                            <ol>
                            {
                                Immutable.asMutable(occupants).sort((a, b) => a.uuid.localeCompare(b.uuid)).filter(a => a.uuid !== 'admin').map(occupant => {
                                    return (
                                        <AdminUser key={occupant.uuid} uuid={occupant.uuid} {...occupant.state}/>
                                    )
                                })
                            } 
                            </ol>
                        </section>
                    </>
                )
            }
        </div>
    )
}

export default Admin