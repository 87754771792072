import useSubscriber from '../../hooks/useSubscriber'
import { useEffect, useState } from 'react'
import config from '../../config/config'
import useRoom from '../../hooks/useRoom'
import styles from './SessionLive.module.css'
import SessionUserVideo from '../SessionUserVideo/SessionUserVideo'
import SessionUsers from '../SessionUsers/SessionUsers'
import SessionLiveVideo from '../SessionLiveVideo/SessionLiveVideo'
import useSession from '../../hooks/useSession'
import ToolBar from '../ToolBar/ToolBar'
import useToggleMedia from '../../hooks/useToggleMedia'
import { useIdleTimer } from 'react-idle-timer'
import HdSelector from '../HdSelector/HdSelector'
import { motion } from 'framer-motion'
import Throbber from '../Throbber/Throbber'
import useApp from '../../hooks/useApp'

const variantsTool = {
    sleep: {
        bottom: '-8rem',
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    },
    active: {
        bottom: '2.5rem',
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    }
}

const SessionLive = () => {

    
    const { iceServers, occupants } = useRoom()
    const { identifier } = useSession()
    const [ room, setRoom ] = useState(null)
    const [ peer, setPeer ] = useState(null)
    const [ tool, setTool ] = useState(false)
    const [ full, setFull ] = useState(true)
    const { audio, video, onToggleAudio, onToggleVideo } = useToggleMedia(room, peer, true)
    const { thumbs } = useApp()

    const handleOnIdle = event => {
        setTool(false)
    }

    const handleOnActive = event => {
        setTool(true)
    }

    const handleOnAction = (e) => {
        setTool(true)
    }

    const onToggleFull = () => {
        setFull(!full)
    }

    useIdleTimer({
        timeout: 1000 * 10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

   


    return (
        <div className={styles.container} id="live-container">
            <SessionLiveVideo iceServers={iceServers} streamId={full ? "chanel" : "chanellow"} />
            {!thumbs && <SessionUserVideo room={room} setRoom={setRoom} peer={peer} setPeer={setPeer} audio={audio} video={video}/>}
            {!thumbs && room && <SessionUsers identifier={identifier} iceServers={iceServers} occupants={occupants} room={room}/>}
            <motion.div className={styles.toolbarContainer} animate={tool ? 'active' : 'sleep'} variants={variantsTool} initial="sleep">
                <ToolBar mic={audio} cam={video} onToggleCam={onToggleVideo} onToggleMic={onToggleAudio} elementId="live-container"/>
            </motion.div>
            {false && <HdSelector onToggle={onToggleFull} full={full}/>}
            <motion.div className={styles.cache}
                animate={{ opacity: 0, transitionEnd:{display:'none'} }}
                transition={{ duration: 2, delay: 5 }}
            >
                {false && <Throbber/>}
            </motion.div>

        </div>
    )
}

export default SessionLive