import styles from './LabelButton.module.css'
import cx from 'classnames'

const LabelButton = ({ label, onClick, className, disabled }) => {
    return (
        <div className={cx(styles.container, className, disabled === true && styles.disabled)} onClick={onClick}>
            {label}
        </div>
    )
}

export default LabelButton