import { gql } from '@apollo/client'
import client from '../../index'

const query = (key) => {
    return new Promise((resolve) => {
        client.query({
            query: gql`
                query existRoom($key:String!){
                    existRoom(key:$key)
                }
            `,
            variables:{
                key
            }
        }).then((res) => {
            resolve(res.data.existRoom)     
        })
    }) 
}

export default query