import { takeLatest, call, fork, select, take, put } from 'redux-saga/effects'
import SessionRedux from '../../redux/session/SessionRedux'
import config from '../../config/config'
import CameraRedux from './CameraRedux'
import LiveRedux from '../live/LiveRedux'
import { eventChannel, END } from 'redux-saga'
import factory from './StreamFactory'

function* initialize() {
    yield fork(initFactory)
}

function* initFactory(){
    const channel = yield call(factoryChannel)
    try {
        while (true){
            const event = yield take(channel)
            yield put(event)
        }
    } finally {
    }
}

function factoryChannel() {
    return eventChannel((emitter) => {
        const eventHandler = (e) => {
           emitter(e)
        }
        factory.onEvent = eventHandler
        return () => factory.onEvent = null
    })
}


function* requestPublish({ identifier }) {
    factory.createPublisher(identifier, `${identifier}-video`)
    let result = yield take([`FACTORY_PUBLISH_SUCCESS_${identifier}`, `FACTORY_PUBLISH_ERROR_${identifier}`])
    if (result.success){
        let devices = factory.getPublishersDevices(identifier)
        if (devices){
            yield put(LiveRedux.actions.liveSetVideoDevice(devices.videoDeviceId))
            yield put(LiveRedux.actions.liveSetAudioDevice(devices.audioDeviceId))
        }
    }
    yield put(CameraRedux.actions.cameraSetPublishing(result.success))
    yield put(LiveRedux.actions.livePing())
}

function* stopPublishing(){
    const identifer = yield select(SessionRedux.selectors.identifier)
    factory.removePublisher(identifer)
    yield take([`FACTORY_REMOVE_PUBLISH_ERROR_${identifer}`, `FACTORY_REMOVE_PUBLISH_SUCCESS_${identifer}`])
    yield put(CameraRedux.actions.cameraSetPublishing(false))
    yield put (CameraRedux.actions.cameraStopPublishingSuccess())
}

function* stopSubscribing({ identifier }){
    factory.removeSubscriber(identifier)
    yield take([`FACTORY_REMOVE_SUBSCRIBE_SUCCESS_${identifier}`, `FACTORY_REMOVE_SUBSCRIBE_ERROR_${identifier}`])
    yield put (CameraRedux.actions.cameraStopPublishingSuccess())
}

function requestSubscribe({elementId, identifier}){
    factory.createSubscriber(identifier, elementId)
}

function subscribeLiveRequest({channel, elementId}){
    factory.createSubscriber(channel, elementId)
}

function* stopSubscribingAllRequest(){
    factory.removeAllSubscribers()
    yield take([`FACTORY_REMOVE_SUBSCRIBE_ALL_SUCCESS`])
    yield put (CameraRedux.actions.cameraStopSubscribingAllSuccess())
}

function* mute({identifier}){
    factory.mute(identifier)
}

function* unMute({identifier }){
    factory.unMute(identifier)
}

function* muteMic({identifier}){
    factory.muteMic(identifier)
}

function* unMuteMic({identifier}){
    factory.unMuteMic(identifier)
}

function* muteCam({identifier}){
    factory.muteCam(identifier)
}

function* unMuteCam({identifier}){
    factory.unMuteCam(identifier)
}

function* checkBitrate({identifier}){
    factory.bitrateForSubscriber(identifier)
}  

function* bitrateDatas({identifier, bytesReceived, timestamp}){

    let bitrates = yield select(CameraRedux.selectors.bitrates)

    if (bitrates[identifier]){
        let br = bitrates[identifier].bytesReceived
        let ts = bitrates[identifier].timestamp

        let elapsed = timestamp - ts
        let bloaded = bytesReceived - br

        yield put(CameraRedux.actions.cameraSetBitrate(identifier, {
            bandwidth: Math.round(((bloaded * 8 / 1024) / elapsed) * 1000),
            bytesReceived,
            timestamp
        }))

    } else {
        yield put(CameraRedux.actions.cameraSetBitrate(identifier, {
            bandwidth: 0,
            bytesReceived,
            timestamp
        }))
    }

    yield put(LiveRedux.actions.livePing())
}

function* updateResolution({identifier}){
    let resolution = yield select(LiveRedux.selectors.resolution)
    factory.updateResolution(identifier, resolution)
}

function* changeVideoDevice({ videoDeviceId }){
    const identifer = yield select(SessionRedux.selectors.identifier)
    const audioDeviceId = yield select(LiveRedux.selectors.audioDeviceId)
    factory.changePublisherDevice(videoDeviceId, audioDeviceId, identifer)
}

function* changeAudioDevice({ audioDeviceId }){
    const identifer = yield select(SessionRedux.selectors.identifier)
    const videoDeviceId = yield select(LiveRedux.selectors.videoDeviceId)
    factory.changePublisherDevice(videoDeviceId, audioDeviceId, identifer)
}

let combine = [
    takeLatest('CAMERA_REQUEST_PUBLISH', requestPublish),
    takeLatest('CAMERA_STOP_PUBLISHING_REQUEST', stopPublishing),
    takeLatest('CAMERA_INITIALIZE', initialize),
    takeLatest('CAMERA_REQUEST_SUBSCRIBE', requestSubscribe),
    takeLatest('CAMERA_STOP_SUBSCRIBING_REQUEST', stopSubscribing),
    takeLatest('CAMERA_SUBSCRIBE_LIVE_REQUEST', subscribeLiveRequest),
    takeLatest('CAMERA_STOP_SUBSCRIBING_ALL_REQUEST', stopSubscribingAllRequest),
    takeLatest('CAMERA_MUTE', mute),
    takeLatest('CAMERA_UN_MUTE', unMute),
    takeLatest('CAMERA_MUTE_MIC', muteMic),
    takeLatest('CAMERA_UN_MUTE_MIC', unMuteMic),
    takeLatest('CAMERA_MUTE_CAM', muteCam),
    takeLatest('CAMERA_UN_MUTE_CAM', unMuteCam),
    takeLatest('CAMERA_CHECK_BITRATE', checkBitrate),
    takeLatest('FACTORY_BITRATE_DATAS', bitrateDatas),
    takeLatest('CAMERA_UPDATE_RESOLUTION', updateResolution),
    takeLatest('CAMERA_CHANGE_VIDEO_DEVICE', changeVideoDevice),
    takeLatest('CAMERA_CHANGE_AUDIO_DEVICE', changeAudioDevice)

]
  
export default combine