import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import RoomRedux from '../redux/room/RoomRedux'
import config from '../config/config'
import ky from 'ky'

const Hook = () => {
    
    const [loading, setLoading] = useState(true)
    const [iceServers, setIceServers] = useState(null)
    const dispatch = useDispatch()

    const initlizePublisher = useCallback(async (identifier) => {
        //
        // Load ices
        //
        let json = await ky.put(config.room.xirsysUrl, {
            json:{
                fomat: 'urls'
            },
            headers: {
                'Authorization' : `Basic ${config.room.xirsysToken}`
            }
        }).json()

        if (json.s === "ok"){
            dispatch(RoomRedux.actions.roomSetIceServers(json.v.iceServers))
            setIceServers(json.v.iceServers)
            setLoading(false)
        }

    }, [ setIceServers, setLoading ])

    return {
        initlizePublisher,
        config: {
            iceServers
        },
        loading
    }


}

export default Hook