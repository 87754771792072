import { gql } from '@apollo/client'
import client from '../../index'

const query = (key) => {
    return new Promise((resolve) => {
        client.query({
            query: gql`
                query existAdmin($key:String!){
                    existAdmin(key:$key){
                        success
                        liveKey
                    }
                }
            `,
            variables:{
                key
            }
        }).then((res) => {
            resolve(res.data.existAdmin)     
        })
    }) 
}

export default query