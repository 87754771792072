import { useSelector, useDispatch } from 'react-redux'
import AppRedux from '../redux/app/AppRedux'

const Hook = () => {

    const dispatch = useDispatch()

    const thumbs = useSelector(AppRedux.selectors.thumbs)
    const transitionEnd = useSelector(AppRedux.selectors.transitionEnd)

    const hideThumbs = (value) => {
        dispatch(AppRedux.actions.appHideThumbs(value))
    }

    return {
        thumbs,
        hideThumbs,
        transitionEnd
    }
}

export default Hook