import { useEffect, useRef, useState } from 'react'
import styles from './VideoFull.module.css'
import Throbber from '../Throbber/Throbber'
import { motion } from 'framer-motion'
import useApp from '../../hooks/useApp'
import LogoChanel from '../LogoChanel/LogoChanel'

const variants = {
    inital: {
        opacity: 0
    },
    on: {
        opacity: 1,
        transition: {
            duration: 3
        }
    },
    off: {
        opacity: 0,
        transition: {
            duration: 3
        }
    }
}

const variantsTitle = {
    initial: {
        opacity: 0
    },
    on: {
        opacity: 1,
        transition: {
            delay: 2,
            duration: 2
        }
    },
    off: {
        opacity: 0,
        transition:{
            duration: 2
        }
    }
}



const VideoFull = ({ onComplete, url, showTitle }) => {

    const refVideo = useRef(null)
    const [anime, setAnime] = useState("initial")
    const [animTitle, setAnimTitle] = useState("initial")
    const { hideThumbs } = useApp()

    const titleOff = () => {
        setAnimTitle("off")
    }

    const animeOff = () => {
        setAnime("off")
    }

    useEffect(() => {
        refVideo.current.oncanplay = () => {
            setAnime("on")
            refVideo.current.play()
        }

        refVideo.current.onended = () => {
            setAnime("off")
        }

        console.log("HIDE THUMBS")
        hideThumbs(true)
      
        if (showTitle){
            setAnimTitle("on")
        }
        

        setTimeout(titleOff, 7500)

    }, [])

    const onAnimationComplete = () => {
        if (anime === "off"){
            onComplete()
            if (showTitle){
                hideThumbs(false)
            }
        }
    }

    return (
        <div className={styles.container}>
            <motion.div variants={variants} initial={'inital'} animate={anime} className={styles.videoContainer} onAnimationComplete={onAnimationComplete}>  
                <video ref={refVideo} className={styles.video} src={url} muted preload="auto"></video>
            </motion.div>
            <motion.div initial={'initial'} animate={animTitle} variants={variantsTitle} className={styles.titleContainer}>
                <LogoChanel className={styles.logoChanel}/>
                ESCALE à venise
            </motion.div>
        </div>
    )
}

export default VideoFull