import HomeRedux from '../../redux/home/HomeRedux'
import { useSelector } from 'react-redux'

const Hook = () => {
    const step = useSelector(HomeRedux.selectors.step)
    const error = useSelector(HomeRedux.selectors.error)

    return {
        step,
        error
    }
}

export default Hook