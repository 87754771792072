import PubNub from 'pubnub'
import RoomRedux from '../../redux/room/RoomRedux'

class PubFactory {

    constructor() {
        this.intialized = false
        this.pub = null;
        this.step = RoomRedux.steps.initial
    }

    initialize(identifier) {
        if (this.intialized) return

        this.intialized = true
        this.identifier = identifier

        this.pub = new PubNub({
            publishKey: "pub-c-bee9e435-70d3-4d2d-9b1f-9d5975b6622c",
            subscribeKey: "sub-c-f95f5e62-8b54-11eb-a47e-8aa5932e3236",
            uuid: identifier,
            presenceTimeout: 20,
            ssl: true
        })

        this.pub.addListener({
            message: this.onMessage,
            presence: this.onPresence,
            signal: this.onSignal,
            objects: this.onObjects,
            messageAction: this.onMessageAction,
            file: this.onFile,
            status: this.onStatus
        })

        this.pub.subscribe({
            channels: ['lobby'],
            withPresence: true
        })

        this.hereNow()

        this.setState({
            step: RoomRedux.steps.initial
        })
    }

    hereNow = async () => {
        let result = await this.pub.hereNow({
            channels: ['lobby'],
            includeUUIDs: true,
            includeState: true,
        })

        console.log(result.channels.lobby.occupants)

        this.sendEvent('ROOM_REQUEST_OCCUPANTS', {
            occupants: result.channels.lobby.occupants
        })
    }

    setState = (state) => {
        console.log("SET STATE --->>>>>", state)
        this.pub.setState({
            state,
            channels: ['lobby']
        })
    }

    sendEvent = (type, payload = {}) => {
        if (this.onEvent){
            this.onEvent({
                type, ...payload
            })
        }
    }

    publishMessage = (message) => {
        let payload = {
            channel : "lobby",
            message
        }
        this.pub.publish(payload, () => {
            console.log("Sended")
        })
    }

    onMessage = (e) => {
        console.log("Message")
        if (e.publisher === "admin"){
            this.sendEvent(`ROOM_PUB_EVENT`, e.message)
        }
    }

    onPresence = (e) => {
        console.log("Presence")
        
        this.sendEvent('ROOM_INVALIDATE')
        this.sendEvent('ROOM_SET_OCCUPANCY', {value: e.occupancy})
        this.hereNow()
    }

    onSignal = () => {
        console.log("Signal")
    }

    onObjects = () => {
    }

    onMessageAction = () => {
    }

    onFile = () => {
    }

    onStatus = (e) => {
        console.log("Status")
    }



}

const factory = new PubFactory()

export default factory