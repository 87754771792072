import styles from './RoomDebug.module.css'

const RoomDebug = ({ identifier, iceServers, occupants, step }) => {
    return (
        <div className={styles.container}>
            <p>Occupants : {occupants.filter(o => o.uuid !== 'tstream').length}</p>
            <p>Identifier : {identifier}</p>
            <p>Step : {step}</p>
            <p>Ice Servers : {iceServers.length}</p>
        </div>
    )
}

export default RoomDebug