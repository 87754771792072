import styles from './SliderPannel.module.css'
import { useState } from 'react'
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import leftArrow from './left-arrow.png'
import rightArrow from './right-arrow.png'
import config from '../../config/config'
import SlidePannel from './SlidePannel/SlidePannel'
import cx from 'classnames'
import '@brainhubeu/react-carousel/lib/style.css'

const SliderPannel = () => {

    const [page, setPage] = useState(0)
    const [indexes] = useState(['1', '2', '3', '4', '5'])

    const renderLeftArrow = (enabled) => {
        return <img src={leftArrow} className={cx(styles.arrow, styles.arrowLeft, !enabled && styles.arrowOff)} onClick={()=>{onChangePage(-1)}}/>
    }

    const renderRightArrow = (enabled) => {
        return <img src={rightArrow} className={cx(styles.arrow, styles.arrowRight, !enabled && styles.arrowOff)} onClick={()=>{onChangePage(1)}}/>
    }

    const onChangePage = (value) => {
        setPage(page + value)
        //console.log(value)
    }

    const onChangedPage = (value) => {
        //setPage(page + value)
        setPage(value)
        console.log("page changed", value)
    }

    const urlHd = (index) => {
        const url = config.waiting.gouachesHdUrl
        return url.split('%name%').join(config.waiting.gouaches[index])
    }

    return (
        <div className={styles.container}>
            <Carousel
                value={page}
                className={styles.caroussel}
                onChange={onChangedPage}
                draggable={true}
                plugins={[
                    'centered',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 2,
                        },
                    },
                    {
                        resolve: arrowsPlugin,
                        options: {
                            arrowLeft: renderLeftArrow(true),
                            arrowRight: renderRightArrow(true),
                            arrowLeftDisabled: renderLeftArrow(false),
                            arrowRightDisabled: renderRightArrow(false),
                            addArrowClickHandler: true
                        }
                    }
                ]}
            >
                <SlidePannel title="camelia" from={0} length={8} />
                <SlidePannel title="constellation" from={10} length={12} />
                <SlidePannel title="éblouissante" from={20} length={8} />
                <SlidePannel title="ruban canotier" from={30} length={7} />
                <SlidePannel title="volute marine" from={40} length={6} />
            </Carousel>
            <div className={styles.pagination}>
                {indexes.map((label, index) => {
                    return (
                        <p onClick={() => {
                            console.log("SET PAGE", index)
                            setPage(index)
                        }} className={cx(styles.paginationItem, page === index && styles.paginationItemSelected)} key={label}>{label}</p>
                    )
                })}
            </div>
        </div>
    )
}

export default SliderPannel