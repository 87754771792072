import styles from './RoomLogos.module.css'
import red5Icon from './red5.png'
import millicastIcon from './millicast.png'
import twilioIcon from './twilio.png'
import separatorIcon from './separator.png'
import config from '../../../config/config'

const icons = {
    red5: red5Icon,
    millicast: millicastIcon,
    twilio: twilioIcon
}

const RoomLogos = () => {
    return (
        <div className={styles.container}>
            <img className={styles.logo} src={icons[config.room.platform().platform]} alt="platform-icon"/>
            <img className={styles.separator} src={separatorIcon} alt="separator"/>
            <img className={styles.logo} src={icons[config.room.platform().livered ? "red5" : config.room.platform().platform]} alt="platform-icon"/>
        </div>
    )
}

export default RoomLogos