import { useEffect, useState } from 'react'
import useRoom from '../../hooks/useRoom'
import SessionLive from '../../components/SessionLive/SessionLive'
import VideoFull from '../../components/VideoFull/VideoFull'
import config from '../../config/config'
import useApp from '../../hooks/useApp'
import styles from './Session.module.css'
import { motion } from 'framer-motion'
import LogoChanel from '../../components/LogoChanel/LogoChanel'

const variants = {
    off:{
        opacity: 0
    },
    appear: {
        opacity: 1,
        transition: {
            duration: 1
        }
    }
}

const variantsLogo = {
    off: {
        opacity: 0
    },
    appear: {
        opacity: 1,
        transition:{
            duration: 1,
            delay: 5
        }
    }
}

const Session = () => {
    const { joinLive } = useRoom()

    const [transition, setTransition] = useState(null)
    const { transitionEnd } = useApp()

    useEffect(() => {
        joinLive()    
        setTransition(config.transitions.intro)
    }, [])

    const onComplete = () => {
        setTransition(null)
    }

    const onCompleteOutro = () => {

    }

    const renderEnd = () => {
        return (
            <motion.div className={styles.transitionContainer} initial={'initial'} variants={variants} animate={transitionEnd}>
                <VideoFull url={config.transitions.outro} onComplete={onCompleteOutro} showTitle={false}/>
                <motion.div className={styles.logoChanel} variants={variantsLogo} animate={transitionEnd} initial={'initial'}>
                    <LogoChanel />
                </motion.div>
            </motion.div>
        )
    }
    
    return (
        <div>
            <SessionLive/>
            {transition && <VideoFull url={transition} onComplete={onComplete} showTitle={true}/>}
            {transitionEnd == "appear" && renderEnd()}
        </div>
    )
}

export default Session