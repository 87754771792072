import Immutable from 'seamless-immutable'
import RoomRedux from '../../redux/room/RoomRedux'
import SessionUser from '../SessionUser/SessionUser'
import { useState } from 'react'
import styles from './SessionUsers.module.css'
import ArrowButton from '../ArrowButton/ArrowButton'

const SessionUsers = ({ occupants, identifier, room, iceServers }) => {

    const [expand, setExpand] = useState(true)

    const filteredOccupants = () => {
        let result = Immutable.asMutable(occupants)
        let filtered = result.filter(o => o.uuid !== identifier && o.state.step === RoomRedux.steps.broadcast &&  o.uuid !== 'feed')
        return filtered.sort((a, b) => a.uuid.localeCompare(b.uuid))
    }

    const onToggleExpand = () => {
        setExpand(!expand)
    }

    return (
        <div className={styles.container}>
            {filteredOccupants().length > 0 && <ArrowButton direction={expand ? 'down' : 'up'} className={styles.arrowButton} onClick={onToggleExpand}/>}
            {
                filteredOccupants().map((user, index) => {
                    return (
                        <SessionUser key={user.uuid} index={index} uuid={user.uuid} room={room} iceServers={iceServers} hidden={!expand}/>
                    )
                })
            }
        </div>
    )
}

export default SessionUsers