import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'

const RoomStep = {
    loading: "loading",
    initial: "initial",
    settings: "settings",
    broadcast: "broadcast",
    cameraError: "cameraError",
    cameraGranted: "cameraGranted",
    confirmed: "confirmed",
    allowed: "allowed",
    live: "live"
}

const RoomLayout = {
    debug: "debug",
    live: "live"
}


/* ------------- Initial state ------------- */

const initialState = Immutable({
    occupancy: 1,
    invalidate: false,
    identifier: null,
    occupants: [],
    step: RoomStep.loading,
    token: "",
    tokenError: "",
    layout: RoomLayout.debug,
    videoId: null,
    audioId: null,
    iceServers: [],
    names: {}
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
    roomInitialize: ['identifier'],
    roomSetOccupants: ['occupants'],
    roomRequestOccupants: ['occupants'],
    roomStartBroadcast: null,
    roomStartSettings: null,
    roomSetOccupancy: ['value'],
    roomInvalidate: null,
    roomValidate: null,
    roomSetToken: ['value'],
    roomSetTokenError: ['value'],
    roomToggleLayout: null,
    roomInviteUser: ['uuid'],
    roomAllowUser: ['uuid'],
    roomKickUser: ['uuid'],
    roomEndUser: ['uuid'],
    roomRenameUser: ['uuid', 'name'],
    roomCameraError: null,
    roomCameraGranted: null,
    roomSetVideoId: ['id'],
    roomSetAudioId: ['id'],
    roomConfirmSettings: null,
    roomAllow: null,
    roomJoinLive: null,
    roomSetIceServers: ['value'],
    roomSetName: ['name'],
    roomReset: null
})
const actions = Creators

/* ------------- Reducers ------------- */

const reset = (state) => initialState
const setName = (state, {name}) => state.merge({names: {...state.names, ...name}})
const roomInvalidate = (state) => state.merge({ invalidate: true })
const setVideoId = (state, {id}) => state.merge({ videoId : id }) 
const setAudioId = (state, {id}) => state.merge({ audioId: id })
const setToken = (state, { value }) => state.merge({ token: value })
const setTokenError = (state, { value }) => state.merge({ tokenError: value })
const roomValidate = (state) => state.merge({ invalidate: false })
const setIdentifier = (state, { identifier }) => state.merge({ identifier })
const setOccupancy = (state, { value }) => state.merge({ occupancy: value })
const setIceServers = (state, {value}) => state.merge({iceServers:value})
const setOccupants = (state, { occupants }) => {
    let self = occupants.filter(o => o.uuid === state.identifier)
    let newState = {
        occupants
    }
    if (self.length === 1) {
        newState.step = self[0].state.step
    }
    return state.merge(newState)
}

const toggleLayout = (state) => {
    if (state.layout === RoomLayout.debug){
        return state.merge({ layout: RoomLayout.live })
    } else {
        return state.merge({ layout: RoomLayout.debug })
    }
}

/* ------------- Selectors ------------- */

const identifier = (state) => state.room.identifier
const invalidate = (state) => state.room.invalidate
const occupants = (state) => state.room.occupants
const step = (state) => state.room.step
const occupancy = (state) => state.room.occupancy
const token = (state) => state.room.token
const tokenError = (state) => state.room.tokenError
const layout = (state) => state.room.layout
const videoId = (state) => state.room.videoId
const audioId = (state) => state.room.audioId
const iceServers = (state) => state.room.iceServers
const names = (state) => state.room.names


const selectors = {
    names: createSelector([names], (_)=>_),
    layout: createSelector([layout], (_)=>_),
    invalidate: createSelector([invalidate], (_) => _),
    occupancy: createSelector([occupancy], (_) => _),
    identifier: createSelector([identifier], (_) => _),
    occupants: createSelector([occupants], (_) => _),
    step: createSelector([step], (_) => _),
    token: createSelector([token], (_) => _),
    tokenError: createSelector([tokenError], (_) => _),
    videoId: createSelector([videoId], (_)=>_),
    audioId: createSelector([audioId], (_)=>_),
    iceServers: createSelector([iceServers], (_)=>_)
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
    [Types.ROOM_INITIALIZE]: setIdentifier,
    [Types.ROOM_SET_OCCUPANTS]: setOccupants,
    [Types.ROOM_SET_OCCUPANCY]: setOccupancy,
    [Types.ROOM_INVALIDATE]: roomInvalidate,
    [Types.ROOM_VALIDATE]: roomValidate,
    [Types.ROOM_SET_TOKEN]: setToken,
    [Types.ROOM_SET_TOKEN_ERROR]: setTokenError,
    [Types.ROOM_TOGGLE_LAYOUT]: toggleLayout,
    [Types.ROOM_SET_VIDEO_ID]: setVideoId,
    [Types.ROOM_SET_AUDIO_ID]: setAudioId,
    [Types.ROOM_SET_ICE_SERVERS]: setIceServers,
    [Types.ROOM_SET_NAME]: setName,
    [Types.ROOM_RESET]: reset

})

const defaultExport = {
    actions,
    selectors,
    reducer,
    types: Types,
    steps: RoomStep,
    layouts: RoomLayout
}

export default defaultExport