import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
    //uri: process.env.NODE_ENV === 'development' ? 'https://192.168.1.19:3001/graphql' : 'https://graphql.livetch.com/',
    uri: 'https://graphql.livetch.com/',
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        }
    }
})

export default client