import styles from './PlayButton.module.css'
import play1 from './play.png'
import play2 from './play@2x.png'
import playw1 from './playw.png'
import playw2 from './playw@2x.png'
import cx from 'classnames'

const colors = {
    black: {
        normal: play1,
        retina: play2
    }, 
    white: {
        normal: playw1,
        retina: playw2        
    }
}

const PlayButton = ({ className, onClick, color="black" }) => {
    return (
        <img src={colors[color].normal} alt='Play' srcSet={colors[color].normal + ' 1x,' + colors[color].retina + ' 2x'} className={cx(styles.container, className)} onClick={onClick}/>
    )
}

export default PlayButton