import styles from './Waiting.module.css'
import LogoChannel from '../../components/LogoChanel/LogoChanel'
import Caroussel from '../../components/Caroussel/Caroussel'
import SliderPannel from '../../components/SliderPannel/SliderPannel'
import StickChrono from '../../components/StickChrono/StickChrono'
import WelcomePopin from '../../components/WelcomePopin/WelcomePopin'
import HeaderPhoto from '../../components/HeaderPhoto/HeaderPhoto'
import ZoomGouache from '../../components/ZoomGouache/ZoomGouache'
import locale from '../../config/locale'
import { motion } from 'framer-motion'
import { useState, useEffect, useRef } from 'react'
import useRoom from '../../hooks/useRoom'
import useSession from '../../hooks/useSession'
import useCamera from '../../hooks/useCamera'
import RowGrid from '../../components/RowGrid/RowGrid'
import CameraSettings from '../../components/CameraSettings/CameraSettings'
import StartPopin from '../../components/StartPopin/StartPopin'
import useZoom from '../../hooks/useZoom'
import { useMediaQuery } from 'react-responsive'
import {throttle} from "lodash"

const Waiting = () => {

    const [y, setY] = useState(0)
    const [h, setH] = useState(0)
    const el = useRef(null)


    const { initialize } = useRoom()
    const { popin } = useCamera()
    const { welcomed, identifier } = useSession()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' })
    const { zoom, urlHd, pageName, length, onNext, onPrevious, pageIndex, onClose } = useZoom()
    
    useEffect(() => {
        initialize(identifier)

        setH(window.innerHeight)

        console.log(window.innerHeight)

        const handleScroll = () => {
            if (el && el.current){
                setY(el.current.getBoundingClientRect().top);
            }
          }

            if (el){
                window.addEventListener(
                "scroll",
                handleScroll)
            }

        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [h])

    const [mask, setMask] = useState(true)

    return (
        <div className={styles.container} id="waiting" ref={el}>
            {!zoom && <StickChrono/>}
            <HeaderPhoto/>
            <div style={{position: 'relative', top: y / 3.0}}>
                <section className={styles.caroussel} style={{marginBottom: y / 3.0}}> 
                    <Caroussel/>
                </section>
            </div>
            <section className={styles.gouaches}>
                <header className={styles.gouachesHeader}>
                    <h2 className={styles.gouachesTitle}>{locale('gouaches_section_title')}</h2>
                </header>
                {!isTabletOrMobile && <SliderPannel/>}
                {
                    isTabletOrMobile && (
                        <>
                            <RowGrid title="camelia" from={0} length={10}/>
                            <RowGrid title="constellation" from={10} length={10}/>
                            <RowGrid title="éblouissante" from={20} length={10}/>
                            <RowGrid title="ruban canotier" from={30} length={10}/>
                            <RowGrid title="volute marine" from={40} length={10}/>
                        </>
                    )
                }
                <p className={styles.disclaimer}>{locale('disclaimer')}</p>
            </section>
            <footer className={styles.footer}>
                <div className={styles.lineMobile}/>
                <LogoChannel className={styles.logo} color='black'/>
            </footer>
            {mask && <motion.div className={styles.cache} animate={{opacity: 0.0, transition:{duration: 2}}} onAnimationComplete={()=>{setMask(false)}}/>}
            {popin && !isTabletOrMobile && <CameraSettings/>}
            {popin && isTabletOrMobile && <StartPopin/>}
            {!welcomed && <WelcomePopin/>}
            {zoom && <ZoomGouache  onCloseWindow={onClose} url={urlHd()} pageIndex={pageIndex()} count={length} pageName={pageName()} onNext={onNext} onPrevious={onPrevious}/>}
        </div>
    )
}

export default Waiting