import { takeLatest, call, put, fork, select, take } from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import AdminRedux from './AdminRedux'
import CameraRedux from '../camera/CameraRedux'
import existAdmin from '../../graphql/queries/admin/existAdmin'
import admin from './AdminFactory'

function* initialize({ slug }){
    let result = yield call (existAdmin, slug)
    if (result.success){
        yield put(AdminRedux.actions.adminSetLiveKey(result.liveKey))
        yield put(AdminRedux.actions.adminSetAdminKey(slug))
        yield put(AdminRedux.actions.adminSetStep(AdminRedux.steps.authenticated))
        yield put(AdminRedux.actions.adminStart())
    } else {
        yield put(AdminRedux.actions.adminSetStep(AdminRedux.steps.error))
        yield put(AdminRedux.actions.adminSetError('INVALID_ADMIN_KEY'))
    }
}

function* start(){
    console.log("START ADMIN")
    //yield fork(initAdmin)
    //yield put (CameraRedux.actions.cameraInitialize())
}

function* initAdmin(){
    let liveKey = yield select(AdminRedux.selectors.liveKey)
    admin.startListen(liveKey, 'ADMIN')

    const channel = yield call(adminChannel)
    try {
        while (true){
            const event = yield take(channel)
            yield put(event)
        }
    } finally {
    }
}

function adminChannel() {
    return eventChannel((emitter) => {
        const eventHandler = (e) => {
           emitter(e)
        }
        admin.onEvent = eventHandler
        return () => admin.onEvent = null
    })
}

function* eventFrame({type, ...frame}){
    yield put(AdminRedux.actions.adminSetFrame(frame))
}

function* forceReload(){
    admin.forceReload()
}

function* sendEvent({ event }){
    admin.sendEvent(event)
}

function* setUserName({identifier, name}){
    admin.setUserName(identifier, name)
}

function* deleteEntry({ identifier }){
    admin.deleteEntry(identifier)
}

function* unpublishedStream({ identifier }){
    let adminStep = yield select(AdminRedux.selectors.step)
    if (adminStep === AdminRedux.steps.authenticated){
        let frame = yield select(AdminRedux.selectors.frame)
        if (frame[`user_${identifier}`] !== undefined){
            if (frame[`user_${identifier}`].publishing === true){
                admin.offPublishing(frame[`user_${identifier}`], identifier)
            }
        }
    }
}

function* setLayout({value}){
    admin.setLayout(value)
}

let combine = [
    takeLatest('ADMIN_INITIALIZE', initialize),
    takeLatest('ADMIN_START', start),
    takeLatest('ADMIN_EVENT_FRAME', eventFrame),
    takeLatest('ADMIN_FORCE_RELOAD', forceReload),
    takeLatest('ADMIN_SEND_EVENT', sendEvent),
    takeLatest('ADMIN_SET_USER_NAME', setUserName),
    takeLatest('ADMIN_DELETE_ENTRY', deleteEntry),
    takeLatest('FACTORY_UNPUBLISHED_STREAM', unpublishedStream),
    takeLatest('ADMIN_SET_LAYOUT', setLayout)
]
  
export default combine