import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import config from '../../config/config'
import Immutable from 'seamless-immutable'

const AdminStep = {
    loading: "loading",
    error: "error",
    authenticated: "authenticated",
    logout: "logout"
}


/* ------------- Initial state ------------- */

const initialState = Immutable({
    step: AdminStep.loading,
    error: null,
    liveKey: null,
    adminKey: null,
    frame: {}
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
    adminSetStep: ['step'],
    adminSetError: ['error'],
    adminInitialize: ['slug'],
    adminSetLiveKey: ['key'],
    adminStart: null,
    adminSetAdminKey: ['key'],
    adminSetFrame: ['frame'],
    adminForceReload: null,
    adminSendEvent: ['event'],
    adminSetUserName: ['identifier', 'name'],
    adminDeleteEntry: ['identifier'],
    adminSetLayout: ['value']
})
const actions = Creators

/* ------------- Reducers ------------- */

const adminSetStep = (state, { step }) => state.merge({ step })
const adminSetError = (state, { error }) => state.merge({ error })
const adminSetLiveKey = (state, { key }) => state.merge({ liveKey: key })
const adminSetAdminKey = (state, { key }) => state.merge({adminKey: key})
const adminSetFrame = (state, { frame }) => state.merge({ frame })

/* ------------- Selectors ------------- */

const step = (state) => state.admin.step
const error = (state) => state.admin.error
const liveKey = (state) => state.admin.liveKey
const adminKey = (state) => state.admin.adminKey
const frame = (state) => state.admin.frame

const selectors = {
    step: createSelector([step],(_)=>_),
    error: createSelector([error],(_)=>_),
    liveKey: createSelector([liveKey],(_)=>_),
    adminKey: createSelector([adminKey],(_)=>_),
    users: createSelector([frame],(frame)=>{
        let result = []
        let keys = Object.keys(frame)
        keys.map(k => {
            
            if (k.indexOf('user_') === 0){
                let active = (new Date().getTime() - frame[k].ping) < config.admin.timeOutUserDelay
                result.push({
                    identifier: k.split('_')[1],
                    active,
                    name: frame[`name_${k.split('_')[1]}`] === undefined ? '' : `${frame[`name_${k.split('_')[1]}`]}`,
                    publishing: active && frame[k].publishing,
                    allowed: frame[k].allowed === undefined ? false :  frame[k].allowed,
                    bitrates: frame[k].bitrates,
                    resolution: frame[k].resolution === undefined ? config.live.defaultResolution :  frame[k].resolution
                })
            }
        })
        return result.sort((a, b) => a.identifier.localeCompare(b.identifier) )
    }),
    frame: createSelector([frame], (_)=>_),
    layout: createSelector([frame], (frame) => frame.layout)
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
    [Types.ADMIN_SET_STEP]: adminSetStep,
    [Types.ADMIN_SET_ERROR]: adminSetError,
    [Types.ADMIN_SET_LIVE_KEY]: adminSetLiveKey,
    [Types.ADMIN_SET_ADMIN_KEY]: adminSetAdminKey,
    [Types.ADMIN_SET_FRAME]: adminSetFrame
})

const defaultExport = {
  actions, 
  selectors,
  reducer,
  types: Types,
  steps: AdminStep
}

export default defaultExport