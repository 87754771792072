import styles from './WelcomePopin.module.css'
import locale from '../../config/locale'
import LabelButton from '../../components/LabelButton/LabelButton'
import useSession from '../../hooks/useSession'
import LogoChanel from '../../components/LogoChanel/LogoChanel'
import { useEffect } from 'react'


const WelcomePopin = () => {
    const text = locale('welcome')
    const { setWelcomed } = useSession()

    useEffect(() => {
        document.querySelector('body').style.overflow = "hidden"
        return () => {
            document.querySelector('body').style.removeProperty("overflow")
        }
    }, [])
    
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.header}>
                    <LogoChanel color="black" className={styles.logoChanel}/>
                </div>
                <div className={styles.text}>
                    {
                        text.map((t, index) => {
                            return (
                                <p key={index}>
                                    {index === 0 ? <b>{t}</b> : t}
                                </p>
                            )
                        })
                    }
                    <div className={styles.separator}/>
                    <div className={styles.author}><b>{locale('welcome_author')}</b></div>
                    <LabelButton label={locale('continue')} className={styles.continue} onClick={() => {
                        setWelcomed(true)
                    }}/>
                </div>
            </div>
        </div>
    )
}

export default WelcomePopin