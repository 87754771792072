import styles from './RowThumb.module.css'
import config from '../../config/config'

const RowThumb = ({ name, index, onClick }) => {
    
    const urlThumbnail = (name) => {
        const url = config.waiting.gouachesRowUrl
        return url.split('%name%').join(name)
    }

    const litteralIndex = (idx) => {
        let r = idx.toString()
        while (r.length < 4)r = "0" + r
        return r
    }

    return (
        <div className={styles.container} onClick={onClick}>
            <img src={urlThumbnail(name)} className={styles.photo}/>
            <div className={styles.footer}>
                <p>{name.split('.')[0]}</p>
                <p className={styles.numero}>{litteralIndex(index)}</p>
            </div>
        </div>
    )
}

export default RowThumb