import rand from 'random-key'
import firestore from 'firebase/firebase-firestore'
import config from '../../config/config'
import db from '../../firebase'
import Immutable from 'seamless-immutable'


class AdminFactory {

    constructor(){
        this.key=''
    }

    startListen(liveKey, key){
        this.liveKey = liveKey
        this.key = key
        db.collection('live').doc(liveKey).onSnapshot((doc) =>{
            if (doc.exists){
                this.broadCastEvent('FRAME', doc.data())
            } else {
                this.broadCastEvent('ERROR')
            }
        })
    }

    broadCastEvent(type, payload) {
        let event = {
            type: `${this.key}_EVENT_${type}`,
        }
        if (payload) event = {...event, ...payload}
        this.onEvent(event)
    }

    forceReload(){
        db.collection('live').doc(this.liveKey).update({forceLoad: rand.generate(8)})
    }

    pingFromUser(identifier, publishing, bitrates, allowed = false, resolution = config.live.defaultResolution, cam = true, mic = true){
        db.collection('live').doc(this.liveKey).update({[`user_${identifier}`]:{
            ping: new Date().getTime(),
            publishing,
            allowed,
            resolution, 
            cam, 
            mic,
            bitrates: bitrates === undefined ? {} : Immutable.asMutable(bitrates, {deep: true})
        }})
    }

    sendEvent(event){
        event.time = new Date().getTime()
        db.collection('live').doc(this.liveKey).update({event})
    }

    setUserName(identifier, name){
        db.collection('live').doc(this.liveKey).update({[`name_${identifier}`]:name})
    }

    setLayout(value) {
        db.collection('live').doc(this.liveKey).update({layout: value})
    }

    deleteEntry(identifier){
        db.collection('live').doc(this.liveKey).update({
            [`user_${identifier}`]: firestore.FieldValue.delete(),
            [`name_${identifier}`]: firestore.FieldValue.delete()
            
        })
    }

    offPublishing(user, identifier){
        db.collection('live').doc(this.liveKey).update({
            [`user_${identifier}`]:{
                ...user,
                publishing: false
            }
        })
    }
}

const admin = new AdminFactory()

export default admin
