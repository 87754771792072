import React from 'react'
import icon from './icon.png'
import styles from './Throbber.module.css'

const Throbber = () => {
    return (
        <div className={styles.container}>
            <img alt='throbber' src={icon}/>
        </div>
    )
}

export default Throbber