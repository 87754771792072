import styles from './SessionUserVideo.module.css'
import { useEffect, useState, useRef } from 'react'
import usePublisher from '../../hooks/usePublisher'
import useRoom from '../../hooks/useRoom'
import useSession from '../../hooks/useSession'
import config from '../../config/config'
import camoff from './camoff@2x.png'
import micoff from './micoff@2x.png'

const SessionUserVideo = ({ room, setRoom, peer, setPeer, video, audio }) => {

    const { iceServers, videoId, audioId, names } = useRoom()
    const { identifier } = useSession()
    const { publish } = usePublisher(peer, setPeer, "twilio")
    const [ stream, setStream ] = useState(null)
    const videoRef = useRef(null)

    useEffect(() => {
        console.log("Publish", identifier, videoId, audioId)

        navigator.mediaDevices.getUserMedia(config.room.mediaConstraints(videoId, audioId, config.room.platform().tstream)).then((result) => {
            setStream(result)
        }).catch((e) => {
        })

    }, [])

    useEffect(() => {
        if (stream){
            videoRef.current.srcObject = stream
            publish(identifier, iceServers, stream, room, setRoom)
        }
    }, [stream])

    return (
        <div className={styles.container}>
             <span id={identifier}/>
            <video ref={videoRef} className={styles.video} width={154} height={100} autoPlay muted/>
            <span className={styles.name}>{names[identifier] ? names[identifier] : ''}</span>
            {!video && <img src={camoff} className={styles.camoff}/>}
            {video && !audio && <img src={micoff} className={styles.micoff}/>}
        </div>
    )
}

export default SessionUserVideo