import { createActions, createReducer } from 'reduxsauce'
import { createSelector } from 'reselect'
import Immutable from 'seamless-immutable'


/* ------------- Initial state ------------- */

const initialState = Immutable({
    popin: false,
    publishing: false,
    bitrates:{}
})

/* ------------- Actions ------------- */

const { Types, Creators } = createActions({
    cameraInitialize: null,
    cameraShowPopin: null,
    cameraRequestPublish: ['identifier'],
    cameraSetPublishing: ['value'],
    cameraStopPublishingRequest: null,
    cameraStopPublishingSuccess: null,
    cameraStopSubscribingRequest: ['identifier'],
    cameraStopPublishingSuccess: ['identifier'],
    cameraRequestSubscribe: ['elementId', 'identifier'],
    cameraSubscribeLiveRequest: ['channel', 'elementId'],
    cameraStopSubscribingAllRequest: null,
    cameraStopSubscribingAllSuccess: null,
    cameraMute: ['identifier'],
    cameraUnMute: ['identifier'],
    cameraMuteMic: ['identifier'],
    cameraUnMuteMic: ['identifier'],
    cameraMuteCam: ['identifier'],
    cameraUnMuteCam: ['identifier'],
    cameraCheckBitrate: ['identifier'],
    cameraSetBitrate: ['identifier', 'datas'],
    cameraUpdateResolution: ['identifier'],
    cameraChangeVideoDevice: ['videoDeviceId'],
    cameraChangeAudioDevice: ['audioDeviceId']
})
const actions = Creators

/* ------------- Reducers ------------- */

const showPopin = (state) => state.merge({ popin: true })
const setPublishing = (state, {value}) => state.merge({ publishing: value})
const setBitrate = (state, {identifier, datas}) => state.merge({ bitrates: {
    ...state.bitrates,
    [identifier]: datas
}})

/* ------------- Selectors ------------- */

const popin = (state) => state.camera.popin
const publishing = (state) => state.camera.publishing
const bitrates = (state) => state.camera.bitrates


const selectors = {
    popin: createSelector([popin], (_)=>_),
    publishing: createSelector([publishing], (_)=>_),
    bitrates: createSelector([bitrates], (_)=>_)
}

/* ------------- Export ------------- */
const reducer = createReducer(initialState, {
    [Types.CAMERA_SHOW_POPIN]: showPopin,
    [Types.CAMERA_SET_PUBLISHING]: setPublishing,
    [Types.CAMERA_SET_BITRATE]: setBitrate
})

const defaultExport = {
  actions, 
  selectors,
  reducer,
  types: Types
}

export default defaultExport