import { useState, useEffect } from 'react'
import styles from './Mobile.module.css'
import MobileHeader from './MobileHeader/MobileHeader'
import MobileCameraChoose from './MobileCameraChoose/MobileCameraChoose'
import LabelButton from '../../components/LabelButton/LabelButton'
import useRoom from '../../hooks/useRoom'
import usePublisherConfig from '../../hooks/usePublisherConfig'
import useToggleMedia from '../../hooks/useToggleMedia'
import { useParams } from 'react-router-dom'
import usePublisher from '../../hooks/usePublisher'
import RoomRedux from '../../redux/room/RoomRedux'
import MobileLive from './MobileLive/MobileLive'
import MobileUsers from './MobileUsers/MobileUsers'
import ToolBar from '../../components/ToolBar/ToolBar'
import { useMediaQuery } from 'react-responsive'

import { useIdleTimer } from 'react-idle-timer'
import cx from 'classnames'
import { motion } from 'framer-motion'

const variantsTool = {
    sleep: {
        opacity: 0,
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    },
    active: {
        opacity: 1,
        transition: {
            ease: 'easeOut',
            duration: 0.5
        }
    }
}

const Mobile = () => {

    const [stream, setStream] = useState(null)
    const [room, setRoom] = useState(null)
    const [peer, setPeer] = useState(null)
    const [ tool, setTool ] = useState(true)
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const handleOnIdle = event => {
        setTool(false)
    }

    const handleOnActive = event => {
        setTool(true)
    }

    const handleOnAction = (e) => {
        setTool(true)
    }

    useIdleTimer({
        timeout: 1000 * 10,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    const { identifier } = useParams()
    const [connecting, setConnecting] = useState(false)

    const { audio, video, onToggleAudio, onToggleVideo } = useToggleMedia(room, peer, true)


    const { initialize, occupants, step, layout } = useRoom()
    const { initlizePublisher, loading, config } = usePublisherConfig()
    const { publish } = usePublisher(peer, setPeer)

    useEffect(() => {
        initialize(identifier)
        initlizePublisher(identifier)
    }, [ initialize ])

    const onJoin = () => {
        publish(identifier, config.iceServers, stream, room, setRoom)
        setConnecting(true)
    }

    const renderCamera  = () => {
        return (
            <>
                <MobileCameraChoose stream={stream} setStream={setStream} />
                <p className={styles.help}>Vous pouvez activer/désactiver votre micro et votre caméra à tout moment pendans la consultation.</p>
                <div className={styles.actions}>
                    <LabelButton label={connecting ? "Connexion..." : "Rejoindre "} className={styles.joinButton} disabled={!stream || connecting} onClick={onJoin}/>
                </div>
            </>
        )
    }

    const renderLive = () => {
        return (
            <>
                <MobileLive iceServers={config.iceServers} room={room}/>
                <MobileUsers stream={stream} occupants={occupants} iceServers={config.iceServers} room={room} identifier={identifier}/>
                <motion.div className={styles.tools} animate={tool || isPortrait ? 'active' : 'sleep'} variants={variantsTool} initial="active">
                    <ToolBar mic={audio} cam={video} onToggleCam={onToggleVideo} onToggleMic={onToggleAudio} mobile={true}/>
                </motion.div>
            </>
        )
    }

    return (
        <div className={cx(styles.container, step === RoomRedux.steps.broadcast && styles.live)}>
            <MobileHeader/>
            {step !== RoomRedux.steps.broadcast && renderCamera()}
            {step === RoomRedux.steps.broadcast && room && renderLive()}
        </div>
    )
}

export default Mobile