import { useMemo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CameraRedux from '../../redux/camera/CameraRedux'
import AdminRedux from '../../redux/admin/AdminRedux'

const Hook = () => {
    const dispatch = useDispatch()

    const step = useSelector(AdminRedux.selectors.step)
    const error = useSelector(AdminRedux.selectors.error)
    const liveKey = useSelector(AdminRedux.selectors.liveKey)
    const users = useSelector(AdminRedux.selectors.users)
    const layout = useSelector(AdminRedux.selectors.layout)
    const [preview, setPreview] = useState(false)

    const initialize = useCallback((slug) => {
        dispatch(AdminRedux.actions.adminInitialize(slug))
    }, [dispatch])

    const requestSubscribe = useCallback((elementId, identifier) => {
        dispatch(CameraRedux.actions.cameraRequestSubscribe(elementId, identifier))
    }, [dispatch])

    const requestStopSubscribe = useCallback((identifier) => {
        dispatch(CameraRedux.actions.cameraStopSubscribingRequest(identifier))

    }, [dispatch])

    const forceReload = () => {
        dispatch(AdminRedux.actions.adminForceReload())
    }

    const setUserName = (identifier, name) => {
        dispatch(AdminRedux.actions.adminSetUserName(identifier, name))
    }

    const sendDebug = (identifier, message) => {
        dispatch(AdminRedux.actions.adminSendEvent({
            type: "LIVE_EVENT_DEBUG_MESSAGE",
            identifier,
            message
        }))
    }

    const setLayout = (value) => {
        dispatch(AdminRedux.actions.adminSetLayout(value))
    }

    const forceLogOut = (identifier) => {
        dispatch(AdminRedux.actions.adminSendEvent({
            type: "LIVE_EVENT_FORCE_LOGOUT",
            identifier
        }))
    }

    const allowUser = (identifier) => {
        dispatch(AdminRedux.actions.adminSendEvent({
            type: "LIVE_EVENT_ALLOW_USER",
            identifier
        }))
    }

    const inviteUser = (identifier) => {
        dispatch(AdminRedux.actions.adminSendEvent({
            type: "LIVE_EVENT_INVITE_USER",
            identifier
        }))
    }

    const changeResolution = (identifier) => {
        dispatch(AdminRedux.actions.adminSendEvent({
            type: "LIVE_EVENT_CHANGE_RESOLUTION",
            identifier
        }))
    }

    const deleteEntry = (identifier) => {
        dispatch(AdminRedux.actions.adminDeleteEntry(identifier))
    }

    return {
        initialize,
        step,
        error,
        liveKey,
        users,
        forceReload,
        setUserName,
        sendDebug,
        forceLogOut,
        deleteEntry,
        preview,
        setPreview,
        requestSubscribe,
        requestStopSubscribe,
        inviteUser,
        setLayout,
        allowUser, 
        changeResolution,
        layout
    }
}

export default Hook