import styles from './MobileUsers.module.css'
import { useState, useRef, useEffect } from 'react'
import Immutable from 'seamless-immutable'
import RoomRedux from '../../../redux/room/RoomRedux'
import MobileUserVideo from '../MobileUserVideo/MobileUserVideo'
import ArrowButton from '../../../components/ArrowButton/ArrowButton'
import useRoom from '../../../hooks/useRoom'
import { useMediaQuery } from 'react-responsive'
import cx from 'classnames'
import { motion, useAnimation } from "framer-motion"

const MobileUsers = ({ occupants, identifier, iceServers, room, stream }) => {

    const [display, setDisplay] = useState(true)
    const [paint, setPaint] = useState(0)
    const { names } = useRoom()
    const refSelf = useRef(null)
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })


    useEffect(() => {
        refSelf.current.srcObject = stream
    }, [])
    
    const occupantsOrder = () => {
        let result = occupants.filter(o => o.uuid !== identifier && o.state !== undefined && o.uuid !== 'tstream' && o.uuid !== 'feed')
        result = result.filter(o => o.state.step === RoomRedux.steps.broadcast)
        return Immutable.asMutable(result).sort((a, b) => a.uuid.localeCompare(b.uuid))
    }

    const onToggleDisplay  = () => {
        setDisplay(!display)
    }

    return (
        <div className={styles.container}>
            <div className={styles.selfContainer}>
                <video className={cx(styles.self, !isPortrait && !display && styles.masked)} playsInline muted autoPlay ref={refSelf}/>
                {(display || isPortrait) && <p className={styles.pseudo}>{names && names[identifier] ? names[identifier] : ''}</p>}
            </div>
            {occupantsOrder().map((user, index) => {
                return (
                    <MobileUserVideo display={isPortrait || (display  && (index < (isPortrait ? 100 : 2))) } room={room} key={user.uuid} identifier={user.uuid} step={user.state.step} iceServers={iceServers}/>
                )
            })}
            <ArrowButton direction={display ? 'down' : 'up'} className={styles.arrow} onClick={onToggleDisplay}/>
        </div>
    )
}

export default MobileUsers