import styles from './VideoSlide.module.css'
import PlayButton from '../../PlayButton/PlayButton'
import { motion } from 'framer-motion'
import { useState, useRef } from 'react'
import cx from 'classnames'
import { useMediaQuery } from 'react-responsive'

const variants = {
    show: {
        opacity: 1
    },
    hide: {
        opacity: 0
    }
}

const VideoSlide = ({ preview, source }) => {

    const [playing, setPlaying] = useState(false)
    const video = useRef(null)
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' })

    const renderVideo = () => {
        return (
            <video 
            ref={video}
            className={styles.video} 
            controls>
                <source src={source} type="video/mp4" />
            </video>
        )
    }

    const togglePlay = () => {
        if (playing){
            video.current.pause()
        } else {
            video.current.play()
        }
        setPlaying(!playing)
    }

    const renderPad = () => {
        return (
            <div className={styles.pad}>
                <p className={styles.padIntro}>Escale à venise</p>
                <p className={styles.padTitle}>CAMélia<br/>Vénitien</p>
                <PlayButton className={styles.playButton} onClick={togglePlay} color="white"/>
            </div>
        )
    }
    
    return (
        <div className={styles.container}>
            <motion.div variants={variants} initial='show' animate={playing ? 'hide' : 'show'} className={styles.preview} style={{backgroundImage:`url(${preview})`}}/>
            <motion.div   
                variants={variants}
                initial='hide'
                animate={playing ? 'show' : 'hide'}
                className={cx(styles.videoContainer, playing && styles.videoContainerPlaying)}
                >
                {renderVideo()}
            </motion.div>
            {playing || renderPad()}
        </div>
    )
}

export default VideoSlide