import { useState } from 'react'
import styles from './Caroussel.module.css'
import VideoSlide from './VideoSlide/VideoSlide'
import ArrowButton from '../ArrowButton/ArrowButton'
import config from '../../config/config'
import { motion, AnimatePresence } from "framer-motion"
import { wrap } from "popmotion"
import cx from 'classnames'


const variants = {
    enter: (direction) => {
        return {
            opacity: 0,
            x: direction > 0 ? 1380 : -1380
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            x: direction < 0 ? 1380 : -1380,
            opacity: 0,
            zIndex: 0
        }
    }
}


const Caroussel = () => {

    const [[page, direction], setPage] = useState([0, 0])
    const slideIndex = wrap(0, config.waiting.videos.length, page);

    const paginate = (newDirection) => {
        setPage([page + newDirection, newDirection])
    }

    const onLeft = () => {
        paginate(-1)
    }

    const onRight = () => {
        paginate(1)
    }

    const onSelectPad = (value) => {
        setPage([value, 1])
    }

    return (
        <div className={styles.container}>
            <div className={styles.cadre}>
                <div className={styles.slideContainer}>
                    <AnimatePresence initial={false} custom={direction}>
                        {
                            <motion.div
                                custom={direction}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                key={page}
                                transition={{
                                    x: { type: "spring", stiffness: 1400, damping: 200 },
                                    opacity: { duration: 0.3 },
                                }}
                            >
                                <VideoSlide
                                    {...config.waiting.videos[slideIndex]}
                                />
                            </motion.div>
                        }
                    </AnimatePresence>
                </div>
                <ArrowButton direction='right' className={styles.rightArrow} onClick={onRight} />
                <ArrowButton direction='left' className={styles.leftArrow} onClick={onLeft} />
            </div>
            <div className={styles.paginator}>
                {
                    new Array(config.waiting.videos.length).fill('').map((_, i) => {
                        return (
                            <span onClick={() => {onSelectPad(i)}} className={cx(styles.dot, i === slideIndex && styles.dotSelected)} key={i}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Caroussel