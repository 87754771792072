
const RoomCombo = ({ items, onChange }) => {
    return (
        <select onChange={onChange} >
            {
                items.map(({ deviceId, label }) => {
                    return (
                        <option key={deviceId} value={deviceId} label={label}/>
                    )
                })
            }
        </select>
    )
}

export default RoomCombo