import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '../../config/config'
import { connect } from 'twilio-video'
import RoomRedux from '../../redux/room/RoomRedux'
import twilioToken from '../../graphql/queries/twilio/twilioToken'

const Hook = (peer, setPeer) => {

    const dispatch = useDispatch()

    const publish = useCallback(async (identifier, iceServers, stream, room, setRoom) => {

        //
        // Token & connect
        //
        const token = await twilioToken(identifier, config.room.twilio.room, 3600)

        let options = {
            name: config.room.twilio.room,
            tracks: stream.getTracks()
        }

        let result = await connect(token, options)

        if (config.room.platform().tstream) {
            result.localParticipant.setParameters({
                maxAudioBitrate: 64000,
                maxVideoBitrate: 3500000
            })
        }

        console.log("ICIIIII TWILIO STREAM")

        console.log(result.name)

        setPeer(true)
        setRoom(result)
        dispatch(RoomRedux.actions.roomStartBroadcast())

    })

    return {
        publish,
        peer
    }
}

export default Hook