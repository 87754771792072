import { useCallback, useState } from 'react'
import config from '../../config/config'

import {
    RTCSubscriber
  } from 'red5pro-webrtc-sdk'

const Hook = () => {

    const [stream, setStream] = useState(null)
    const [active, setActive] = useState(false)
    
    const subscribe = useCallback(async (identifier, iceServers, elementId) => {

        let subConfig = config.room.subscriberConfig(identifier, elementId, iceServers)

        let subscriber = new RTCSubscriber()

        subConfig.rtcConfiguration.iceServers = iceServers

        subscriber.init(subConfig).then(() => {
            return subscriber.subscribe()
        }).then(() => {
            setActive(true)
        })

        subscriber.on('*', (evt) => {
            /*if (evt.type === "Subscribe.Playback.Change"){
                if(evt.data.state === "Playback.PAUSED"){
                    setActive(false)
                }
            }*/
        })
    })

    return {
        subscribe,
        stream,
        setStream,
        active
    }
}

export default Hook