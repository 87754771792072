import { useRef, useEffect, useReducer } from 'react'
import styles from './MobileCameraChoose.module.css'
import config from '../../../config/config'
import locale from '../../../config/locale'

const MobileCameraChoose = ({ stream, setStream }) => {

    const refVideo = useRef(null)

    useEffect(() => {
        navigator.mediaDevices.getUserMedia(config.room.mediaConstraints(undefined, undefined, config.room.platform().tstream)).then((result) => {
            setStream(result)
        }).catch((e) => {
        })
    }, [])

    useEffect(() => {
        refVideo.current.srcObject = stream
    }, [stream])


    return (
        <div className={styles.container}>
            <video playsInline ref={refVideo} className={styles.video} id="publisher-preview" muted autoPlay/>
            {!stream && <div className={styles.infos}>{locale('mobile_no_camera')}</div>}
        </div>
    )
}
export default MobileCameraChoose