import { useEffect, useState } from 'react'
import styles from './DemoStreamPlayer.module.css'

let unefois = false

const DemoStreamPlayer = ({ identifier }) => {

    const [streamName, setStreamName] = useState(identifier)
    const [accountId, setAccountId] = useState('vhz7rj')
    const [iceServers, setIceServers] = useState([])
    const [url, setUrl] = useState(null)
    const [jwt, setJwt] = useState(null)

    useEffect(() => {

        console.log(unefois.toString())

        if (!unefois){
            unefois = true;


            var xhr = new XMLHttpRequest();
            xhr.open("POST", "https://director.millicast.com/api/director/subscribe", true)
            xhr.setRequestHeader("Content-Type", "application/json")

            xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                    var js = JSON.parse(xhr.responseText);
                    console.log("success:", js)
                    setUrl(js.data.urls[0])
                    setJwt(js.data.jwt)
                }
            }

            xhr.send(JSON.stringify({ streamAccountId: accountId, streamName: streamName, unauthorizedSubscribe: true }))
        }

    }, [streamName, accountId, setUrl, setJwt])

    const loadIces = () => {
        let xhr = new XMLHttpRequest();
         xhr.onreadystatechange = function(evt){
            if(xhr.readyState == 4 && xhr.status == 200){
                let res = JSON.parse(xhr.responseText);
                setIceServers([res.v.iceServers])
            }
         }
         xhr.open("PUT", "https://global.xirsys.net/_turn/ChanelPB", true);
         xhr.setRequestHeader ("Authorization", "Basic " + btoa("didierbrun:7d206dc6-80b6-11eb-a5b3-0242ac150002") );
         xhr.setRequestHeader ("Content-Type", "application/json");
         xhr.send( JSON.stringify({"format": "urls"}) );
    }

    const onConnect = () => {
        console.log("connecting")
        let conf = {
            iceServers: iceServers,
            rtcpMuxPolicy: "require",
            bundlePolicy: "max-bundle"
        }
        let pc = new RTCPeerConnection(conf)

        pc.ondatachannel = () => {
            console.log("DATA CHANNEL")
        }



        pc.ontrack = function (event) {

            console.log("ON TRACK")
            //Play it
            //console.log(event.streams[0])

            let vid = document.getElementById("video")

            if (vid.srcObject === null){
                vid.srcObject = event.streams[0];
            }
            //let vidWin = document.getElementsByTagName('video')[0];
            /*if (vidWin) {
                vidWin.srcObject = event.streams[0];
                vidWin.controls = true;
            }*/
        };

        let ws = new WebSocket(url + "?token=" + jwt);
        let receiveResponse = false

        ws.onerror = () => {
            console.log("WS ERROR")
        }

        ws.onmessage = (evt) => {
            let msg = JSON.parse(evt.data);

            if (!receiveResponse){
                receiveResponse = true
                let data = msg.data;


                let answer = new RTCSessionDescription({
                    type: 'answer',
                    sdp : data.sdp + "a=x-google-flag:conference\r\n"
                });

                pc.setRemoteDescription(answer).then(() => {
                    console.log("REMOTE SUCCESS")

                   

                }).catch(e => {
                    console.log("REMOTE FAIL")
                    console.log(e)
                })
            }

            if (msg.type === "event"){
   
                console.log("EVENT", evt)
            }
          
        }
        
        ws.onopen = function () {

            if (pc.addTransceiver) {
                console.log('transceiver!');
                //Create dummy stream
                const stream = new MediaStream();
                //Create all the receiver tracks
                pc.addTransceiver("audio", {
                    direction: "recvonly",
                    streams: [stream]
                });
                pc.addTransceiver("video", {
                    direction: "recvonly",
                    streams: [stream]
                });
            }

            let offer = pc.createOffer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            }).then(desc => {
                console.log('createOffer Success!');
                pc.setLocalDescription(desc)
                    .then(() => {
                        console.log(desc.sdp)
                        let data = {
                            streamId : streamName,//Millicast viewer streamId
                            sdp : desc.sdp
                          }
                          //create payload
                          let payload = {
                            type : "cmd",
                            transId : Math.floor(Math.random() * 1000),
                            name : 'view',
                            data : data
                          }

                          console.log(payload)

                          ws.send( JSON.stringify(payload) );

                    })
                    .catch(e => {
                        console.log('setLocalDescription failed: ', e);
                    })
            }).catch(e => {
                console.log('createOffer Failed: ', e);
            });

        }



    }


    return (
        <div>
            Demo Stream Player
            <p>URL : {url}</p>
            <p>JWT : {jwt}</p>
            <p>Ice servers : {iceServers.length}</p>
            <div onClick={onConnect}>Connect</div>
            <div onClick={loadIces}>Load Ices</div>
            <video id="video" width="640" height="480" controls autoPlay className={styles.video}/>
        </div>
    )
}

export default DemoStreamPlayer