import styles from './RoomPlatform.module.css'
import config from '../../../config/config'
import red5Icon from './red5.png'
import millicastIcon from './millicast.png'
import twilioIcon from './twilio.png'

const icons = {
    red5: red5Icon,
    millicast: millicastIcon,
    twilio: twilioIcon
}


const RootPlatform = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img className={styles.icon} alt={config.room.platform().platform} src={icons[config.room.platform().platform]}/>
                {config.room.platform().platform}
            </div>
            <div className={styles.infos}>
                <p className={styles.warning}>Desktop Chrome Browser Only</p>
                <p className={styles.version}>{config.room.version}</p>
            </div>
        </div>
    )
}

export default RootPlatform