import red5 from './subscribers/Red5Subscriber'
import milli from './subscribers/MilliSubscriber'
import twilio from './subscribers/TwilioSubscriber'
import config from '../config/config'

const Current = (live = false, force = undefined) => {
    if (force){
        if (force === "red5")return red5()
        if (force === "twilio")return twilio()
        if (force === "millicast")return milli()
    }

    if (config.room.platform().platform === "red5" || (config.room.platform().livered && live))return red5()
    if (config.room.platform().platform === "millicast")return milli()
    if (config.room.platform().platform === "twilio")return twilio()
}


export default Current