import styles from './AdminInput.module.css'
import AdminButton from '../AdminButton/AdminButton'
import { useState } from 'react'

const AdminInput = ({value:defaultValue, onValidate}) => {

    const [value, setValue] = useState(defaultValue)

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const onOk = () => {
        onValidate(value)
    }

    return (
        <div className={styles.container}>
            <input type='text' className={styles.input} value={value} onChange={onChange}/>
            <AdminButton label='Ok' className={styles.ok} onClick={onOk}/>
        </div>
    )
}

export default AdminInput