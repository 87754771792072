import styles from './ToggleButton.module.css'
import cx from 'classnames'
import mic1 from './icons/mic.png'
import mic2 from './icons/mic@2x.png'
import hp1 from './icons/hp.png'
import hp2 from './icons/hp@2x.png'
import cam1 from './icons/cam.png'
import cam2 from './icons/cam@2x.png'

const icons = {
    mic: { low: mic1, high: mic2 },
    hp: { low: hp1, high: hp2 },
    cam: { low: cam1, high: cam2 }
}

const ToggleButton = ({ icon, active, onClick }) => {
    return (
        <div className={cx(styles.container, active && styles.active)} onClick={onClick}>
            <img src={icons[icon].low} srcSet={`${icons[icon].low} 1x,${icons[icon].high} 2x`} alt={icon} className={styles.img}/>
        </div>
    )
}

export default ToggleButton