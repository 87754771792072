import { useState, useEffect } from 'react'
import styles from './ToolBar.module.css'
import ToolButton from '../ToolButton/ToolButton'
import useCamera from '../../hooks/useCamera'
import useSession from '../../hooks/useSession'

const ToolBar = ({ onToggleCam, onToggleMic, cam, mic, elementId, mobile = false }) => {

    const [fs, setFs] = useState(document.fullscreenElement !== null)


    useEffect(() => {
        
        const onFullScreenChange = () => {
            setFs(document.fullscreenElement !== null)
        }
        
        document.addEventListener('fullscreenchange', onFullScreenChange)

        return () => {document.removeEventListener('fullscreenchange', onFullScreenChange)}
    }, [setFs])

    const onToggleFullScreen = () => {
        console.log(document.fullscreenElement)

        if (!fs){
            let element = document.getElementById(elementId)
            if (element.requestFullscreen){
                element.requestFullscreen()
            }
        } else {
            document.exitFullscreen()
        }
    }

    return (
        <div className={styles.container}>
            <ToolButton icon='mic' on={mic} onClick={onToggleMic}/>
            <ToolButton icon='cam' on={cam} onClick={onToggleCam}/>
            {!mobile && <ToolButton icon='fs' on={!fs} onClick={onToggleFullScreen}/>}
            {mobile && <ToolButton icon='tool' on={true} onClick={() => {}}/>}
        </div>
    )
}

export default ToolBar