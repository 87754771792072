import styles from './ToolButton.module.css'
import micon1 from './icons/micon.png'
import micon2 from './icons/micon@2x.png'
import micoff1 from './icons/micoff.png'
import micoff2 from './icons/micoff@2x.png'
import camon1 from './icons/camon.png'
import camon2 from './icons/camon@2x.png'
import camoff1 from './icons/camoff.png'
import camoff2 from './icons/camoff@2x.png'
import fson1 from './icons/fson.png'
import fson2 from './icons/fson@2x.png'
import fsoff1 from './icons/fsoff.png'
import fsoff2 from './icons/fsoff@2x.png'
import toolon1 from './icons/toolon.png'
import tooloff1 from './icons/tooloff.png'
import toolon2 from './icons/toolon@2x.png'
import tooloff2 from './icons/tooloff@2x.png'

const icons = {
    mic: {
        on: { low: micon1, high: micon2 },
        off: { low: micoff1, high: micoff2 }
    },
    cam: {
        on: { low: camon1, high: camon2 },
        off: { low: camoff1, high: camoff2 }
    },
    fs: {
        on: { low: fson1, high: fson2 },
        off: { low: fsoff1, high: fsoff2 }
    },
    tool: {
        on: { low: toolon1, high: toolon2},
        off: { low: tooloff1, high: tooloff2}
    }
}

const ToolButton = ({on, icon, onClick}) => {

    const getIcons = () => {
        if (on){
            return icons[icon].on
        } else {
            return icons[icon].off 
        }
    }
    return (
        <div className={styles.container}>
            <img src={getIcons().low}   className={styles.icon} 
                                srcSet={getIcons().low + ' 1x,' + getIcons().high + ' 2x'} 
                                alt={icon} 
                                onClick={onClick}/>
        </div>
    )
}

export default ToolButton