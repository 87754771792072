import { useRef } from 'react'
import styles from './MediaList.module.css'
import useRoom from '../../hooks/useRoom'

const MediaList = ({ id, source, current }) => {

    const selectRef = useRef(null)

    const { setVideoId, setAudioId } = useRoom()

    const onChange = (e) => {
        let deviceId = selectRef.current.value

        if (id !== current){
            if (id === "video"){
                setVideoId(deviceId)
            } else {
                setAudioId(deviceId)
            }
        }
    }

    return (
        <div className={styles.container}>
            <select name={id} id={id} onChange={onChange} ref={selectRef} className={styles.select}>
                {
                    source.map(s => {
                        return <option defaultValue={s.deviceId === current} value={s.deviceId} key={s.deviceId}>{s.label}</option>
                    })
                }

            </select>
        </div>
    )
}

export default MediaList