import DemoStreamPlayer from './DemoStreamPlayer/DemoStreamPlayer'
import DemoStreamCamera from './DemoStreamCamera/DemoStreamCamera'
import DemoUserCount from './DemoUserCount/DemoUserCount'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import styles from './Demo.module.css'

const Demo = () => {

    const params = useParams()
    const [identifier] = useState(params.identifier)
    const [mode] = useState(params.mode)

    const renderPlayer = () => {
        return (
            <DemoStreamPlayer identifier={identifier} />
        )
    }

    const renderCamera = () => {
        return (
            <DemoStreamCamera identifier={identifier}/>
        )
    }

    return (
        <div className={styles.container}>
            <div>
                {mode === 'player' && renderPlayer()}
                {mode === 'publisher' && renderCamera()}
            </div>
        </div>
    )
}

export default Demo