import styles from './RoomUserVideo.module.css'
import RoomVideo from './RoomVideo/RoomVideo'
import RoomRedux from '../../../redux/room/RoomRedux'
import useRoom from '../../../hooks/useRoom'
import cx from 'classnames'

const RoomUserVideo = ({ identifier, step, iceServers, room }) => {

    const { layout } = useRoom()

    return (
        <div className={cx(styles.container, layout === RoomRedux.layouts.live && styles.containerLive)}>
            {step === RoomRedux.steps.broadcast && <RoomVideo room={room} identifier={identifier} iceServers={iceServers}/>}
            <p className={styles.label}>{identifier} <span className={styles.legend}>[ { step } ]</span></p>
        </div>
    )
}

export default RoomUserVideo