import styles from './MobileUserVideo.module.css'
import useSubscriber from '../../../hooks/useSubscriber'
import { useEffect, useReducer } from 'react'
import useRoom from '../../../hooks/useRoom'
import cx from 'classnames'

const MobileUserVideo = ({ identifier, iceServers, room, display }) => {

    const { subscribe, stream, active, audio, video } = useSubscriber()
    const { names } = useRoom()
    

    useEffect(() => {
        subscribe(identifier, iceServers, identifier, room)
    }, [])

    return (
        <div className={cx(styles.container, !display && styles.masked)}>
            <div id={`container-${identifier}`} className={cx(styles.videoContainer)} >
                <video id={identifier} playsInline autoPlay muted />
            </div>
            {display && <p className={styles.pseudo}>{names && names[identifier] ? names[identifier] : ''}</p>}
        </div>
    )
}

export default MobileUserVideo