import { useEffect, useState } from 'react'
import useRoom from '../../hooks/useRoom'
import usePublisherConfig from '../../hooks/usePublisherConfig'
import RoomDebug from './RoomDebug/RoomDebug'
import RoomStreamer from './RoomStreamer/RoomStreamer'
import RoomCameraChoose from './RoomCameraChoose/RoomCameraChoose'
import RoomUsers from './RoomUsers/RoomUsers'
import RoomLive from './RoomLive/RoomLive'
import RoomPlatform from './RoomPlatform/RoomPlatform'
import RoomToken from './RoomToken/RoomToken'
import { useParams } from 'react-router-dom'
import styles from './Room.module.css'
import RoomRedux from '../../redux/room/RoomRedux'
import cfg from '../../config/config'

const Room = () => {

    const { initialize, occupants, step, layout } = useRoom()
    const { initlizePublisher, loading, config } = usePublisherConfig()
    const { identifier } = useParams()
    const [peer, setPeer] = useState(null)
    const [stream, setStream] = useState(null)
    const [camera, setCamera] = useState(undefined)
    const [room, setRoom] = useState(null)
    const [micro, setMicro] = useState(undefined)
    const [liveCount, setLiveCount] = useState(0)

    useEffect(() => {
        initialize(identifier)
        initlizePublisher(identifier)
    }, [ initialize ])

    const renderUsers = () => {
        if (cfg.room.platform().platform === "twilio"){
            return (
                identifier !== "tstream" && occupants.length > 0 && room !== null && <RoomUsers room={room} occupants={occupants} identifier={identifier} {...config}/>
            )
        } else {
            return (
                occupants.length > 0 && <RoomUsers room={room} occupants={occupants} identifier={identifier} {...config}/>
            )
        }
    }

    const renderLive = () => {
        if (cfg.room.platform().platform === "twilio"){
            return (
                identifier !== 'tstream' && room !== null && config.iceServers && <RoomLive peer={peer} {...config} liveCount={liveCount} setLiveCount={setLiveCount} room={room}/>
            )
        } else {
            return (
                config.iceServers && <RoomLive {...config} liveCount={liveCount} peer={peer} setLiveCount={setLiveCount} room={room}/>
            )
        }
    }

    return (
        <div className={styles.container} id='room'>
            <div className={styles.left}>
                {layout === RoomRedux.layouts.debug && <RoomPlatform/>}
                {layout === RoomRedux.layouts.debug && loading && <p className={styles.preparing}>Preparing...</p>}
                {layout === RoomRedux.layouts.debug && !loading && <RoomDebug {...config} identifier={identifier} occupants={occupants} step={step}/>}
                <RoomCameraChoose step={step} stream={stream} setStream={setStream} micro={micro} setMicro={setMicro} camera={camera} setCamera={setCamera}/>
                <RoomStreamer peer={peer} setPeer={setPeer} identifier={identifier} micro={micro} camera={camera} {...config} stream={stream} room={room} setRoom={setRoom}/>
            </div>
            <div className={styles.right}>
                {renderLive()}
                {renderUsers()}
            </div>
        </div>
    )
}
export default Room