import styles from './CloseButton.module.css'
import close1 from './close.png'
import close2 from './close@2x.png'
import cx from 'classnames'

const CloseButton = ({ className, onClick }) => {
    return (
        <div className={cx(styles.container, className)} onClick={onClick}>
            <img src={close1} srcSet={close1 + ' 1x,' + close2 + ' 2x'} alt='Close'/>
        </div>
    )
}

export default CloseButton