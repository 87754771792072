const config = {
    transitions:{
        intro: "https://storage.googleapis.com/remote-sales-images/videos/intro_final.mp4",
        outro: "https://storage.googleapis.com/remote-sales-images/videos/outro_final.mp4"
    },
    images:{
        cover_1: "https://remote-sales.imgix.net/chanel/cover_1.png?fm=jpg"
    },
    imagesSettings: [
        'https://remote-sales.imgix.net/chanel/settings_0.png?fm=jpg',
        'https://remote-sales.imgix.net/chanel/settings_1.png?fm=jpg',
        'https://remote-sales.imgix.net/chanel/settings_2.png?fm=jpg',
        'https://remote-sales.imgix.net/chanel/settings_3.png?fm=jpg'
    ],
    room: {
        version: "0.0.19",
        platform: () => {

            let params = new URLSearchParams(document.location.search.substring(1))
            let platform = params.get("platform")
            let livered = params.get("livered") && params.get("livered") === "true" ? true : false
            let tstream = params.get("tstream") && params.get("tstream") === "true" ? true : false

            if (!platform) platform = "red5"


            switch (platform) {
                case "red5":
                    return {
                        label: "RED5",
                        stream: "chanel",
                        platform: "red5",
                        livered,
                        tstream
                    }
                case "millicast":
                    return {
                        label: "MILLICAST",
                        stream: !livered ? "kmo2axah" : "chanel",
                        platform: "millicast",
                        livered,
                        tstream
                    }
                case "twilio":
                    return {
                        label: "TWILIO",
                        stream: livered ? 'chanel' : 'tstream',
                        platform: "twilio",
                        livered,
                        tstream
                    }
            }
        },
        twilio: {
            room: "live",
            accountSid: "ACf7cdf426633bc726eb7e22349b1bb885",
            sid: "SKfcbcc7cdbcb2820941f823011add39d6",
            secret: "fbVgDjs1UmOIRjIYrlipSQdXr4sDy3gx"
        },
        dacast: {
            
        },
        liveLabel: "FullHD 1920x1080p - 550KBps",
        milliRtmp: "rtmp://live-rtmp-pub.millicast.com:1935/v2/pub/",
        milliChanel: "kmo2axah?token=e53964a0e79bcc8742a9d4f0a07f1cba854b75f27ff480bdd02e367d0fc95dff",
        redRtmp: "rtmp://www.chanel-live.com:1935/live",
        redChanel: "chanel",
        liveStreamId: 'kmo2axah',
        liveRetryDelay: 5000,
        publishToken: "cd2cd2177435082e4fd5f2ca403394e43ccfdaefe311c97fd42a34a93b8ab341",
        accountId: 'YXewhN',
        publishUrl: 'https://director.millicast.com/api/director/publish',
        subscribeUrl: 'https://director.millicast.com/api/director/subscribe',
        xirsysUrl: 'https://global.xirsys.net/_turn/ChanelPB',
        xirsysTokenUrl: 'https://global.xirsys.net/_token/ChanelPB',
        xirsysHostUrl: 'https://global.xirsys.net/_host',
        xirsysToken: btoa("didierbrun:7d206dc6-80b6-11eb-a5b3-0242ac150002"),
        mediaConstraints: (cameraId, microId, tstream = false) => {
            return {
                audio: {
                    deviceId: microId
                },
                video: {
                    deviceId: cameraId,
                    frameRate: 30,
                    width: {
                        max: tstream ? 1920 : 1280,
                        ideal: tstream ? 1920 : 320,
                        min: 320
                    },
                    height: {
                        max: tstream ? 1080 : 720,
                        ideal: tstream ? 1080 : 240,
                        min: 240
                    }
                }
            }
        },
        publishPayload: (identifier, sdp) => {
            return {
                type: "cmd",
                transId: Math.floor(Math.random() * 10000),
                name: 'publish',
                data: {
                    streamId: identifier,
                    sdp: sdp
                }
            }
        },
        subscribePayload: (identifier, sdp) => {
            return {
                type: "cmd",
                transId: Math.floor(Math.random() * 10000),
                name: 'view',
                data: {
                    name: identifier,
                    sdp: sdp,
                    codec: 'h264'
                }
            }
        },
        publisherResponse: (sdp) => {
            return {
                type: 'answer',
                sdp: sdp + "a=x-google-flag:conference\r\n"
            }
        },
        subscriberConfig: (identifier, mediaElementId, iceServers) => {
            return {
                protocol: 'wss',
                host: 'www.chanel-live.com',
                port: 443,
                app: 'live',
                streamName: identifier,
                mediaElementId: mediaElementId,
                rtcConfiguration: {
                    iceServers: iceServers,
                    iceCandidatePoolSize: 0,
                    bundlePolicy: 'balanced'
                }
            }
        },
        publisherConfig: (identifier, mediaElementId, iceServers) => {
            return {
                protocol: 'wss',
                host: 'red5rschanel.com',
                port: 443,
                app: 'live',
                streamName: identifier,
                mediaElementId: mediaElementId,
                rtcConfiguration: {
                    iceServers: iceServers,
                    iceCandidatePoolSize: 2,
                    bundlePolicy: 'max-bundle'
                },
                bandwidth: {
                    audio: 128,
                    video: 1500
                },
                keyFramerate: 3000
            }
        }
    },
    debug: {
        live: false
    },
    lobby: {
        pingDelay: 30,
        pingDelayCheck: 5
    },
    live: {
        pingInterval: 1000,
        eventTimeOut: 5000,
        bandwithInterval: 1000,
        defaultResolution: 'low',
        moveTimeOut: 10000
    },
    admin: {
        pingInterval: 1000,
        timeOutUserDelay: 30000
    },
    home: {
        defaultBackground: 'https://remote-sales.imgix.net/chanel/VISUEL%20VERTCAL%202.png?fm=jpg',
        mobileBackground: 'https://remote-sales.imgix.net/chanel/home_mobile.png?fm=jpg'
    },
    resolutions: {
        high: 2000,
        medium: 1000,
        low: 500
    },
    red5: {
        publisherConfig: {
            protocol: 'wss',
            host: 'red5rschanel.com',
            port: 443,
            app: 'live',
            streamName: '%identifier%',
            mediaElementId: '%elementId%',
            rtcConfiguration: {
                iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
                iceCandidatePoolSize: 2,
                bundlePolicy: 'max-bundle'
            },
            bandwidth: {
                audio: 128,
                video: 500
            },
            keyFramerate: 1000,
            mediaConstraints: {
                audio: true,
                video: {
                    width: {
                        max: 1280,
                        ideal: 640,
                        min: 640
                    },
                    height: {
                        max: 720,
                        ideal: 480,
                        min: 480
                    }
                }
            }
        },
        subscriberConfig: {
            protocol: 'wss',
            host: 'red5rschanel.com',
            port: 443,
            app: 'live',
            streamName: '%identifier%',
            mediaElementId: '%elementId%',
            rtcConfiguration: {
                iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
                iceCandidatePoolSize: 2,
                bundlePolicy: 'max-bundle'
            }
        }
    },
    publish: {
        protocol: 'wss',
        host: 'red5rschanel.com',
        port: 443,
        app: 'live',
        streamName: '%identifier%',
        mediaElementId: '%elementId%',
        rtcConfiguration: {
            iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
            iceCandidatePoolSize: 2,
            bundlePolicy: 'max-bundle'
        }
    },
    waiting: {
        date: new Date('Thu Feb 25 2021 10:00:00 GMT+0100'),
        videos: [
            {
                preview: 'https://remote-sales.imgix.net/chanel/cover_2.png?fm=jpg',
                source: 'https://storage.googleapis.com/remote-sales-images/videos/01-CHANEL_FS_CONSTELLATION-ASTRALE_CONFO_H264_MUTE.mp4'
            },
            {
                preview: 'https://remote-sales.imgix.net/chanel/preview2.png?fm=jpg',
                source: 'https://storage.googleapis.com/remote-sales-images/videos/02-CHANEL_FS_CAMELIA-VENITIEN_CONFO_H264_MUTE.mp4'
            },
            {
                preview: 'https://remote-sales.imgix.net/chanel/preview3.png?fm=jpg',
                source: 'https://storage.googleapis.com/remote-sales-images/videos/03-CHANEL_FS_CAMELIA-BYZANTIN_CONFO_H264_MUTE.mp4'
            }
        ],
        gouachesThumbUrl: 'https://remote-sales.imgix.net/chanel/%name%?fm=jpg&w=194&h=260',
        gouachesRowUrl: 'https://remote-sales.imgix.net/chanel/%name%?fm=jpg&w=276&h=390',
        gouachesMediumUrl: 'https://remote-sales.imgix.net/chanel/%name%?fm=jpg&w=800&h=1120',
        gouachesHdUrl: 'https://remote-sales.imgix.net/chanel/%name%?fm=jpg',
        gouaches: [
            'CAMELIA BAROQUE NECKLACE.jpg',
            'CAMELIA BAROQUE RING.jpg',
            'CAMELIA BAROQUE TOI & MOI RING.jpg',
            'CAMELIA BAROQUE WATCH.jpg',
            'CAMELIA BYZANTIN RING.jpg',
            'CAMELIA VENITIEN ASY EARRINGS.jpg',
            'CAMELIA VENITIEN BRACELET.jpg',
            'CAMELIA VENITIEN EARRINGS.jpg',
            'CAMELIA VENITIEN NECKLACE.jpg',
            'CAMELIA VENITIEN RING.jpg',
            'CONSTELLATION ASTRALE BRACELET.jpg',
            'CONSTELLATION ASTRALE EARRINGS.jpg',
            'CONSTELLATION ASTRALE NECKLACE.jpg',
            'CONSTELLATION ASTRALE RING.jpg',
            'EBLOUISSANTE BRACELET (marc image).jpg',
            'EBLOUISSANTE EARRING (marc image).jpg',
            'EBLOUISSANTE EARRING (marc prestige).jpg',
            'EBLOUISSANTE EARRINGS (marc prestige).jpg',
            'EBLOUISSANTE NECKLACE (marc image).jpg',
            'EBLOUISSANTE NECKLACE (marc prestige).jpg',
            'EBLOUISSANTE RING (marc image).jpg',
            'EBLOUISSANTE RING (marc prestige).jpg',
            'EBLOUISSANTE WATCH.jpg',
            'LION EMBLEMATIQUE EARRINGS.jpg',
            'LION EMBLEMATIQUE NECKLACE.jpg',
            'LION EMBLEMATIQUE RING.jpg',
            'LION SECRET DIAMOND RING.jpg',
            'LION SECRET EARRINGS.jpg',
            'LION SECRET NECKLACE.jpg',
            'LION SECRET SAPHIR RING.jpg',
            'RUBAN CANOTIER BRACELET.jpg',
            'RUBAN CANOTIER EARRINGS.jpg',
            'RUBAN CANOTIER NECKLACE.jpg',
            'RUBAN CANOTIER RING.jpg',
            'VOLUTE CROISIERE BRACELET.jpg',
            'VOLUTE CROISIERE EARRINGS.jpg',
            'VOLUTE CROISIERE NECKLACE.jpg',
            'VOLUTE CROISIERE SAPHIR RING.jpg',
            'VOLUTE MARINE BRACELET.jpg',
            'VOLUTE MARINE EARRINGS.png',
            'VOLUTE MARINE NECKLACE.jpg',
            'VOLUTE MARINE RING.jpg',
            'VOLUTE VENITIENNE BRACELET.jpg',
            'VOLUTE VENITIENNE EARRINGS.jpg',
            'VOLUTE VENITIENNE NECKLACE.jpg',
            'VOLUTE VENITIENNE RING.jpg'
        ]
    }
}

export default config