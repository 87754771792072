import { useEffect, useState } from 'react'
import styles from './DemoStreamCamera.module.css'

const mediaConstraints = {
    audio: true,
    video: {
        width: {
            max: 1280,
            ideal: 640,
            min: 640
        },
        height: {
            max: 720,
            ideal: 360,
            min: 360
        }
    }
}


let sendChannel

const DemoStreamCamera = ({ identifier }) => {

    const [streamName, setStreamName] = useState(identifier)
    const [accountId, setAccountId] = useState('vhz7rj')
    //const [iceServers, setIceServers] = useState([{ urls: 'stun:stun2.l.google.com:19302' }])
    const [iceServers, setIceServers] = useState([])
    const [url, setUrl] = useState(null)
    const [jwt, setJwt] = useState(null)

    const PUBLISH_TOKEN = "c0b180bbd8daee499a619495713111a4588d6baf28fb95563885361e872e7ebb";

    useEffect(() => {

        console.log("DEMO STREAM PLAYER")

        var xhr = new XMLHttpRequest();
        xhr.open("POST", "https://director.millicast.com/api/director/publish", true)
        xhr.setRequestHeader("Authorization", `Bearer ${PUBLISH_TOKEN}`);
        xhr.setRequestHeader("Content-Type", "application/json")
        

        xhr.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                var jsonData = JSON.parse(xhr.responseText);
                /*console.log("success:", js)
                setUrl(js.data.urls[0])
                setJwt(js.data.jwt)*/
                setUrl(jsonData.data.urls[0])
                setJwt(jsonData.data.jwt)
            }
        }

        xhr.send(JSON.stringify({ streamAccountId: accountId, streamName: streamName, unauthorizedSubscribe: true }))

    }, [streamName, accountId, setUrl, setJwt])

    const onPoil = () => {
        console.log("POIL")
        console.log(sendChannel)
        sendChannel.send("Poil aux fesses...")
    }

    const onConnect = () => {

        let conf = {
            iceServers: iceServers,
            rtcpMuxPolicy: "require",
            bundlePolicy: "max-bundle"
        }
        let pc = new RTCPeerConnection(conf)

        /*sendChannel = pc.createDataChannel("didou")      

        sendChannel.onopen = () => {
            console.log("DATA OPEN")
            sendChannel.send("Poil aux fesses premier...")
        }

        sendChannel.onclose = () => {
            console.log("CLOSED")
        }*/

        /*let sendChannel = pc.createDataChannel('sendChannel')
        
        pc.onopen = () => {
            console.log("ON OPEN")
        }

        pc.onclose = () => {
            console.log("ON CLOSE")
        }*/

        pc.ontrack = function (event) {

        };

        let ws = new WebSocket(url + "?token=" + jwt);
        ws.onmessage = function (evt) {

            let msg = JSON.parse(evt.data);

            switch(msg.type){
              //Handle counter response coming from the Media Server.

        
              case "response":
                let data = msg.data;
                let sdp = data.sdp
                //let sdp = data.sdp.split(`m=application 9 UDP/TLS/RTP/SAVPF \r\n`).join(`m=application 9 DTLS/SCTP 5000\r\na=sctpmap:5000 webrtc-datachannel 1024\r\n`)

                let answer = new RTCSessionDescription({
                  type: 'answer',
                  sdp : sdp + "a=x-google-flag:conference\r\n"
                });
                pc.setRemoteDescription(answer)
                  .then(d => {
                    console.log('setRemoteDescription Success! ');
                    console.log('YOU ARE BROADCASTING!');

                  })
                  .catch(e => {
                      console.log('setRemoteDescription failed:')
                    console.log(e);
                  });
                break;
            case "event":
                console.log("EVENT", evt)
                break;
            }
        }

        ws.onerror = function(){
            console.log("WS ERROR")
        }

        ws.onopen = async function () {


            const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);

            let vid = document.getElementById("preview")
            vid.srcObject = stream

            stream.getTracks()
                .forEach(track => {
                    console.log('video track: ', track);
                    pc.addTrack(track, stream)
                });

                /*{
                    offerToReceiveAudio: true,
                    offerToReceiveVideo: true
                }*/
            let offer = pc.createOffer().then(desc => {
                console.log('createOffer Success!');
                console.log(desc.sdp)
                pc.setLocalDescription(desc)
                    .then(() => {
                        let data = {
                            name: streamName,
                            sdp: desc.sdp,
                            codec: 'h264'
                        }

                        let payload = {
                            type: "cmd",
                            transId: Math.floor(Math.random() * 10000),
                            name: 'publish',
                            data: data
                        }

                        ws.send(JSON.stringify(payload));

                        console.log(payload)

                    })
                    .catch(e => {
                        console.log('setLocalDescription failed: ', e);
                    })
            }).catch(e => {
                console.log('createOffer Failed: ', e)
            });
        }

    }

    const loadIces = () => {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function (evt) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                let res = JSON.parse(xhr.responseText);
                setIceServers([res.v.iceServers])
            }
        }
        xhr.open("PUT", "https://global.xirsys.net/_turn/ChanelPB", true);
        xhr.setRequestHeader("Authorization", "Basic " + btoa("didierbrun:7d206dc6-80b6-11eb-a5b3-0242ac150002"));
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(JSON.stringify({ "format": "urls" }));
    }

    return (
        <div>
            Demo stream camera
            <p>Stream name : {streamName}</p>
            <p>URL : {url}</p>
            <p>JWT: {jwt}</p>
            <p>Ice servers : {iceServers.length}</p>
            <div onClick={loadIces}>[ Load ices ]</div>
            <div onClick={onConnect}>[ Connect ]</div>
            <div onClick={onPoil}>[ Poil ]</div>
            <video className={styles.video} id="preview" width="640" height="360" controls autoPlay/>
        </div>
    )
}

export default DemoStreamCamera