import { all } from 'redux-saga/effects'

import AppSaga from './app/AppSaga'
import SessionSaga from './session/SessionSaga'
import LiveSaga from './live/LiveSaga'
import AdminSaga from './admin/AdminSaga'
import CameraSaga from './camera/CameraSaga'
import RoomSaga from './room/RoomSaga'

const root = function* () {
  yield all([
    ...AppSaga,
    ...SessionSaga,
    ...LiveSaga,
    ...AdminSaga,
    ...CameraSaga,
    ...RoomSaga
  ])
}

export default root