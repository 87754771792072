import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import useSession from '../../hooks/useSession'
import config from '../../config/config'
import styles from './Home.module.css'
import useHome from './useHome'
import locale from '../../config/locale'
import BlurWindow from '../../components/BlurWindow/BlurWindow'
import LogoChanel from '../../components/LogoChanel/LogoChanel'
import Thobber from '../../components/Throbber/Throbber'
import HomeRedux from '../../redux/home/HomeRedux'
import CodeForm from '../../components/CodeForm/CodeForm'
import UnderLink from '../../components/UnderLink/UnderLink'
import { motion, useAnimation } from "framer-motion"
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Home = () => {

    const { initialize, requestCode, authenticated } = useSession()
    const { slug } = useParams()
    const { step, error } = useHome()
    const blackMask = useAnimation()
    const history = useHistory()
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 640px)' })

    useEffect(() => {
        initialize(slug)
    }, [initialize, slug])

    useEffect(() => {
        async function animation() {
            await blackMask.start({ opacity: 1, transition: { duration: 1.5 } })
            history.replace('/waiting')
        }
        if (step === HomeRedux.steps.authenticated) {
            animation()
        }
    }, [step])

    const onSubmit = (code) => {
        requestCode(code)
    }

    const renderBlurWindow = () => {
        return (
            <BlurWindow step={step}>
                <div key={HomeRedux.steps.loading}><Thobber /></div>
                <div key={HomeRedux.steps.requestCode} className={styles.centered}>
                    {isTabletOrMobile && <LogoChanel className={styles.logoMobile}/>}
                    <p className={styles.boxed}>{locale('enter_code')}</p>
                    <CodeForm onSubmit={onSubmit} />
                    <UnderLink to='#' label={locale('lost_password')} action={HomeRedux.actions.homeLostPassword()} />
                </div>
                <div key={HomeRedux.steps.error}>
                    <div className={styles.boxed}>
                        {locale(error)}
                        {
                            error !== 'invalid_room' && <UnderLink to='#'
                                label={locale('try_new_code')}
                                action={HomeRedux.actions.homeSetStep(HomeRedux.steps.requestCode)} />
                        }
                        {
                            error === 'invalid_room' && <span className={styles.separator} />
                        }
                    </div>
                </div>
            </BlurWindow>
        )
    }

    return (
        <div className={styles.container} style={{ backgroundImage: `url(${isTabletOrMobile ? config.home.mobileBackground : config.home.defaultBackground})` }}>
            {!isTabletOrMobile && <LogoChanel />}
            {authenticated || renderBlurWindow()}
            <motion.div animate={blackMask} className={styles.blackMask}></motion.div>
        </div>
    )
}

export default Home