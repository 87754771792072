import { useEffect, useState } from 'react'
import styles from './VideoPreview.module.css'
import useCamera from '../../hooks/useCamera'
import ToggleButton from '../ToggleButton/ToggleButton'
import config from '../../config/config'
import micon from './icons/micon@2x.png'
import micoff from './icons/micoff@2x.png'
import cx from 'classnames'

const VideoPreview = ({ streamIdentifier, publisher, name, className, hidden, cam, mic }) => {

    const { requestSubscribe, mute, unMute, checkBitrate, bitrates } = useCamera()
    const [muted, setMuted] = useState(false)

    const debugLive = config.debug.live

    useEffect(() => {
        if (!publisher){
            requestSubscribe(`${streamIdentifier}-video`, streamIdentifier)
        }

        const interval = setInterval(() => {
            if (!publisher){
                checkBitrate(streamIdentifier)
            }
        }, config.live.bandwithInterval)

        return () => clearInterval(interval)

    }, [streamIdentifier])


    const onToggleMute = () => {
        if (muted){
            setMuted(false)
            unMute(streamIdentifier)
        } else {
            setMuted(true)
            mute(streamIdentifier)
        }
    }

    const videoAlpha = () => {
        if (publisher){
            return cam ? 1 : 0
        } else {
            return bitrates[streamIdentifier] && bitrates[streamIdentifier].bandwidth > 0 ? 1 : 0
        }
    }


    return (
        <div className={cx(styles.container, className, hidden && styles.hidden)}>
            <video
                id={`${streamIdentifier}-video`}
                autoPlay
                className={styles.video}
                style={{opacity: videoAlpha()}}
                muted={publisher}
            />
            {videoAlpha() === 0 && !cam && <div className={styles.onlyMic}><img className={styles.micIcon} src={mic ? micon : micoff} alt='mic'/></div>}
            {debugLive && <p className={styles.legend}>{streamIdentifier}</p>}
            {<p className={styles.name}>{name}</p>}
            {debugLive && bitrates[streamIdentifier] && bitrates[streamIdentifier].bandwidth > 0 && <p className={styles.bandwidth}>{bitrates[streamIdentifier].bandwidth} Kbps</p>}
            <div className={styles.controls}>
                {!publisher && debugLive && <ToggleButton icon='hp' active={!muted} onClick={onToggleMute}/>}
            </div>
        </div>
    )
}

export default VideoPreview