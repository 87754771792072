import red5 from './publishers/Red5Publisher'
import milli from './publishers/MilliPublisher'
import twilio from './publishers/TwilioPublisher'
import config from '../config/config'

const Current = (peer, setPeer, force) => {
    if (force){
        if (force === "twilio")return twilio(peer, setPeer) 
    }
    if (config.room.platform().platform === "red5")return red5(peer, setPeer)
    if (config.room.platform().platform === "millicast")return milli(peer, setPeer)
    if (config.room.platform().platform === "twilio")return twilio(peer, setPeer)
}


export default Current