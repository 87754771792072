import { takeLatest, call, fork, select, take, put, race } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import pub from './PubFactory'
import RoomRedux from '../room/RoomRedux'
import LiveRedux from '../live/LiveRedux'
import SessionRedux from '../session/SessionRedux'
import AppRedux from '../app/AppRedux'

function* initialize({ identifier }){
    pub.initialize(identifier)
    yield fork(initFactory)
}

function* initFactory(){
    const channel = yield call(factoryChannel)
    try {
        while (true){
            const event = yield take(channel)
            yield put(event)
        }
    } finally {
    }
}

function factoryChannel() {
    return eventChannel((emitter) => {
        const eventHandler = (e) => {
           emitter(e)
        }
        pub.onEvent = eventHandler
        return () => pub.onEvent = null
    })
}

function* startBroadcast(){
    pub.setState({
        step: RoomRedux.steps.broadcast
    })
}

function* requestOccupants({ occupants }){
    let occupancy = yield select(RoomRedux.selectors.occupancy)
    let invalidate = yield select(RoomRedux.selectors.invalidate)

    if (occupants.length !== occupancy){
        if (!invalidate){
            yield put(RoomRedux.actions.roomSetOccupancy(occupants.length))
            yield put(RoomRedux.actions.roomSetOccupants(occupants))
        } else {
            pub.hereNow()
        }
    } else {
        yield put(RoomRedux.actions.roomSetOccupants(occupants))
        if (invalidate){
            yield put(RoomRedux.actions.roomValidate())
        } 
    }
}

function* inviteUser({ uuid }){
    pub.publishMessage({
        uuid,
        action: "INVITE_USER"
    })
}

function* allowUser({ uuid }){
    pub.publishMessage({
        uuid,
        action: "ALLOW_USER"
    })
}

function* pubEvent(payload){
    let { action } = payload
    let identifier = yield select(RoomRedux.selectors.identifier)

    switch (action){
        case "RENAME_USER":
            yield put(RoomRedux.actions.roomSetName({
                [payload.uuid]: payload.name
            }))
            break;
        case "INVITE_USER":
            if (identifier === payload.uuid){
                yield put(RoomRedux.actions.roomStartSettings())
                yield put(LiveRedux.actions.liveEventInviteUser(payload.uuid))
            }
            break;
        case "ALLOW_USER":
            if (identifier === payload.uuid){
                yield put(RoomRedux.actions.roomAllow())
            }
            break;
        case "KICK_USER":
            if (identifier === payload.uuid){
                yield put(SessionRedux.actions.sessionLogoutRequest())
            }
            break;
        case "END_USER":
            if (identifier == payload.uuid){
                yield put(AppRedux.actions.appSetTransitionEnd("appear"))
            }
    }
}

function* startSettings(){
    pub.setState({
        step: RoomRedux.steps.settings
    })
}

function* cameraError(){
    pub.setState({
        step: RoomRedux.steps.cameraError
    })
}

function* cameraGranted(){
    pub.setState({
        step: RoomRedux.steps.cameraGranted
    })
}

function* confirmSettings(){
    pub.setState({
        step: RoomRedux.steps.confirmed
    })
}

function* allow(){
    pub.setState({
        step: RoomRedux.steps.allowed
    })
}

function* joinLive(){
    pub.setState({
        step: RoomRedux.steps.live
    })
}

function* renameUser({uuid, name}){
    pub.publishMessage({
        uuid,
        name,
        action: "RENAME_USER"
    })
}

function* kickUser({uuid}){
    pub.publishMessage({
        uuid,
        action: "KICK_USER"
    })
}

function* endUser({uuid}){
    pub.publishMessage({
        uuid,
        action: "END_USER"
    })
}


let combine = [
    takeLatest('ROOM_INITIALIZE', initialize),
    takeLatest('ROOM_START_BROADCAST', startBroadcast),
    takeLatest('ROOM_START_SETTINGS', startSettings),
    takeLatest('ROOM_REQUEST_OCCUPANTS', requestOccupants),
    takeLatest('ROOM_INVITE_USER', inviteUser),
    takeLatest('ROOM_PUB_EVENT', pubEvent),
    takeLatest('ROOM_CAMERA_ERROR', cameraError),
    takeLatest('ROOM_CAMERA_GRANTED', cameraGranted),
    takeLatest('ROOM_CONFIRM_SETTINGS', confirmSettings),
    takeLatest('ROOM_ALLOW_USER', allowUser),
    takeLatest('ROOM_ALLOW', allow),
    takeLatest('ROOM_JOIN_LIVE', joinLive),
    takeLatest('ROOM_RENAME_USER', renameUser),
    takeLatest('ROOM_KICK_USER', kickUser),
    takeLatest('ROOM_END_USER', endUser)
]
  
export default combine