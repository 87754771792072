import { useCallback, useState } from 'react'
import { connect } from 'twilio-video'
import config from '../config/config'

const Hook = (room, peer, forceTwilio = false) => {

    const [audio, setAudio] = useState(true)
    const [video, setVideo] = useState(true)

    const onToggleVideo = useCallback(() => {
        if (config.room.platform().platform === "twilio" || forceTwilio){
            if (room.localParticipant){
                if (video){
                    room.localParticipant.videoTracks.forEach(publication => {
                        publication.track.disable();
                    })
                } else {
                    room.localParticipant.videoTracks.forEach(publication => {
                        publication.track.enable();
                    })
                }
            }
        } else {
            let sender = peer.getSenders().filter(s => s.track.kind === "video")[0]
            sender.track.enabled = !video
        }
        setVideo(!video)
    })

    const onToggleAudio = useCallback(() => {
        if (config.room.platform().platform === "twilio" || forceTwilio){
            if (room.localParticipant){
                if (audio){
                    room.localParticipant.audioTracks.forEach(publication => {
                        publication.track.disable();
                    })
                } else {
                    room.localParticipant.audioTracks.forEach(publication => {
                        publication.track.enable();
                    })
                }
            }
        } else {
            let sender = peer.getSenders().filter(s => s.track.kind === "audio")[0]
            sender.track.enabled = !audio
        }
        setAudio(!audio)
    })

    return {
        audio,
        video,
        onToggleVideo,
        onToggleAudio
    }
}

export default Hook