import { useCallback, useState } from 'react'
import config from '../../config/config'
import { connect } from 'twilio-video'
import { useSelector } from 'react-redux'
import RoomRedux from '../../redux/room/RoomRedux'
import twilioToken from '../../graphql/queries/twilio/twilioToken'


const Hook = () => {

    const [stream, setStream] = useState(null)
    const [active, setActive] = useState(false)
    const [audio, setAudio] = useState(true)
    const [video, setVideo] = useState(true)

    const localIdentifier = useSelector(RoomRedux.selectors.identifier)

    const subscribe = useCallback(async (identifier, iceServers, elementId, room) => {
        console.log("Twilio subscribe")

        let participants = Array.from(room.participants.values())

        if (participants.length > 0) {
            let p = participants.filter(p => p.identity === identifier)[0]

            if (p) {
                let container = document.getElementById(`container-${elementId}`)
                container.innerHTML = ''

                p.tracks.forEach(publication => {
                    if (publication.track) {
                        document.getElementById(`container-${elementId}`).appendChild(publication.track.attach())
                        setActive(true)

                        publication.track.on('disabled', () => {
                            console.log("TRACK DISABLED")
                        })
                    }
                })

                p.on('trackSubscribed', track => {
                    document.getElementById(`container-${elementId}`).appendChild(track.attach())
                    setActive(true)
                })

                p.on('trackDisabled', track => {
                    if (track.kind === "audio"){
                        setAudio(false)
                    } 
                    if (track.kind === "video"){
                        setVideo(false)
                    }
                })

                p.on('trackEnabled', track => {
                    if (track.kind === "audio"){
                        setAudio(true)
                    } 
                    if (track.kind === "video"){
                        setVideo(true)
                    }
                })



            
            }
        }

    })

    return {
        subscribe,
        stream,
        setStream,
        audio,
        video,
        active
    }
}

export default Hook