import styles from './LanguageSelector.module.css'
import ArrowButton from '../ArrowButton/ArrowButton'
import { useState } from 'react'
import cx from 'classnames'

const LanguageSelector = () => {

    const languages = ['FR', 'EN', 'CN', 'JP', 'IT']

    const [language, setLanguage] = useState(languages[0])
    const [open, setOpen] = useState(false)

    const onEnter = () => {
        setOpen(true)
    }

    const onLeave = () => {
        setOpen(false)
    }

    const onSelectLanguage = (l) => {
        setLanguage(l)
        setOpen(false)
    }

    return (
        <div className={styles.container} onMouseEnter={onEnter} onMouseLeave={onLeave}>
            <div className={styles.current}>
                <div className={styles.labelInitial}>{language}</div><ArrowButton direction='down' className={styles.arrow}/>
            </div>
            <div className={cx(styles.body, open && styles.open)}>
                <div className={styles.separator}/>
                {
                    languages.filter(l => l !== language).map(l => {
                        return (
                            <div key={l} className={styles.plang} onClick={() => {
                                onSelectLanguage(l)
                            }}>{l}</div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default LanguageSelector