import React from 'react'
import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SessionRedux from '../../redux/session/SessionRedux'
import RoomRedux from '../../redux/room/RoomRedux'

const ProtectedRoute = ({ component:Component,  requiredStep}) => {

    const step = useSelector(RoomRedux.selectors.step)
    const authenticated = useSelector(SessionRedux.selectors.authenticated)
    const allowed = requiredStep ? requiredStep.indexOf(step) >= 0 : true

    return (authenticated && allowed) ? <Component/> : <Redirect to={{pathname: '/'}}/> 
}

export default ProtectedRoute