import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import RoomRedux from '../../redux/room/RoomRedux'
import config from '../../config/config'
import ky from 'ky'

const Hook = (peer, setPeer) => {

    const dispatch = useDispatch()
    
    const publish = useCallback(async (identifier, iceServers, stream) => {

        //
        // Load URLS & JWT
        //
        let url, jwt;
        let result = await ky.post(config.room.publishUrl, {
            json:{
                streamAccountId: config.room.accountId,
                streamName: identifier,
                unauthorizedSubscribe: true
            },
            headers: {
                'Authorization': `Bearer ${config.room.publishToken}`
            } 
        }).json()

        if (result.status === 'success'){
            url = result.data.urls[0]
            jwt = result.data.jwt
        }

        //
        // Create the peer
        //
        let peer = new RTCPeerConnection({
            iceServers: iceServers,
            rtcpMuxPolicy: "require",
            bundlePolicy: "max-bundle"
        })

        //
        // Create the web socket
        //
        let ws = new WebSocket(url + "?token=" + jwt);
        
        ws.onmessage = async function (evt){
            let data = JSON.parse(evt.data)
            if (data.type === "response"){
                let session = new RTCSessionDescription(config.room.publisherResponse(data.data.sdp))
                await peer.setRemoteDescription(session)
                setPeer(peer)
            }
        }

        await new Promise((resolve) => {
            ws.onopen = () => {
                stream.getTracks().forEach(track => {
                    peer.addTrack(track, stream)
                })
                resolve()
            }
        })

        //
        // Create the offer
        //
        let offer = await peer.createOffer()
        await peer.setLocalDescription(offer)
        ws.send(JSON.stringify(config.room.publishPayload(identifier, offer.sdp)))

        dispatch(RoomRedux.actions.roomStartBroadcast())

    })

    return {
        publish,
        peer
    }
}

export default Hook