import styles from './ZoomGrid.module.css'
import { useEffect } from 'react'
import CloseButton from '../CloseButton/CloseButton'
import config from '../../config/config'
import ArrowButton from '../ArrowButton/ArrowButton'

const ZoomGrid = ({ onClose, name, onPrevious, onNext }) => {

    useEffect(() => {
        document.querySelector('body').style.overflow = "hidden"
        return () => {
            document.querySelector('body').style.removeProperty("overflow")
        }
    }, [])

    return (
        <div className={styles.container}>
            <CloseButton className={styles.close} onClick={onClose}/>
            <img className={styles.image} src={config.waiting.gouachesMediumUrl.split('%name%').join(name)} alt={name}/>
            <div className={styles.buttons}>
                <ArrowButton direction='left' className={styles.leftArrow} onClick={onPrevious}/>
                <ArrowButton direction='right' className={styles.rightArrow} onClick={onNext}/>
            </div>
        </div>
    )
}

export default ZoomGrid