import { useEffect, useState } from 'react'
import styles from './RoomStreamer.module.css'
import RoomButton from '../RoomButton/RoomButton'
import usePublisher from '../../../hooks/usePublisher'
import config from '../../../config/config'
import useRoom from '../../../hooks/useRoom'
import useToggleMedia from '../../../hooks/useToggleMedia'

const RoomStreamer = ({ identifier, micro, camera, iceServers, stream, room, setRoom, peer, setPeer }) => {

    const [enabled, setEnabed] = useState(true)
    const { publish } = usePublisher(peer, setPeer)
    const { toggleLayout } = useRoom()
    const { audio, video, onToggleAudio, onToggleVideo } = useToggleMedia(room, peer)

    const onPublish = () => {
        setEnabed(false)
        publish(identifier, iceServers, stream, room, setRoom)
    }

    const onDebug =() => {
        console.log(room)
    }

    return (
        <div className={styles.container}>
            Streamer {config.room.platform().label}
            <p>CAM : {camera ? `${camera.substr(0, 20)}...` : 'None'}</p>
            <p>MIC : {micro ? `${micro.substr(0, 20)}...` : 'None'}</p>
            <div className={styles.actions}>
                <RoomButton label="Join" onClick={onPublish} enabled={enabled && stream !== null} className={styles.spacer}/>
                <RoomButton label={video ? 'Mute video' : 'Unmute video'} onClick={onToggleVideo} enabled={peer !== null} className={styles.spacer}/>
                <RoomButton label={audio ? 'Mute audio' : 'Unmute audio'} onClick={onToggleAudio} enabled={peer !== null} className={styles.spacer}/>
                <RoomButton label="debug" enabled={peer !== null} onClick={onDebug} className={styles.spacer}/>
                <RoomButton label="Toggle layout" enabled={peer !== null} className={styles.spacer} onClick={toggleLayout}/>
            </div>
        </div>
    )
}

export default RoomStreamer