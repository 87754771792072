import styles from './CameraSettings.module.css'
import LabelButton from '../LabelButton/LabelButton'
import locale from '../../config/locale'
import camera1 from './icons/camera.png'
import camera2 from './icons/camera@2x.png'
import UnderLink from '../UnderLink/UnderLink'
import useCamera from '../../hooks/useCamera'
import { motion } from 'framer-motion'
import SlideShow from '../SlideShow/SlideShow'
import useSession from '../../hooks/useSession'
import useRoom from '../../hooks/useRoom'
import RoomRedux from '../../redux/room/RoomRedux'
import { useHistory } from 'react-router-dom'
import useLive from '../../hooks/useLive'
import MediaList from '../MediaList/MediaList'
import usePublisherConfig from '../../hooks/usePublisherConfig'
import { useState, useEffect, useRef } from 'react'
import config from '../../config/config'
import cx from 'classnames'

const variants = {
    hide: {
        opacity: 0,
        transition: {
            duration: 0.5
        }
    },
    show: {
        opacity: 1,
        transition: {
            duration: 0.5
        }
    }
}

const CameraSettings = () => {
    const history = useHistory()
    const { cameraError, cameraGranted, confirmSettings, step, audioId, videoId } = useRoom()
    const { identifier } = useSession()
    const [loading, setLoading] = useState(false)
    const [joined, setJoined] = useState(false)
    const [videoMedias, setVideoMedias] = useState([])
    const [audioMedias, setAudioMedias] = useState([])
    const { initlizePublisher, loading: loadingPublisher } = usePublisherConfig()
    const [deviceLoaded, setDeviceLoaded] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const refVideo = useRef(null)

    useEffect(() => {
        document.body.style.setProperty("overflow-y", "hidden")
        return () => {
            document.body.style.setProperty("overflow-y", "auto")
        }
    }, [])

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((result) => {
            setVideoMedias(result.filter(d => d.kind === "videoinput"))
            setAudioMedias(result.filter(d => d.kind === "audioinput"))
        })
    }, [setVideoMedias, setAudioMedias])


    useEffect(() => {
        if (step !== RoomRedux.steps.initial){
            selectDevice()
        }
    }, [videoId, audioId])

    const loadDevices = () => {
        if (!deviceLoaded) {
            navigator.mediaDevices.enumerateDevices().then((result) => {
                let camList = result.filter(c => c.kind === "videoinput")
                let micList = result.filter(c => c.kind === "audioinput")
                console.log(camList, micList)
                setVideoMedias(camList)
                setAudioMedias(micList)
            })
            setDeviceLoaded(true)
        }
    }

    const onJoin = () => {
        setJoined(true)
        setLoading(true)
        initlizePublisher(identifier)
        selectDevice()
    }

    const onConfirm = () => {
        if (!confirmed){
            setConfirmed(true)
            confirmSettings()
        }
    }

    const onSession = () => {
        history.replace("/session")
    }

    const selectDevice = () => {
        navigator.mediaDevices.getUserMedia(config.room.mediaConstraints(videoId, audioId, config.room.platform().tstream)).then((result) => {
            refVideo.current.srcObject = result
            cameraGranted()
            if (!deviceLoaded)loadDevices()
        }).catch((e) => {
            cameraError()
        })
    }

    const renderMedias = () => {
        return (
            <div className={cx(styles.settingsMedia, confirmed && styles.disableMedias)}>
                <p className={styles.settingsLabel}>{locale('video_settings')}</p>
                <MediaList id="video" source={videoMedias} current={videoId} />
                <MediaList id="audio" source={audioMedias} current={audioId} />
            </div>
        )
    }

    const renderInstructions = () => {
        return (
            <>
                <p className={styles.instructions}>
                    {step === RoomRedux.steps.cameraError ? locale('camera_error') : locale('camera_settings_instructions')}
                </p>
                <UnderLink label={locale('need_help')} to='#' className={styles.helpLink} />
            </>
        )
    }

    const etape = () => {
        switch(step){
            case RoomRedux.steps.initial:
            case RoomRedux.steps.settings:
                return locale('step_label')(1, 2);
            case RoomRedux.steps.cameraGranted:
            case RoomRedux.steps.confirmed:
                return locale('step_label')(2, 2);
            case RoomRedux.steps.allowed:
                return locale('you_are_ready')
        }
        return locale('step_label')(1, 2);
    }

    const renderActions = () => {
        switch(step){
            case RoomRedux.steps.initial:
            case RoomRedux.steps.settings:
                return (
                    <LabelButton label={loading ? locale('please_wait') : locale('ask_permission')} onClick={onJoin} disabled={loading} />
                )
                break;
            case RoomRedux.steps.cameraGranted:
                return (
                    <LabelButton label={confirmed ? locale('please_wait') : locale('comfirm_settings')} onClick={onConfirm} disabled={confirmed} />
                )
                break;
            case RoomRedux.steps.cameraGranted:
            case RoomRedux.steps.confirmed:
                return (
                    <LabelButton label={locale('please_wait')} onClick={() => {}} disabled={true} />
                )
                break;
            case RoomRedux.steps.allowed:
                return (
                    <LabelButton label={locale('join_live')} onClick={onSession} disabled={false}/>
                )
                break;
        }
        return <></>
    }

    return (
        <motion.div className={styles.container} initial={{ opacity: 0.0 }} animate={{ opacity: 1 }}>
            <SlideShow images={config.imagesSettings} duration={8000}/>
            <div className={styles.content}>
                <div className={styles.videoContainer}>
                    <video ref={refVideo} className={styles.video} autoPlay muted/>
                    {!joined && <span className={styles.videoInfos}>{locale('no_camera')}</span>}
                </div>
                { !deviceLoaded && renderInstructions() }
                { deviceLoaded && renderMedias()}
                <span className={styles.stepLabel}>{etape()}</span>
                { renderActions() }
            </div>
        </motion.div>
    )
}

export default CameraSettings