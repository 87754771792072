import { useEffect, useState, useRef } from 'react'
import styles from './CodeForm.module.css'

const CodeForm = ({ onSubmit }) => {

    const [code, setCode] = useState(Array(4).fill(''))

    const refs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null)
    ]

    useEffect(() => {
        refs[0].current.focus()
    }, [])

    const onChange = (index, value) => {
        let newCode = code.slice(0)
        newCode[index] = value
        if (index < 3){
            refs[index + 1].current.focus()
            newCode[index + 1] = ''
        } else {
            refs[index].current.blur()

            if (newCode.join('').length === 4)(
                onSubmit(newCode.join(''))
            )
        }
        setCode(newCode)
    }

    const onFocus = (index) => {
        let newCode = code.slice(0)
        newCode[index] = ''
        setCode(newCode)
    }

    return (
        <div className={styles.container}>
            {code.map((c, index) => {
                return (
                    <input  key={index} 
                            ref={refs[index]}
                            value={c}
                            className={styles.inputDigit}
                            onFocus={() => { onFocus(index)}}
                            onChange={(e) => { onChange(index, e.target.value) }}
                            />
                )
            })}
        </div>
    )
}

export default CodeForm