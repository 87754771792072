import logo1 from './logo.png'
import logo2 from './logo@2x.png'
import logob1 from './logob.png'
import logob2 from './logob@2x.png'

import cx from 'classnames'

import styles from './LogoChanel.module.css'

const logos = {
    white: {
        normal: logo1,
        retina: logo2
    },
    black: {
        normal: logob1,
        retina: logob2
    }
}

const LogoChanel = ({ className, color = "white" }) => {
    return (
        <img src={logos[color].normal} className={cx(styles.logo, className)} srcSet={logos[color].normal + ' 1x,' + logos[color].retina + ' 2x'} alt='Logo Chanel'/>
    )
}

export default LogoChanel