import styles from './RoomUsers.module.css'
import { useState } from 'react'
import RoomUserVideo from '../RoomUserVideo/RoomUserVideo'
import Immutable from 'seamless-immutable'
import useRoom from '../../../hooks/useRoom'
import RoomRedux from '../../../redux/room/RoomRedux'
import cx from 'classnames'
import ArrowButton from '../../../components/ArrowButton/ArrowButton'

const RoomUsers = ({ occupants, identifier, iceServers, room }) => {

    const { layout } = useRoom()
    const [show, setShow] = useState(true)
    

    const onToggleShow = () => {
        setShow(!show)     
    }

    const occupantsOrder = () => {
        let result = occupants.filter(o => o.uuid !== identifier && o.state !== undefined && o.uuid !== 'tstream')
        if (layout === RoomRedux.layouts.live){
            result = result.filter(o => o.state.step === RoomRedux.steps.broadcast)
        }
        return Immutable.asMutable(result).sort((a, b) => a.uuid.localeCompare(b.uuid))
    }

    const isArrowVisible = () => {
        if (layout === RoomRedux.layouts.live){
            return occupantsOrder().length > 0
        } else {
            return false
        }
    }

    const isUsersVisible = () => {
        if (layout === RoomRedux.layouts.live){
            return show
        } else {
            return true
        }
    }

    return (
        <div className={cx(styles.container, layout === RoomRedux.layouts.live && styles.containerLive)}>
            {isArrowVisible() && <ArrowButton direction={show ? "down" : "up"} className={styles.arrowButton} onClick={onToggleShow}/>}
            {isUsersVisible() && iceServers && iceServers.length > 0 && occupantsOrder().map(o => {
                return (
                    <RoomUserVideo room={room} key={o.uuid} identifier={o.uuid} step={o.state.step} iceServers={iceServers}/>
                )
            })}
            {!iceServers && <p className={styles.status}>Loading...</p>}
            {layout !== RoomRedux.layouts.live && iceServers && iceServers.length > 0 && occupantsOrder() < 1 && <p className={styles.status}>No other participant</p>}
        </div>
    )
}

export default RoomUsers