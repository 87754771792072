import styles from './RoomButton.module.css'
import cx from 'classnames'

const RoomButton = ({ label, onClick, enabled, className }) => {
    return (
        <div className={cx(styles.container, enabled && styles.enabled, className)} onClick={onClick}>
            {label}
        </div>
    )
}

export default RoomButton