import styles from './CountDown.module.css'
import { useState, useEffect } from 'react'
import locale from '../../config/locale'
import config from '../../config/config'
import timediff from 'timediff'
import cx from 'classnames'
import { motion } from 'framer-motion'

const futureDate = new Date(new Date().getTime() + 10 * 60000)


const CountDown = ({ dark }) => {

    const [elapsed, setElapsed] = useState(' ')
    const [started, setStarted]= useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            setElapsed(dateDif())
        }, 100);
        return () => clearInterval(interval);
    }, [])

    const dateDif = () => {
        let now = new Date()
        let future = futureDate

        let difference = future.getTime() - now.getTime()

        setStarted(difference < 0)

        let result = timediff(now, future, 'MDHmS');
        let _minutes = result.minutes.toString()
        let _seconds = result.seconds.toString()

        while (_minutes.length < 2)_minutes = '0' + _minutes
        while (_seconds.length < 2)_seconds = '0' + _seconds
        
        let out = `${_minutes}M ${_seconds}S`

        if (result.hours > 0 || result.days > 0 || result.months > 0){
            out = `${result.hours}H ` + out
        }

        if (result.days > 0 || result.months > 0){
            out = `${result.days}J ` + out
        }

        if (result.months > 0){
            out = `${result.months}M ` + out
        }
        
        return out
    }

    return (
        <div className={cx(styles.container, dark && styles.containerDark)}>
            {!started && <div>{locale('presentation_starting')}</div>}
            {started && <div>{locale('presentation_started')}</div>}
            {!started && <div className={styles.elapsed}>
                {elapsed}
            </div>}
            <motion.span className={styles.underline} initial={{width: '0rem'}} animate={{width: '8rem'}} transition={{ duration: 0.7, delay: 2.5, ease: 'easeOut'}}/>
        </div>
    )
}

export default CountDown