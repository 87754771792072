import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import config from '../../config/config'
import RoomRedux from '../../redux/room/RoomRedux'

const Hook = (peer, setPeer) => {

    const dispatch = useDispatch()

    const publish = useCallback(async (identifier, iceServers, stream) => {

        let pubConfig = config.room.publisherConfig(identifier, 'publisher-preview', iceServers)
        let publisher = new window.red5prosdk.RTCPublisher()

        publisher.initWithStream(pubConfig, stream).then(() => {
            return publisher.publish()
        }).then(() => {
            setPeer(publisher.getPeerConnection())
            dispatch(RoomRedux.actions.roomStartBroadcast())
        })

    })

    return {
        publish,
        peer
    }
}

export default Hook