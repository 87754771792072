import config from '../../config/config'

class StreamFactory {

    constructor(red5) {
        this.red5 = red5
        this.subscribers = {}
        this.publishers = {}
    }


    onPublisherEvent = (e, identifier) => {
    }

    onSubscriberEvent = (e, identifier) => {
        if (e._type === 'Subscribe.Play.Unpublish') {
            this.sendEvent('UNPUBLISHED_STREAM', {
                identifier
            })
        }
    }

    bitrateForSubscriber(identifier) {
        let subscriber = this.subscribers[identifier].subscriber
        let self = this
        if (subscriber) {
            let connection = subscriber.getPeerConnection()
            if (connection) {
                connection.getStats(null).then(res => {
                    res.forEach(function (report) {
                        if (report.bytesReceived > 0 && report.mediaType === 'video') {
                            self.sendEvent(
                                "BITRATE_DATAS",
                                {
                                    bytesReceived: report.bytesReceived,
                                    timestamp: report.timestamp,
                                    identifier
                                }
                            )
                        }
                    })
                })
            }
        }
    }

    changePublisherDevice(videoDeviceId, audioDeviceId, identifier){
        let publisher = this.publishers[identifier]
        if (publisher){

            (async ()=>{
                publisher.publisher.on('*',() =>{})
                await publisher.publisher.unpublish()

                publisher.publisher = new this.red5.RTCPublisher()
                
                let publisherConfig = {
                    ...config.red5.publisherConfig,
                    streamName: identifier,
                    mediaElementId: publisher.elementId
                }

                publisherConfig.mediaConstraints.video.deviceId = videoDeviceId
                publisherConfig.mediaConstraints.audio = {
                    deviceId: audioDeviceId
                }

                publisher.publisher.init(publisherConfig).then(() => {
                    return publisher.publisher.publish()
                }).then(() => {
                    this.sendEvent("CHANGE_VIDEO_DEVICE_SUCCESS")
                }).catch((e) => {
                    this.sendEvent("CHANGE_VIDEO_DEVICE_FAIL")
                })
            })()
        }   


    }

    getPublishersDevices(identifier){
        let publisher = this.publishers[identifier]
        if (publisher){
            let mediaStream = publisher.publisher.getMediaStream()
            let videoSettings = mediaStream.getVideoTracks()[0].getSettings();
            let audioSettings = mediaStream.getAudioTracks()[0].getSettings();

            return {
                videoDeviceId: videoSettings.deviceId,
                audioDeviceId: audioSettings.deviceId
            }
        }
        return null
    }

    createPublisher(identifier, elementId) {

        if (this.publishers[identifier] !== undefined) {
            document.getElementById(elementId).srcObject = this.publishers[identifier].publisher.getMediaStream()
            this.sendIdEvent(identifier, 'PUBLISH_SUCCESS', { success: true })
            return
        }

        let publisher = {
            identifier,
            elementId,
            publisher: new this.red5.RTCPublisher()
        }

        let publisherConfig = {
            ...config.red5.publisherConfig,
            streamName: identifier,
            mediaElementId: elementId
        }
        
        publisher.publisher.id = identifier
        publisher.publisher.on('*', (e) => { this.onPublisherEvent(e, identifier) })

        publisher.publisher.init(publisherConfig).then(() => {
            return publisher.publisher.publish()
        }).then(() => {
            this.sendIdEvent(identifier, 'PUBLISH_SUCCESS', { success: true })
        }).catch((e) => {
            this.sendIdEvent(identifier, 'PUBLISH_ERROR', { success: false })
        })

        this.publishers[identifier] = publisher
    }

    createSubscriber(identifier, elementId) {
        let subscriber = {
            identifier,
            elementId,
            subscriber: new this.red5.RTCSubscriber()
        }

        let subscribeConfig = {
            ...config.red5.subscriberConfig,
            streamName: identifier,
            mediaElementId: elementId
        }

        subscriber.subscriber.id = identifier
        subscriber.subscriber.on('*', (e) => { this.onSubscriberEvent(e, identifier) })

        subscriber.subscriber.init(subscribeConfig).then(() => {
            return subscriber.subscriber.subscribe()
        }).then(() => {
            this.sendIdEvent(identifier, 'SUBSCRIBE_SUCCESS', { success: true })
        }).catch((e) => {
            this.sendIdEvent(identifier, 'SUBSCRIBE_ERROR', { success: false })
        })

        this.subscribers[identifier] = subscriber
    }

    removeSubscriber(identifier) {
        let subscriber = this.subscribers[identifier]
        if (subscriber) {
            subscriber.subscriber.unsubscribe().then(() => {
                this.sendIdEvent(identifier, 'REMOVE_SUBSCRIBE_SUCCESS', { success: true })
            }).catch(() => {
                this.sendIdEvent(identifier, 'REMOVE_SUBSCRIBE_ERROR', { success: false })
            })
        } else {
            this.sendIdEvent(identifier, 'REMOVE_SUBSCRIBE_ERROR', { success: false })
        }
        delete (this.subscribers[identifier])
    }

    removeAllSubscribers() {
        let keys = Object.keys(this.subscribers);
        Promise.all(keys.map(key => {
            return new Promise((resolve) => {
                let subscriber = this.subscribers[key]
                subscriber.subscriber.unsubscribe().then(() => {
                    this.subscribers[key] = null
                    resolve()
                }).catch(() => {
                    this.subscribers[key] = null
                    resolve()
                })
            })
        })).then(() => {
            this.sendEvent('REMOVE_SUBSCRIBE_ALL_SUCCESS', { success: true })
        })
    }

    updateResolution(identifier, resolution){        
        let publisher = this.publishers[identifier]
        if (publisher){
            let connection = publisher.publisher.getPeerConnection()
            connection.createOffer().then(offer => connection.setLocalDescription(offer)).then(() => {
                let sdp = connection.remoteDescription.sdp

                console.log(sdp)
                
                let bandwidth = config.resolutions[resolution]
                let lines = sdp.split('\r\n')
                let videoFound = false;
                for (let i = 0; i < lines.length; i++){
                    if (lines[i].indexOf('m=video') === 0)videoFound = true
                    if (videoFound && lines[i].indexOf('b=AS:') === 0){
                        lines[i] = `b=AS:${bandwidth}`
                    } 
                }
                let newSdp = lines.join('\r\n')
                const desc = {
                    type: connection.remoteDescription.type,
                    sdp: newSdp
                }
                return connection.setRemoteDescription(desc)
            })
        }
    }

    muteMic(identifier) {
        let publisher = this.publishers[identifier]
        if (publisher){
            document.getElementById(publisher.elementId).muted = true
            publisher.publisher.muteAudio()
        }
    }

    unMuteMic(identifier) {
        let publisher = this.publishers[identifier]
        if (publisher){
            document.getElementById(publisher.elementId).muted = false
            publisher.publisher.unmuteAudio()
        }
    }

    muteCam(identifier){
        let publisher = this.publishers[identifier]
        if (publisher){
            publisher.publisher.muteVideo()
        }
    }

    unMuteCam(identifier){
        let publisher = this.publishers[identifier]
        if (publisher){
            publisher.publisher.unmuteVideo()
        }
    }

    mute(identifier) {
        let subscriber = this.subscribers[identifier]
        if (subscriber) {
            subscriber.subscriber.mute()
        }
    }

    unMute(identifier) {
        let subscriber = this.subscribers[identifier]
        if (subscriber) {
            subscriber.subscriber.unmute()
        }
    }

    removePublisher(identifier) {
        let publisher = this.publishers[identifier]
        if (publisher) {
            publisher.publisher.unpublish().then(() => {
                document.getElementById(publisher.elementId).srcObject = null
                this.sendIdEvent(identifier, 'REMOVE_PUBLISH_SUCCESS', { success: true })
            }).catch(() => {
                this.sendIdEvent(identifier, 'REMOVE_PUBLISH_ERROR', { success: false })
            })
        } else {
            this.sendIdEvent(identifier, 'REMOVE_PUBLISH_ERROR', { success: false })
        }
        delete (this.publishers[identifier])
    }

    sendEvent(type, payload = {}) {
        let event = {
            type: `FACTORY_${type}`,
        }
        if (payload) event = { ...event, ...payload }
        this.onEvent(event)
    }

    sendIdEvent(identifier, type, payload) {
        let event = {
            type: `FACTORY_${type}_${identifier}`,
        }
        if (payload) event = { ...event, ...payload }
        this.onEvent(event)
    }

}

const factory = new StreamFactory(window.red5prosdk)

export default factory