import { createStore, applyMiddleware, compose } from 'redux'
import Reactotron from '../config/reactotron'
import { combineReducers } from 'redux'
import ImmutablePersistenceTransform from './ImmutablePersistTransform'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './saga'

const rootReducer = combineReducers({
  app: require('./app/AppRedux').default.reducer,
  session: require('./session/SessionRedux').default.reducer,
  home: require('./home/HomeRedux').default.reducer,
  live: require('./live/LiveRedux').default.reducer,
  admin: require('./admin/AdminRedux').default.reducer,
  camera: require('./camera/CameraRedux').default.reducer,
  room: require('./room/RoomRedux').default.reducer
})

const persistConfig = {
  key: 'root',
  whitelist: ['user', 'session'],
  storage,
  transforms: [ImmutablePersistenceTransform]
}

const middleware = []
const enhancers = []
const sagaMonitor = Reactotron.createSagaMonitor()
const sagaMiddleware = createSagaMiddleware({sagaMonitor})
middleware.push(sagaMiddleware)

enhancers.push(applyMiddleware(...middleware))
const reducer = persistReducer(persistConfig, rootReducer)

let composed = compose(...enhancers, Reactotron.createEnhancer())

let store = createStore(reducer, composed)
let persistor = persistStore(store)
//persistor.purge()

sagaMiddleware.run(rootSaga)

let exportDefault = {
  store,
  persistor
}

export default exportDefault
