import styles from './RoomVideo.module.css'
import useSubscriber from '../../../../hooks/useSubscriber'
import { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import config from '../../../../config/config'
import useRoom from '../../../../hooks/useRoom'


const variants = {
    show: {
        opacity: 1
    },
    hide: {
        opacity: 0
    }
}

const RoomVideo = ({ identifier, iceServers, room }) => {

    const { subscribe, stream, active } = useSubscriber()
    const videoRef = useRef(null)
    const { layout } = useRoom()

    useEffect(() => {
        subscribe(identifier, iceServers, identifier, room)
    }, [])

    useEffect(() => {
        if (config.room.platform().platform === "millicast"){
            videoRef.current.srcObject = stream
        }
    }, [stream])


    return (
        <div className={styles.container}>
            <motion.div id={`container-${identifier}`} className={styles.videoContainer} variants={variants} animate={active ? 'show' : 'hide'} transition={{duration: 1, delay: 2, ease: 'easeOut'}}>
                <video ref={videoRef} id={identifier} width="240" height="135" className={styles.video} autoPlay={true}/>
            </motion.div>
        </div>
    )
}

export default RoomVideo