import { useCallback } from 'react'
import SessionRedux from '../redux/session/SessionRedux'
import { useDispatch, useSelector } from 'react-redux'

const Hook = () => {

    const dispatch = useDispatch()
    const identifier = useSelector(SessionRedux.selectors.identifier)
    const liveKey = useSelector(SessionRedux.selectors.liveKey)
    const authenticated = useSelector(SessionRedux.selectors.authenticated)
    const displayName = useSelector(SessionRedux.selectors.displayName)
    const welcomed = useSelector(SessionRedux.selectors.welcomed)

    const requestCode = (code) => {
        dispatch(SessionRedux.actions.sessionCodeRequest(code.toUpperCase()))
    }

    const setWelcomed = (value) => {
        dispatch(SessionRedux.actions.sessionSetWelcomed(value))
    }

    const initialize = useCallback((slug) => {
        dispatch(SessionRedux.actions.sessionInitialize(slug))
    }, [dispatch])

    return {
        initialize,
        identifier,
        liveKey,
        requestCode,
        authenticated,
        displayName,
        setWelcomed,
        welcomed
    }

}

export default Hook