import styles from './ZoomGouache.module.css'
import { useState, useEffect, useRef } from 'react'
import Throbber from '../../components/Throbber/Throbber'
import CloseButton from '../CloseButton/CloseButton'
import ArrowButton from '../../components/ArrowButton/ArrowButton'
import { motion } from "framer-motion"
import cx from 'classnames'

const variants = {
    hide: {
        opacity: 0
    },
    show: {
        opacity: 1
    }
}

const ZoomGouache = ({ url, onCloseWindow, onNext, onPrevious, pageName, pageIndex, count }) => {

    const [imageSize, setImageSize] = useState(null)
    const [imgSize, setImgSize] = useState({ width: 'auto', height: 'auto' })
    const [screenSize, setScreenSize] = useState(null)
    const [cadreSize, setCadreSize] = useState({ width: 0, height: 0 })
    const [loading, setLoading] = useState(true)
    const [anime, setAnime] = useState('hide')
    const [zoom, setZoom] = useState(false)
    const [bounds, setBounds] = useState(null)

    const cadre = useRef(null)

    useEffect(() => {
        document.body.style.setProperty("overflow-y", "hidden")
        return () => {
            document.body.style.setProperty("overflow-y", "auto")
        }
    }, [])

    useEffect(() => {

        setBounds(cadre.current.getBoundingClientRect())
        
        function onResize() {
            if (imageSize && screenSize) {
                let ratioImage = imageSize.width / imageSize.height
                let ratioScreen = window.innerWidth / window.innerHeight

                if (ratioImage < ratioScreen) {
                    setCadreSize({
                        height: window.innerHeight - 140,
                        width: (window.innerHeight - 140) * ratioImage
                    })
                } else {
                    setCadreSize({
                        height: window.innerWidth - 140,
                        width: (window.innerWidth - 140) * ratioImage
                    })
                }
            }

            setBounds(cadre.current.getBoundingClientRect())
        }


        function onMoveCadre(e) {
            if (zoom) {

                let px = e.clientX - bounds.left;
                let py = e.clientY - bounds.top;

                setImgSize({
                    width: '200%',
                    height: '200%',
                    transform: `translate(-${px}px, -${py}px)`
                })
            }
        }

        if (imageSize && screenSize) {
            onResize()
        }

        window.addEventListener('resize', onResize)
        cadre.current.addEventListener('mousemove', onMoveCadre)

        return () => {
            window.removeEventListener('resize', onResize)
            if (cadre.current) {
                cadre.current.removeEventListener('mousemove', onMoveCadre)
            }
        }

    }, [imageSize, screenSize, cadre, zoom, setBounds])

    const onClose = () => {
        setImageSize(null)
        setScreenSize(null)
        setCadreSize({ width: 0, height: 0 })
        setImgSize({ width: 'auto', height: 'auto' })
        onCloseWindow()
    }


    const onImageLoad = (e) => {

        setLoading(false)

        setAnime('show')

        setImgSize({
            width: '100%',
            height: '100%'
        })

        setImageSize({
            width: e.target.width,
            height: e.target.height
        })

        setScreenSize({
            width: window.innerWidth,
            height: window.innerHeight
        })

        setTimeout(() => {
            setBounds(cadre.current.getBoundingClientRect())
        }, 60)

    }



    const onToggleZoom = (e) => {

        let px = 0
        let py = 0

        if (e && e.nativeEvent){

            px = e.nativeEvent.clientX - bounds.left;
            py = e.nativeEvent.clientY - bounds.top;
        }

        let newZoom = !zoom
        setZoom(newZoom)

        if (newZoom) {
            setImgSize({
                width: '200%',
                height: '200%',
                transform: `translate(-${px}px, -${py}px)`
            })

        } else {
            setImgSize({
                width: '100%',
                height: '100%',
                transform: 'translate(0px, 0px)'
            })
        }
    }

    const onChangePage = (callback) => {
        if (zoom){
            onToggleZoom()
        }

        setAnime('hide')
        setLoading(true)
        callback()
    }

    const countLabel = () => {
        let pi = (pageIndex + 1).toString()
        let co = count.toString()
        while (pi.length < 2)pi = '0' + pi
        while (co.length < 2)co = '0' + co
        return `${pi} / ${co}`
    }

    return (
        <div className={styles.container} id="zoomguache">
            <div className={styles.motionContent}>
                <motion.div className={styles.cadrePhoto} style={{
                    width: `${cadreSize.width}px`,
                    height: `${cadreSize.height}px`
                }}
                    variants={variants} animate={anime} initial={'hide'}
                    ref={cadre}
                >
                    <img src={url} onLoad={onImageLoad} className={cx(styles.imgZoom, zoom && styles.imgZoomed)} style={imgSize} onClick={onToggleZoom} />
                </motion.div>
                {!loading && <CloseButton className={styles.closeButton} onClick={onClose} />}
                <ArrowButton direction='right' className={styles.arrowRight} onClick={()=> {onChangePage(onNext)}}/>
                <ArrowButton direction='left' className={styles.arrowLeft} onClick={()=> {onChangePage(onPrevious)}}/>
                {!loading && <p className={styles.legend}>{pageName}</p>}
                {!loading && <p className={styles.countLabel}>{countLabel()}</p>}
            </div>
            {loading && <div className={styles.throbber}><Throbber /></div>}
        </div>
    )
}

export default ZoomGouache